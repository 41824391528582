/*****************************
 Accordion
*****************************/

.accordion {

  .accordion-item {
    border: 0;
    background-color: inherit;
    margin-bottom: 20px;

    .accordion-header {
      border-bottom: none;
      padding: 0;
      margin-bottom: 0px;

      .accordion-button {
        font-size: 18px;
        padding: 15px 20px;
        font-weight: 600;
        color: $white;
        background-color: rgba($white, .1);

        &:after {
          content: "\2b";
          background-image: inherit;
          font-family: 'Font Awesome 5 Free';
          font-size: 18px;
          margin-right: 0px;
          color: $white;
        }

        &:hover {
          color: $primary;
        }
      }

      .accordion-button:hover {

        &:after {
          color: $primary;
        }
      }

      .accordion-button:not(.collapsed) {
        background-color: $primary;
        box-shadow: inherit;
        outline: inherit;
        color: $white;

        &:after {
          content: "\f068";
          background-image: inherit;
          font-family: 'Font Awesome 5 Free';
          transform: inherit;
          color: $white;
        }
      }
    }

    .accordion-button:focus {
      border-color: inherit;
      box-shadow: inherit;
    }

    .accordion-collapse {
      .accordion-body {
        padding: 20px 15px;
        color: $body-color;
      }
    }

    &:last-child .accordion-header {
      margin-bottom: 0;
    }

    &:last-child .accordion-body {
      padding-top: 30px;
      padding-bottom: 0px;
    }
  }
}

.frequently-asked-questions {
  .sidebar {
    .widget {
      .submit-form {
        .form-control {
          background-color: rgba($white, .1);
          color: $white;
          border-color: rgba($white, .1);
        }
      }
    }
  }
}

.checkout-review {

  .accordion {

    .card {
      border: none;
      padding: 8px 0px;
      margin-bottom: 5px;
      background-color: inherit;

      .accordion-icon.card-header {
        background: none;
        padding: 0;
        border-bottom: none;

        button {
          padding: 0px 0px 0px 30px;
          font-size: 18px;
          font-weight: 600;
          color: $primary;

          &:before {
            font-size: 10px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            content: "\f068";
            font-family: "Font Awesome 5 Free";
            border: 2px solid $primary;
            width: 18px;
            height: 18px;
            line-height: 14px;
            border-radius: 0;
            z-index: 9;
            text-align: center;
            font-weight: 600;
          }
        }

        button.collapsed {
          color: $white;

          &:before {
            content: "\f067";
          }
        }
      }

      .card-body {
        padding: 10px 0px 0px 30px;
        color: $white;
      }
    }
  }
}

.map-section {

  .accordion {
    margin-left: 50px;
  }
}

.asked-questions{

  .questions-title{
    font-weight: 600;
    display: flex;
    font-size: 20px;
    line-height: 30px;

    .number{
      width: 32px;
      height: 32px;
      border-radius: $border-radius-lg;
      background-color: $primary;
      display:flex;
      flex: 0 0 32px;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      margin-right: 20px;
      color: $white;
    }
  }
}

.booking-02 {
  padding:40px 28px;
  box-shadow: $box-shadow;
  border-radius: $border-radius;

  .accordion {

    .custom-control {

      .custom-control-label {

        .accordion-title {
          font-weight: 700;
        }
      }
    }
  }
}


@media (max-width:991px) {

  .map-section {

    .accordion {
      margin-left: 0px;
    }
  }
}

@media (max-width:767px) {

  .accordion {

    .accordion-item {

      h3.accordion-header {
        padding-bottom: 15px;
        margin-bottom: 20px;
        font-size: 20px;

        .accordion-button {

          &:after {
            margin-right: 0px;
          }
        }
      }
    }
  }
}



