/*****************************
  Layout
*****************************/

/*Tournament Countdown*/
.tournament-countdown {
  padding: 50px;
  border-bottom: 1px solid $border-light;
}

/*Back To Top*/
.back-to-top a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 50px;
  right: 40px;
  width: 50px;
  height: 50px;
  margin: 0px;
  color: $white;
  font-size: 18px;
  background: $primary;
  transition: all 0.3s ease-in-out;
  z-index: 999;
}

/*Prevent text selection*/
.qty {
  border: 1px solid rgba($white, 0.2);
  margin-right: 10px;
  align-items: center;
  display: flex;
  padding: 13px 10px;
  border-radius: 0;
  width: 170px;
  justify-content: center;
  justify-content: space-between;

  .count {
    color: $white;
    display: inline-block;
    vertical-align: top;
    font-size: 18px;
    line-height: 10px;
    padding: 0 2px;
    min-width: 35px;
    text-align: center;
    background-color: inherit;
  }

  .plus {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    font-size: 30px;
    line-height: 30px;
    color: rgba($white, 0.2);
    min-width: 30px;
    text-align: center;
  }

  input {
    color: $white;
    border-color: inherit;
    text-align: center;
    width: auto;
  }

  .minus {
    cursor: pointer;
    display: inline-block;
    font-size: 30px;
    line-height: 30px;
    min-width: 30px;
    color: rgba($white, 0.2);
    text-align: center;
  }

  input {
    border: 0;
    width: 2%;

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:disabled {
      background-color: white;
    }
  }
}

/*Social Icon*/
.social-icon {

  ul {
    display: inline-flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding-left: 0;

    li {
      display: inline-flex;
      list-style-type: none;
      float: left;

      &:last-child {

        a {
          margin-right: 0;
        }
      }

      a {
        display: flex;
        font-size: 18px;
        color: $gray-2;
        margin-right: 25px;
        transition: all 0.5s ease;

        &:hover {
          color: $primary;
        }
      }
    }
  }
}

/*Live Videos*/
.video-section {
  position: relative;

  .video-box{
    overflow: hidden;

    .video-image{

      img{
        transform: scale(1);
        transition: all 0.5s ease;
      }
    }

    &:hover {

      .video-image{

        img{
          transform: scale(1.1);
        }
      }
    }
  }

  .video-style-vertical {
    overflow: auto;
    height: 550px;
    padding-right: 20px;
      
    .video-post {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .video-post-date {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 13%;
        flex: 0 0 13%;
      }

      .video-image {
        min-width: 160px;
        width: 160px;
        margin-right: 30px;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        img{
          transform: scale(1);
          transition: transform .3s ease;
        }

        &:hover {

          img{
            transform: scale(1.1);
          }
        } 
      }

      .video-post-details {

        .badge {
          background: $primary;
          border-radius: 0;
          text-transform: uppercase;
          margin-bottom: 15px;
        }

        .video-view {

          a {
            color: $white;

            &:hover {
              color: $primary;
            }

            &:last-child {
              padding-left: 35px;
              position: relative;

              &:before {
                content: "";
                position: absolute;
                background: $white;
                height: 4px;
                width: 4px;
                border-radius: 50%;
                left: 15px;
                top: 6px;
              }
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba($black,0.3);
      background-color: $gray-2;
    }

    &::-webkit-scrollbar{
      width: 3px;
      background-color: $gray-2;
    }

    &::-webkit-scrollbar-thumb{
      background-color: $primary;
    }
  }

  .video-image {
    position: relative;

    .video-btn.popup-youtube {
      position: absolute;
      top: 40%;
      left: 45%;
      font-size: 80px;
      color: $white;

      &:hover {
        color: $primary;
      }
    }

    .video-icon a {
      position: absolute;
      bottom: 5px;
      right: 5px;
      background: $white;
      display: flex;
      padding: 7px;
      justify-content: center;
      align-items: center;
    }
  }

  &:before {
    background-image: url(../images/home-01/pattern-06.png);
    content: "";
    position: absolute;
    height: 70px;
    width: 70px;
    left: 110px;
    top: 200px;
    background-repeat: no-repeat;
    backface-visibility: hidden;
    animation: jumping 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all .9s ease 5s;
    user-select: none;
  }

  &:after {
    background-image: url(../images/home-01/pattern-07.png);
    content: "";
    position: absolute;
    height: 70px;
    width: 70px;
    right: 130px;
    bottom: 250px;
    background-repeat: no-repeat;
    -webkit-animation: upmover 1.5s infinite  alternate;
    animation: upmover 1.5s infinite  alternate;
  }
}

/*Video Style 02*/
.video-style-02 {
  padding: 0px 150px;

  .video-bg {
    background-image: url(../images/home-03/video-bg.jpg);
    background-repeat: no-repeat;
    padding: 100px 0px;
    background-size: cover;
    background-position: center;
  }

  .match-box {
    
    .match-team {
      display: flex;
      align-items: center;

      span {
        color: $white;
        font-weight: bold;
      }

      .team-vs {
        padding: 0px 15px;
      }
    }

    .match-time {
      margin-top: 10px;
      margin-bottom: 20px;

      span {
        color: $white;
        font-size: 20px;
      }
    }

    p {
      color: $white;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 0;
    }
  }

  .match-video {
    padding-top: 100px;

    .play-btn {
      position: relative;
      padding-left: 0px;
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      color: $white;
      display: flex;
      align-items: center;

      i {
        color: $primary;
        font-size: 30px;
        transition: all 0.3s ease-in-out;
        z-index: 3;
        padding-right: 15px;
      }
    }
  }
}

/*Video Style 03*/

.video-style-03 {
  padding-left: 40px;
  position: relative;

  .play-btn {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex: 0 0 80px;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    border-radius: $border-radius-lg;
    background-color: $primary;
    color: $white;
    transition: all 0.3s ease-in-out;
    z-index: 9;

    i {
      font-size: 30px;
    }

    &:hover{
      background-color:$white;
      color:$primary;
    }
  }
}

.about {
  position: relative;

  &:before {
    background-image: url(../images/home-01/pattern-03.png);
    content: "";
    position: absolute;
    height: 158px;
    width: 69px;
    left: 130px;
    top: 130px;
    background-repeat: no-repeat;
    -webkit-animation: upmover 1.5s infinite  alternate;
    animation: upmover 1.5s infinite  alternate;
  }

  &:after {
    background-image: url(../images/home-01/pattern-05.png);
    content: "";
    position: absolute;
    height: 102px;
    width: 120px;
    right: 180px;
    top: 100px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .about-img{
    margin-right: 110px;
  }

  .about-bg {

    img {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}


/*Latest Results*/
.latest-results {
  background-image: url(../images/home-01/bg-01.jpg);
  padding-bottom: 280px;
  background-repeat: no-repeat;
}

.time-location{
  color: $white;

  .time{ 
    margin-right: 20px;
  }

  .time,
  .location{

    i{
      color: $primary;
      margin-right: 8px;
    }
  }
}

.latest-results-02{
  background-image: url(../images/home-01/bg-03.jpg);
  padding-bottom: 280px;
  background-repeat: no-repeat;
}

/*Upcoming Match*/
.upcoming-match{
  display: flex;
  align-items: center;
  justify-content: center;
  
  .match-date-time{
    padding: 0 30px;

    span{
      display: block;
    }

    .match-time{
      font-size: 32px;
      line-height: 32px;
      color: $white;
    }

    .match-date{
      font-size: 14px;
      line-height: 24px;
      color: $white;

      i{
        color: $primary;
        padding-right: 8px;
      }
    }
  }
}


/*Performers*/

.top-performers {
  position: relative;
  z-index: 2;

  .icon-1{
    left: 185px;
    top: 150px;
    z-index: -1;
   
  }

  .icon-2{
    left: 210px;
    bottom: 125px;
    z-index: -1;
    backface-visibility: hidden;
    animation: jumping3 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 7s ease 2s;
    user-select: none;
  }

  .icon-3{
    right: 340px;
    top: 150px;
    z-index: -1;
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 2s infinite  alternate;
  }

  .icon-4{
    right: 125px;
    bottom:155px;
    z-index: -1;
  }
}

.performers-box {
  display: flex;
  align-items: center;
  position: relative;

  .performers-info {
    flex: 0 0 55%;
    position: relative;
    z-index: 9;

    .player-name {
      font-size: 42px;
      font-weight: bold;
      color: $white;
      margin-bottom: 16px;
    }

    .player-rank {
      font-size: 42px;
      font-weight: bold;
      color: $white;
      margin-bottom: 5px;
    }

    .player-position,
    .player-height,
    .player-weight {
      font-size: 16px;
      color: $white;
      font-weight: normal;
      margin-bottom: 8px;

      span {
        font-size: 22px;
        font-weight: bold;
        color: $primary;
        margin-right: 5px;
      }
    }
  }

  .performers-img{
    position: relative;
    z-index: 2;

    img{
      position: relative;
      left: -90px;
    }
  }

  .performers-img.player-02{

    img{
      position: relative;
      left:inherit;
      right: -20px;
    }
  }
}

.testimonial-video-section{

  .icon-1{
    top: 150px;
    left: 65px;
    z-index: -1;
    -webkit-animation: upmover 1.5s infinite  alternate;
    animation: upmover 1.5s infinite  alternate;
  }

  .icon-2{
    left: 135px;
    bottom: 65px;
    z-index: -1;
  }

  .icon-3{
    left: 41%;
    bottom: 185px;
    z-index: -1;
    backface-visibility: hidden;
    animation: jumping3 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 7s ease 2s;
    user-select: none;
  }

  .icon-4{
    top: 160px;
    right: 80px;
    z-index: -1;
  }

  .icon-5{
    right: 50px;
    bottom: 20px;
    z-index: -1;
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 2s infinite  alternate;
  }
}

.images-gallery {

  .portfolio.portfolio-style-02{

    .portfolio-images{
      position: relative;
      z-index: 3;

      &:before{
        position: absolute;
        content: "";
        left: 0;
        width: 100%;
        height: 0;
        top: 0;
        background-color: rgba($primary,0.5);
        transition: all 0.3s ease-in-out;
        z-index: 1;
      }
    }

    .portfolio-info-02 {
      .popup-icon{
        z-index: 5;
      }
    }

    &:hover{

      .portfolio-images{

        &:before{
          height: 100%;
        }
      }
    }
  }
}

/*Client Logo*/
.client-logo {

  .owl-carousel {

    .owl-stage {
      display: flex;
      align-items: center;

      .owl-item {
        display: flex;
        align-items: center;
        justify-content: center;

        img{

          width: auto;
        }
      }
    }
  }
}

/* Home 02 */
.game-countdown {
  background-image: url(../images/home-02/bg-01.jpg);
  padding-top: 240px;
  padding-bottom: 240px;
  background-repeat: no-repeat;

  .section-title{

    h2{
      font-size: 42px;
      font-weight: bold;
    }
  }
}

.featured-news{
  padding-bottom: 250px;
}

.players-details{
  background-color: rgba($gray-1,0.3);
}

.products{
  position: relative;
  z-index: 2;

  &:before{
    background-image: url(../images/home-02/pattern-05.png);
    content: "";
    position: absolute;
    height: 70px;
    width: 70px;
    left: 35%;
    top: 55px;
    z-index: -1;
    background-repeat: no-repeat;
    backface-visibility: hidden;
    animation: jumping10 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 8s ease 4s;
    user-select: none;
  }

  &:after{
    background-image: url(../images/home-02/pattern-01.png);
    content: "";
    position: absolute;
    height: 93px;
    width: 93px;
    right: 90px;
    bottom: 160px;
    z-index: -1;
    background-repeat: no-repeat;
    backface-visibility: hidden;
    animation: jumping11 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 8s ease 4s;
    user-select: none;
  }

  .section-title{
    margin-bottom: 35px;
  }

  .pattern-7{
    position: absolute;
    bottom: 62px;
    left: -163px;
    z-index: -1;
  }
}

.video-section-02{
  background-image: url(../images/home-02/bg-02.png);
  background-repeat: no-repeat;
  padding-top: 170px;
  padding-bottom: 170px;

  .video-style-04{
    text-align: center;

    .play-btn {
      margin: 0 auto;
      margin-bottom: 40px;
      display: flex;
      flex: 0 0 90px;
      align-items: center;
      justify-content: center;
      height: 90px;
      width: 90px;
      border-radius: $border-radius-lg;
      background-color: $white;
      color: $primary;
      transition: all 0.3s ease-in-out;
      z-index: 99;

      i {
        font-size: 30px;
      }

      &:hover{
        background-color:$primary;
        color:$white;
      }
    }
  }
}

/*Home 03*/
.match-schedule {
  position: relative;

  .pattern-1 {
    top: 30px;
    left: 0;
    -webkit-animation: upmover 1.5s infinite  alternate;
    animation: upmover 1.5s infinite  alternate;
  }

  .pattern-2 {
    top: 30px;
    right: 0;
    -webkit-animation: upmover 1.5s infinite  alternate;
    animation: upmover 1.5s infinite  alternate;
  }
}

.pattern-5 {
  bottom: 20px;
}

.latest-news {
  position: relative;
  z-index: 2;

  .section-title {
    
    .title {
      margin-bottom: 0;
    }
  }
}

.latest-news-tab {
  position: relative;
  z-index: 2;

  .icon-1{
    bottom: 370px;
    left: 165px;
    z-index: -1;
  }

  .icon-2{
    bottom: 115px;
    left: 80px;
    z-index: -1;
    -webkit-animation: upmover 1.5s infinite  alternate;
    animation: upmover 1.5s infinite  alternate;
  }

  .icon-3{
    top: 285px;
    right:190px;
    z-index: -1;
    backface-visibility: hidden;
    animation: jumping10 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 8s ease 4s;
    user-select: none;
  }

  .icon-4{
    bottom: 193px;
    right:95px;
    z-index: -1;
    backface-visibility: hidden;
    animation: jumping3 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 7s ease 2s;
    user-select: none;
  }

  .points-table{
    
    .table{
      background-color:rgba($gray-1,0.3);
    }
  }
}

/*inner-page*/
.header.inner-page-header{
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99;
}

.inner-banner{
  height: 500px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 45px;

  .inner-banner-tittle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    h2 {
      color: $white;
      font-size: 60px;
      line-height: 70px;
      position: relative;
      margin-bottom: 15px;
    }

    .breadcrumb {
      margin-bottom: 15px;

      .breadcrumb-item {
        color: $white;
        text-transform: capitalize;

        &:before {
          color: $white;
        }

        a {
          color: $white;

          &:hover {
            color: $primary;
          }
        }
      }

      .breadcrumb-item.active{
        color: $primary;
      }
    }
  }
}

/*Progress Bar*/

.progress {
  position: relative;
  overflow: visible;
  height: .5rem;

  .progress-bar {
    border-radius: $border-radius;
    background-color: $primary;
  }
}

/*Newsletter*/
.newsletter{

  form{
    position: relative;
    padding-right: 193px;

    button.btn {
      position: absolute;
      top: 0;
      right: 0;
      padding-top: 18px;
      padding-bottom: 18px;

      &:hover{
        transform: translateY(0);
        background-color: $dark;
        color: $white;
      }
    }
  }
}

.player-about {
  background-image: url(../images/player/bg-01.png);
  background-repeat: no-repeat;
  padding: 100px 0px;
  background-size: cover;
  background-position: center;
}


/* Pagination */

.pagination {
  display: flex;
  justify-content: center;

  .page-item {
    margin: 0 6px;
    display: flex;

    .page-link {
      color: $primary;
      padding: 10px 18px;
      border-radius: 0;
      font-weight: 500;
      border: none;
      background: $gray-2;
      border-color: $gray-2;

      &:hover {
        background: $primary;
        border-color: $primary;
        color: $white;
      }

      &:focus {
        background: $primary;
        border-color: $primary;
        color: $white;
        box-shadow: none;
      }
    }
  }
  .page-item.active {

    .page-link {
      background: $primary;
      border-color: $primary;
      color: $white;
    }
  }
}



.message-info{
  padding: 40px 30px;

  .section-title{
    margin-bottom: 50px;

    p{
      margin-top: 20px;
    }
  }
}

.contact-us-form{
  padding-left: 60px;

  .section-title{
    margin-bottom: 50px;

    p{
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  form {
    .form-control {
      background-color: rgba($white, 0.1);
      border:1px solid rgba($white, 0.1);
      color: $white;
      &::placeholder {
        color: $white;
      }
    }

    .select2-container--default {
      .select2-selection--single {
        background-color: rgba($white, 0.1);
        border:1px solid rgba($white, 0.1);
        padding:15px 20px;

        .select2-selection__rendered {
          color: $white;
        }
      }
    }
  }
}

/*Coming Soon*/

.coming-soon-section{
  height: 100vh;

  .coming-soon-logo{

    img {
      display: block;
      margin: 0 auto;
      margin-bottom: 30px;
      height: 50px;
    }
    .logo-blue,
    .logo-yellow{
      display: none;
    }

  }
}  


/*404*/

.contant-404{

  h1{
    font-size: 250px;
    color: $white;

    span{
      color: $primary;
      padding: 0px 25px;
    }
  }
}


/*Ticket*/
.ticket-box{

  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding-right: 30px;

  .match-img{
    width: 200px;
  }

  .ticket-details{
    padding-left: 30px;
    display: flex;
    align-items: center;
    width: calc(100% - 150px);
    justify-content: space-between;

    .ticket-info{
      padding-right: 30px;

      .ticket-description{
        font-size: 30px;
        line-height: 38px;
        color: $white;
        margin-bottom: 10px;
      }

      .ticket-price{
        font-size: 24px;
        color: $primary;
      }
    }
    
    .match-details{
      padding-right: 30px;
      text-align: center;

      .team{
        font-size: 20px;
        color: $white;
        display: block;
      }

      .match-vs{
        padding: 15px 0px;
        font-size: 24px;
        color: $primary;
      }
    }
  }
}


/*Match Schedule Table*/

.upcoming-match-table{
  background-image: url(../images/home-01/bg-02.jpg);
  background-repeat: no-repeat;
  background-position: bottom;
  
  .match-schedule{
    position: relative;
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    width: 100%;
    padding:40px 40px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    transition: all 0.3s ease-in-out;

    &:before{
      content: "";
      width: 0;
      height: 3px;
      position: absolute;
      left: auto;
      right: 0;
      bottom: 0px;
      background-color:$primary;
      -webkit-transition: width 500ms ease 0s, left 500ms ease 0s;
      -moz-transition: width 500ms ease 0s, left 500ms ease 0s;
      transition: width 500ms ease 0s, left 500ms ease 0s;
    }

    > div{
      padding-left: 30px;
      position: relative;
      
      width: 216px;
      flex:0 0 auto;

      label.title{
        margin-bottom: 10px;
        font-size: 16px;
        color: $body-color;
        width: 100%;
      }

      &:before{
        position:absolute;
        content: "";
        width: 1px;
        height: 40px;
        background-color: rgba($white,0.3);
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .upcoming-match{
      padding-left: 0px;
      display: block;
      text-align: center;

      h2{
        font-size: 24px;
      }

      .match-team{

        img{
          height: 70px;
        }
      }

      &:before{
        content: inherit;
      }
    }

    .match-date{
      
      span{
        font-size: 18px;
        color: $white;
        display: block;
      }

      .time{
        font-size: 18px;
      }
    }

    .tournament{
      font-size: 18px;
      color: $white;
    }

    .venue{
      font-size: 18px;
      color: $white;
    }

    .article{
      padding-right: 0px;
    }

    &:hover{
      background-color: rgba($black,1);
      
      &:before{
        width: 100%;
        left: 0;
        right: auto;
      }
    }
  }
}

/*Keyframes*/

img.vert-move {
  -webkit-animation: upmover 1.5s infinite  alternate;
  animation: upmover 1.5s infinite  alternate;
}

img.hori-move {
  -webkit-animation: mover 2s infinite  alternate;
  animation: mover 2s infinite  alternate;
}

@-webkit-keyframes upmover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }

}

@keyframes mover {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-20px);
  }

}

.custom-animation {
  backface-visibility: hidden;
  animation: jumping 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all .9s ease 5s;
  user-select: none;
}

.custom-animation2 {
  backface-visibility: hidden;
  animation: jumping2 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all 1s ease 3s;
  user-select: none;
}

.custom-animation3 {
  backface-visibility: hidden;
  animation: jumping3 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all 7s ease 2s;
  user-select: none;
}

.custom-animation4 {
  backface-visibility: hidden;
  animation: jumping4 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all 8s ease 4s;
  user-select: none;
}

.custom-animation10 {
  backface-visibility: hidden;
  animation: jumping10 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all 8s ease 4s;
  user-select: none;
}

.custom-animation11 {
  backface-visibility: hidden;
  animation: jumping11 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all 8s ease 4s;
  user-select: none;
}

@keyframes jumping {
  0% {
    transform: translateY(0px) translateX(0) rotate(0) scale(1);
    opacity: .8;
  }

  25% {
    transform: translateY(-10px) translateX(-10px) rotate(20deg) scale(0.8);
    opacity: .9;
  }

  50% {
    transform: translateY(-15px) translateX(-15px) rotate(10deg) scale(0.9);
    opacity: .8;
  }

  75% {
    transform: translateY(-20px) translateX(-20px) rotate(20deg) scale(0.75);
    opacity: .6;
  }

  85% {
    transform: translateY(-25px) translateX(-25px) rotate(20deg) scale(0.9);
    opacity: .7;
  }

  100% {
    transform: translateY(-15px) translateX(-15px) rotate(0) scale(0.95);
    opacity: .85;
  }

}

@keyframes jumping2 {
  0% {
    transform: translateY(0px) translateX(0) rotate(0) scale(1);
    opacity: .5;
  }

  25% {
    transform: translateY(-30px) translateX(10px) rotate(20deg) scale(0.8);
    opacity: .8;
  }

  50% {
    transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.7);
    opacity: .8;
  }

  75% {
    transform: translateY(30px) translateX(20px) rotate(20deg) scale(0.75);
    opacity: .7;
  }

  100% {
    transform: translateY(-15px) translateX(15px) rotate(0) scale(0.75);
    opacity: .9;
  }

}

@keyframes jumping3 {
  0% {
    transform: translateY(10px) translateX(0) rotate(0) scale(1);
    opacity: .9;
  }

  20% {
    transform: translateY(20px) translateX(10px) rotate(-20deg) scale(0.8);
    opacity: .8;
  }

  40% {
    transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.75);
    opacity: .8;
  }

  40% {
    transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(0.5);
    opacity: 1;
  }

  80% {
    transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(0.75);
    opacity: .6;
  }

  100% {
    transform: translateY(15px) translateX(15px) rotate(0) scale(0.95);
    opacity: .7;
  }

}

@keyframes jumping4 {
  0% {
    transform: translateY(-30px) translateX(20px) rotate(0) scale(1.2);
    opacity: .7;
  }

  25% {
    transform: translateY(-20px) translateX(10px) rotate(50deg) scale(0.6);
    opacity: .8;
  }

  50% {
    transform: translateY(15px) translateX(-15px) rotate(20deg) scale(0.5);
    opacity: .9;
  }

  75% {
    transform: translateY(30px) translateX(20px) rotate(50deg) scale(0.75);
    opacity: .7;
  }

  100% {
    transform: translateY(-15px) translateX(15px) rotate(0) scale(0.5);
    opacity: .9;
  }

}

@keyframes jumping10 {
  0% {
    transform: rotate(180deg);
    display: block;
  }

  100% {
    transform: rotate(30deg);
    display: block;
  }

}

@keyframes jumping11 {
  0% {
    transform: rotate(180deg) translate(-20px, 20px);
    display: block;
  }

  100% {
    transform: rotate(30deg) translate(0px, 0px);
    display: block;
  }

}



@media (max-width: 1699px) {

  .video-style-02{
    padding: 0px 100px;

  }

  .about {

    &:before {
      display: none;
    }

    &:after {
      height: 61px;
      width: 70px;
      right: 90px;
      top: 60px;
    }
  }

  .video-section {

    &:before {
      display: none;
    }

    &::after {
      display: none;
    }
  }
}


@media (max-width: 1499px) {

  .video-style-02 {
    padding: 0px 30px;
  }

  .about-bg {

    img {
      width: 150px;
    }
  }

  .tournament-countdown {

    .tournament-title{

      h2{
        font-size: 36px;
      }
    }

    .upcoming-match{

      .match-team{
        width: 100px;
      }
    }
  }
}


@media (max-width: 1199px) {

  .video-style-02 {
    padding: 0px 30px;

    .video-bg {
      padding: 80px 0px;
    }

    .match-video {
      padding-top: 80px;
    }
  }

  .tournament-countdown {

    .tournament-title{

      h2{
        font-size: 48px;
      }
    }
  }

  .upcoming-match-table{

    .match-schedule{

      > div{
        padding: 0px 15px;
      }

      .upcoming-match{

        .match-team{

          img{
            height: 60px;
          }
        }
      }

      .match-date{

        span{
          font-size: 18px;
        }

        .time {
          font-size: 16px;
        }
      } 

      .tournament{
        font-size: 18px;
      }

      .venue{
        font-size: 18px;
      }

      .article{
        .btn{
          padding: 10px 25px;
        }
      }
    }
  }

  .about {
    .about-img {
      margin-right: 20px;
    }
  }
}

@media (max-width: 991px) {

  .inner-banner{
    height: 400px;

    .inner-banner-tittle {

      h2 {
        font-size: 40px;
        line-height: 50px;
      }
    }
  }

  .video-style-02 {
    padding: 0px 0px;

    .video-bg {
      padding: 60px 0px;
    }

    .match-video {
      padding-top: 60px;
    }
  }

  .about:after {
    display: none;
  }

    .about {
      .about-img {
        margin-right: 0px;
      }
    }


  /*Video Style 03*/
  .video-style-03 {
    padding-left: 30px;

    .play-btn {
      flex: 0 0 60px;
      height: 60px;
      width: 60px;

      i {
        font-size: 24px;
      }
    }
  }

  .about-bg {

    img {
      display: none;
    }
  }

  .game-countdown{
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .featured-news{
    padding-bottom: 60px;
  }

  .video-section-02{
    padding-top: 100px;
    padding-bottom: 100px;
  }


  .message-info{

    .section-title{
      margin-bottom: 40px;
    }
  }

  .contact-us-form{
    padding-left: 0px;
    margin-top: 30px;

    .section-title{
      margin-bottom: 40px;
    }
  }

  /*Ticket*/

  .ticket-box{
    padding-right: 0;
    display: block;
    padding-bottom: 30px;

    .match-img{
      width: 100%;
      margin-bottom: 30px;
    }

    .ticket-details{
      padding-right: 20px;
      padding-left: 20px;
      justify-content: space-between;
      width: 100%;
    }
  }


  .coming-soon-section{
    height: auto;
  }  

  .upcoming-match-table{

    .match-schedule{
      flex-wrap: wrap;

      > div{
        padding: 0px 15px;
        width: 50%;
        text-align: center;
        margin-bottom: 20px;

        &:before{
          left:0;
        }
      }

      .upcoming-match{
        width: 100%;
      }

      .match-date{
        &:before{
          content:inherit;
        }
      } 

      .venue{
        &:before{
          content:inherit;
        }
      }
    }
  }

  .latest-results.latest-results-about{
    padding-bottom: 80px;
  }

  .player-about{
    padding: 80px 0px;
  }

  .upcoming-match {
    .match-date-time {
      .match-time {
        font-size: 18px;
      }
    }

    .match-date-time {
      padding: 0 20px;
    }
  }

}

@media (max-width: 767px) {

  .tournament-countdown {
    padding: 40px 30px;
    border-bottom: 1px solid $border-light;
  }


  .video-section {

    .video-style-vertical {
      margin-top: 40px;

      .video-post {
        flex-direction: column;
        align-items: start;

        .video-image {
          width: 100%;
        }

        .video-post-details {
          margin-top: 20px;
        }
      }
    }
  }

  .video-style-02 {

    .video-bg {
      padding: 50px 0px;
    }

    .match-box {

      .match-team {
        display: block;

        span {
          display: block;
        }

        .team-vs {
          padding:0;
        }
      }
    } 
    .match-video {
      padding-top: 50px;
    }
  }

  /*performers*/
  .performers-box {

    .performers-info {

      .player-name {
        font-size: 30px;
      }

      .player-rank {
        font-size: 30px;
      }

      .player-position,
      .player-height,
      .player-weight {

        span {
          font-size: 18px;
        }
      }
    }
  }
  
  .game-countdown{
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .featured-news{
    padding-bottom: 50px;
  }

  .video-section-02{
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .products{

    &:after,
    &:before{
      height: 50px;
      width: 50px;
      background-size: cover;
    }

    &:after{
      right: 30px;
    }

    .pattern-7{
      max-width: 35%;
    }
  }

  .inner-banner{
    height: 300px;

    .inner-banner-tittle {

      h2 {
        font-size: 40px;
        line-height: 50px;
      }
    }
  }

  /*newsletter*/
  .newsletter{

    form{
      padding-right: 130px;

      button.btn.btn-primary{
        padding-top: 20px;
        padding-bottom: 19px;
      }
    }
  }


  .latest-results.latest-results-about{
    padding-bottom: 40px;
  }

  .player-about{
    padding: 50px 0px;
  }

  /*Ticket*/
  .ticket-box{
    padding-bottom: 20px;

    .match-img{
      width: 100%;
      margin-bottom: 25px;
    }

    .ticket-details{
      padding: 0px 20px;
      display: block;

      .ticket-info{
        padding-right: 0;
        margin-bottom: 25px;
      }

      .upcoming-match{
        justify-content: flex-start;
        margin-bottom: 25px;
      }

      .match-details{
        padding: 25px 0;
        text-align: left;
      }
    }
  }
}

@media (max-width: 575px) {

  .tournament-countdown {
    .tournament-title{
      h2{
        font-size: 28px;
      }
    }
  }
  

  .video-section {

    .video-image {

      .video-btn.popup-youtube {
        top: 40%;
        left: 45%;
        font-size: 40px;
      }
    }
  }

  .video-style-02 {

    .video-bg {
      padding: 40px 0px;
    }

    .match-box {

      .match-team {

        span {
          font-size: 30px;
        }
      }
    } 

    .match-video {
      padding-top: 40px;
    }
  }

  /*performers*/
  .performers-box {
    display: block;

    .performers-info {

      .player-name {
        font-size: 24px;
        margin-bottom: 8px;
      }

      .player-rank {
        font-size: 24px;
      }
    }

    .performers-img{
      margin-bottom: 30px;

      img{
        left:0px;
      }
    }

    .performers-img.player-02{

      img{
        right: 0;
      }
    }
  }

  .video-section-02{
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .products{

    &:after,
    &:before{
      content:none;
    }

    .pattern-7{
      display: none;
    }
  }

  .game-countdown{
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .featured-news{
    padding-bottom: 40px;
  }

  .inner-banner{

    .inner-banner-tittle {

      h2 {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }


}


@media (max-width: 479px) {

  /*Upcoming Match*/
  .upcoming-match {

    .match-date-time {
      padding: 0 15px;
      
      .match-time {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }

  .upcoming-match-table{

    .match-schedule{
      padding: 30px 20px;

      > div{
        width: 100%;

        &:before{
          content:inherit;
        }
      }

      .article{
        margin-bottom: 0px;
      }
    }
  }  

  .contant-404{

    h1 {
      font-size: 120px;
    }
  } 
}


.map{
  height: 100vh;
  filter: grayscale(100%) invert(92%) contrast(90%);
}