/*****************************
  sidebar
*****************************/

.sidebar{

	.widget{
		margin-bottom: 40px;
		
		.widget-title{
			margin-bottom: 20px;
		}

		/*sidebar-search*/
		.search{

			.search-form{
				position: relative;
    		padding-right: 105px;
        .form-control{
          background-color: rgba($white, 0.1);
          border: 1px solid rgba($white, 0.1);
          color: $white;
        }
			}
			
			.btn {
        position: absolute;
        right: 0px;
        top: 0px;
        padding:22px 40px; 
        transition: all 0.5s ease;

        &:hover{
        	transform:inherit;
        }
		  }

    	input {
        padding:10px 20px;
        height: 60px;
        font-size: 14px;
        border: 0;
    	}
		}

		/*Recent Post*/
		.recent-post {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .post-img {
        flex: 0 0 100px;
        margin-right: 15px;
        overflow: hidden;

        img{
          transition: all 0.5s ease;
          transform: scale(1);
        }
      }

      .post-info {

        .post-date {
          font-size: 14px;
          padding-bottom: 5px;
          display: block;
          color: white;

          i {
            margin-right: 8px;
          }
        }

        h6 {
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          margin-bottom: 0;
        }
      }

      &:hover{
        .post-img {
          img{
            transform: scale(1.1);
          }
        }
      }
	  }

	  /*sidebar-categories*/
    .widget-categories {

      ul.list-style {

        li {

          a {
            display: flex;
            color: $white;
            width: 100%;
            line-height: 32px;
            justify-content: space-between;
          }
        }
      }

      .list-style-underline {

        li {

          a {
            padding: 10px 0;
            border-bottom: 1px solid rgba($white,0.2);

            span {
              font-size: 10px;
            }

            &:hover {
              color: $primary;
            }
          }

          &:last-child {

            a {
              border-bottom: inherit;
            }
          }
        }
      }
    }

	  /*popular-tag*/
    .popular-tag {

      ul {

        li {
          display: inline-block;
          margin-bottom: 10px;
          margin-right: 8px;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            padding: 6px 20px;
            display: block;
            border: 1px solid rgba($white, 0.1);
            color: $white;
            background: inherit;

            &:hover {
              color: $primary;
              border: 1px solid $primary;
            }
          }
        }
      }
    }

	  /*social*/
    .social {

      ul {
        margin-bottom: 0;

        li {
          padding: 10px 10px 10px 15px;
          border: 1px solid rgba($white,0.2);
          display: flex ! important;
          margin-bottom: 10px;
          align-items: center;

          a {
            font-weight: 500;
          }

          .follow {
            background: $gray-1;
            padding: 3px 18px;
            font-size: 12px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .facebook {

          a {
            color: #466ca9;
          }

          .follow {

            &:hover {
              background: #466ca9;
              color: $white;
            }
          }
        }

        .twitter {

          a {
            color: #20b5e6;
          }

          .follow {
            &:hover {
              background: #20b5e6;
              color: $white;
            }
          }
        }

        .youtube {

          a {
            color: #d92c20;
          }

          .follow {

            &:hover {
              background: #d92c20;
              color: $white;
            }
          }
        }

        .instagram {

          a {
            color: #2673f8;
          }

          .follow {

            &:hover {
              background: #2673f8;
              color: $white;
            }
          }
        }

        .linkedIn {

          a {
            color: #13799f;
          }

          .follow {

            &:hover {
              background: #13799f;
              color: $white;
            }
          }
        }
      }
    }
	}
}


@media (max-width: 767px) {

  .sidebar{

    .widget{

      .search {
        
        .btn{
          padding: 23px 40px;
        }
      }
    }
  }  
}