/*****************************
    Tab
*****************************/
.tab{
	position: relative;

	.nav {

		.nav-item{
			margin: 0px 16px;

			.nav-link{
				font-size: 14px;
				text-transform: uppercase;
				font-weight: normal;
				color: $white;
				padding: 0;

				&:hover{
					color:$primary;
					text-decoration: underline;
					background-color: inherit;
				}
			}

			.nav-link.active{
				color:$primary;
				text-decoration: underline;
				background-color: inherit;
			}
		}
	}
}

.news-tab-nav.nav{
	margin-left: 40px;

	.nav-item{
		margin-right: 35px;

		.nav-link{
			font-size: 14px;
			text-transform: uppercase;
			font-weight: normal;
			color: $white;
			padding: 0;

			&:hover{
				color:$primary;
				text-decoration: underline;
				background-color: inherit;
			}
		}

		.nav-link.active{
			color:$primary;
			text-decoration: underline;
			background-color: inherit;
		}
	}
}


@media (max-width: 575px) {
	
	.news-tab-nav.nav{
		margin-left: 0px;
	}
}