/*****************************
  Button
*****************************/

.btn {
  border: none;
  z-index: 1;
  position: relative;
  font-size: 16px;
  padding: 15px 40px;
  overflow: hidden;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:active {
    box-shadow: none;
    outline: none;
  }
}

.btn.btn-link {
  padding: 0 !important;
}

/*Btn Color*/
.btn.btn-primary {
  background: $primary;
  color: $white;

  &:hover {
    background: $white;
    color: $black;
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-white {
  background: $white;
  color: $black;

  &:hover {
    background: $primary;
    color: $white;
    transform: translateY(-2px);
  }

  &:focus {
    color: $black;
  }
}

.btn-secondary {
  background: $secondary;
  color: $white;

  &:hover {
    background: $primary;
    transform: translateY(-2px);
  }
}

.btn-yellow {
  background-color: $yellow;
  color: $white;

  &:hover {
    background-color: $white !important;
    color: $yellow !important;
    transform: translateY(-2px);
  }
}

.bg-dark {

  .btn-primary {

    &:hover {
      background: $white;
      color: $primary;
    }
  }
}

.btn.text-secondary:hover {
  color: $primary !important;
}

.btn.text-white:hover {
  color: $primary !important;
}

.btn.text-primary:hover {
  color: $secondary !important;
}

.btn-list{

  .btn-white{

    &:hover{
      background:$primary;
      color: $white;
    }
  }
}

/*Btn Size*/
.btn.btn-lg {
  font-size: 20px;
  padding: 20px 40px;
}

.btn.btn-md {
  font-size: 14px;
  padding: 15px 30px;
}

.btn.btn-sm {
  font-size: 14px;
  padding: 12px 20px;
}

@media (max-width:767px) {

  .btn {
    font-size: 14px;
    padding: 12px 20px;

    &.btn-lg {
      font-size: 15px;
      padding: 15px 30px;
    }
  }
}
