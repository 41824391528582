/*****************************
    Portfolio
*****************************/

.portfolio{
	position: relative;
	z-index: 9;

	.portfolio-img{
		position: relative;
    overflow: hidden;

    img{
      transform: scale(1);
      transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

		&:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0%;
      display: block;
      z-index: 1;
      content: "";
      background: linear-gradient(to bottom, rgba($dark, 0.87), rgba($dark, 0.87) 100%);
      transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
	}

	.portfolio-info{
		position: absolute;
    width: 80%;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		text-align: center;
		z-index: 2;
		transition: all 0.5s ease-in-out;
		opacity: 0;

		.portfolio-sub-title{
			font-size: 18px;
      line-height: 26px;
			font-weight: bold;
			color: $white;
			display: block;
			margin-bottom: 8px;

			&:hover{
				color:$primary;
			}
		}

		.portfolio-title{
			font-size: 32px;
      line-height: 40px;
			font-weight: bold;
			color: $white;
			display: block;
			margin-bottom: 18px;

			&:hover{
				color:$primary;
			}
		}

		.btn{
			color: $primary;

			&:hover{
				color:$white;
			}
		}
	}

	&:hover{

		.portfolio-img{

			&:before {
				height:100%;
			}

      img{
        transform: scale(1.1);
      }
		}

		.portfolio-info{
			opacity: 1;
		}
	}
}


/*portfolio-style-02*/

.portfolio-style-02 {
  position: relative;
  overflow: hidden;
  border-radius: 0;

  .portfolio-images {
    position: relative;
    border-radius: 0;
  }

  .portfolio-img{
    border-radius: 0;
    overflow: hidden;
  }

  .portfolio-info-02 {
    background: transparent;

    .popup-icon {
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 0;
      position: absolute;
      margin: 0 auto;
      top: 40%;
      opacity: 0;
      visibility: hidden;
      right: 0;
      left: 0;
      text-align: center;
      transition: all 0.3s ease-in-out;
      background: $white;
      transform: translateY(-50%);

      &:before{
      	content:inherit;
      }
    }
  }

  &:hover {

    img{
      transform: scale(1.1);
    }

    .portfolio-info-02 {

      .popup-icon {
        opacity: 1;
        top: 50%;
        visibility: visible;
      }
    }
  }

  img {
    transform: scale(1);
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
}

/*Portfolio Style 03*/
.portfolio.portfolio-style-03{
  position: relative;

  .portfolio-img {
    position: relative;
    border-radius: 0;
    overflow: hidden;
    transition: transform 0.28s ease;
    z-index: 2;

    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40%;
      display: block;
      z-index: 1;
      content: "";
      background: linear-gradient(to bottom, rgba($dark, 0.0), rgba($dark, 0.87) 100%);
      transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  .portfolio-info{
    opacity: 1;
    position: absolute;
    left: 0;
    transform: inherit;
    text-align: left;
    top: inherit;
    bottom: 0px;
    padding: 20px;
    width: 100%;

    .portfolio-title{
      margin-bottom: 0px;
    }
  }

  &:hover{

    .portfolio-img {

      &:before {
        height: 100%;
        background: linear-gradient(to bottom, rgba($dark, 0.30), rgba($dark, 0.87) 100%);
      }
    }
  }
}

.filters-group {
  display: table;
  margin: 0 auto 30px;
  text-align: center;

  button {
    background: $primary;
    border: none;
    z-index: 1;
    position: relative;
    font-size: 16px;
    padding: 15px 40px;
    color: $white;
    border-radius: 0;
    transition: all 0.3s ease-in-out;
    margin: 0 5px;

    &:hover {
    	background: $white;
      color: $primary;
      box-shadow: none;
      outline: none;
    }

    &:focus {
      box-shadow: none;
      outline: none;
      color: $white;
    }

    &:active {
      box-shadow: none;
      outline: none;
      color: $white;
    }
  }

  button.active {
    background-color:$white;
    color:$primary;
  }
}

.my-shuffle-container {
  margin-left: -15px;
  margin-right: -15px;

  .grid-item {
    width: 33.33%;
    padding: 15px;
  }
}

.my-shuffle-container {

  .portfolio {
    margin-bottom: 0;
  }
}

/*************************************
         shuffle
 *************************************/

.my-shuffle-container.columns-1 {

  .grid-item {
    width: 100%;
    padding: 15px;
  }
}

.my-shuffle-container.columns-2 {

  .grid-item {
    width: 50%;
    padding: 15px;
    float: left;
  }
}

.my-shuffle-container.columns-3 {

  .grid-item {
    width: 33.33333333%;
    padding: 15px;
  }
}

.my-shuffle-container.columns-4 {

  .grid-item {
    width: 25%;
    padding: 10px;
  }
}

.my-shuffle-container.columns-5 {

  .grid-item {
    width: 20%;
    padding: 15px;
  }
}

.my-shuffle-container.columns-6 {

  .grid-item {
    width: 16.666666%;
    padding: 0px;
  }
}

.my-shuffle-container.columns-2 .grid-item-full,
.my-shuffle-container.columns-3 .grid-item-full,
.my-shuffle-container.columns-4 .grid-item-full,
.my-shuffle-container.columns-5 .grid-item-full,
.my-shuffle-container.columns-6 .grid-item-full {
  width: 100%;
}

/* Responsive */
@media (max-width:991px) {

  .filters-group button {
    margin: 0 5px 10px;
  }

  .filters-group button:first-child {
    margin-left: 0;
  }

  .filters-group button:last-child {
    margin-right: 0;
  }
}

@media (max-width:1199px) {

  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-5 .grid-item {
    width: 25%;
  }
}

@media (max-width:991px) {

  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 33.333%;
  }
}

@media (max-width:767px) {

  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-3 .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 50%;
  }
}

@media (max-width:575px) {

  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-2 .grid-item,
  .my-shuffle-container.columns-3 .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 100%;
  }

  .my-shuffle-container {
    margin: 0;
  }
}