/*****************************
    Search
*****************************/

#search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: $dark;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 9999;

  input[type="search"] {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 50%;
    color: $gray-2;
    background: $dark;
    font-size: 36px;
    font-weight: 300;
    text-align: center;
    border: $border-color;
    border-radius: 0;
    margin: 0px auto;
    margin-top: -51px;
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    text-transform: capitalize;
  }

  .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 61px;
    margin-left: -45px;
  }

  .close {
    position: fixed;
    top: 15px;
    right: 15px;
    background-color: $secondary;
    color: $primary;
    opacity: 1;
    padding: 3px 17px;
    font-size: 27px;
    border: transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &:hover{
      background-color:$primary;
      color: $white;
    }
  }
}

#search.open {
  opacity: 1;
  visibility:visible ;
}