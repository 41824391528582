/*****************************
  Owl carousel
*****************************/

.owl-carousel {

	.owl-nav {

		button {
			position: absolute;
			bottom:0; 
			display: flex;
			height: 35px;
			width: 35px;
			flex: 0 0 35px;
			align-items: center;
			justify-content: center;
			background-color: $white !important;
			color: $dark !important;
			transition: all 0.3s ease-in-out;

			&:hover {
				background-color: $primary !important;
				color: $white !important;
			}
		}

		button.owl-prev {
			left: 0px;
		}

		button.owl-next {
			left: 45px;
		}
	}

	.owl-dots {
		position: absolute;
		bottom: 0px;

		.owl-dot {
			margin-right: 10px;

			span {
				background-color: $white;
				height: 15px;
    			width: 15px;
    			display: inline-block;

				&:hover {
					background-color: $primary;
				}
			}

			&:last-child{
				margin-right: 0px;
			}
		}

		.owl-dot.active {

			span {
				background-color: $primary;
			}
		}
	}
}


.owl-carousel.arrow-top-right{	

	.owl-nav {
		position: absolute;
		top: -60px;
   		right: 80px;
	}
}

.match-schedule-slider{

	.item{
		border: 1px solid rgba($white,0.2);
		padding: 15px;
		height: 110px;
		display: flex;
		align-items: center;
		// backdrop-filter: blur(13px) !important;
		// background: rgba(255, 255, 255, 0.12) !important;
		justify-content: center;
	}
}


/*Dots Bottom Center*/

.owl-carousel.dots-bottom-center{
	padding-bottom: 40px;
	
	.owl-dots {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0px;
	}
}


@media (max-width:570px) {

	.match-schedule-slider .item {
     	border-right: 1px solid #ccc; 
	}
}

@media (max-width:479px) {

	.client-logo{

		.owl-carousel{

			.item{
				display: flex;
				justify-content: center;
			}
		}
	}

	.owl-carousel.arrow-top-right {
		.owl-nav {
			display: none;
		}
	}
}