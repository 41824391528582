/*
Template: PGSports - Sports HTML Template
Version: 1.0.0
Author: potenzaglobalsolutions
Design and Developed by: potenzaglobalsolutions.com

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template.
*/
/*==============================================
[  Table of contents  ]
================================================

 :: Helper Classes
 :: Typography
 :: Shortcodes
    - Accordion
    - Button
    - Countdown
    - Counter
    - Event
    - Feature Info
    - Owl-carousel
		- Portfolio
		- Product
    - Search
    - Select
    - Sidebar
    - Tab
    - Table
    - Team
    - Testimonial
 :: Header
   - Header Default
   - Header Style 02
   - Header Style 03
 :: Banner
   - Banner
   - Banner 01
   - Banner 02
   - Banner 03
 :: Layout
   - Back To Top
   - Social Icon
   - Live Videos
     - Video Style 02
     - Video Style 03
   - Latest Results
   - Upcoming Match
   - Performers
   - Clients
   - Inner Page
   - Progress Bar
   - Newsletter
   - Pagination
   - Coming Soon
   - 404
   - Ticket
   - Match Schedule Table
   - Keyframes

 :: Blog
   - Blog Style 01
   - Blog Style 02
   - Featured News
   - Blog
   - Blog Detail

 :: Shop
   - Product
   - Product Detail
    - Commentlist
    - Checkout
    - Cart
    - Shop Category
    - Shop Sidebar
    
 :: Footer
   - Footer
   - Footer Style 02
   - Footer Style 03

 :: Theme-yellow
 :: Theme-blue

======================================
[ End table content ]
======================================*/
/*****************************
    Helper Classes
*****************************/
/*Color*/
.text-primary {
  color: #d41640 !important; }

.text-secondary {
  color: #ffffff !important; }

.text-white {
  color: #ffffff; }

.text-dark {
  color: #ffffff !important; }

.text-light {
  color: #8d8c8c !important; }

.text-yellow {
  color: #e5af13 !important; }

/*Background*/
.bg-primary {
  background: #d41640 !important; }

.bg-dark {
  background: #ffffff !important; }

.bg-secondary {
  background: #ffffff !important; }

.bg-light {
  background: #35343c !important; }

.bg-black {
  background: #000000 !important; }

.bg-yellow {
  background: #e5af13 !important; }

.bg-blue {
  background: #0751df !important; }

/* fill */
.fill-primary {
  fill: #d41640;
  fill-rule: nonzero; }

.fill-white {
  fill: #ffffff;
  fill-rule: evenodd; }

/*Img Holder*/
.bg-holder {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%; }

.bg-holder-bottom {
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 100%; }

/*Page Section Margin Padding*/
.space-ptb {
  padding: 100px 0; }

.space-pt {
  padding: 100px 0 0; }

.space-pb {
  padding: 0 0 100px; }

.section-title.border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 30px;
  padding-bottom: 20px; }

/* Sticky Column */
.is-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 130px; }

/*Background Overlay*/
.bg-overlay-half-bottom {
  position: relative; }

.bg-overlay-black-1 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-1:before {
    background: rgba(0, 0, 0, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-2 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-2:before {
    background: rgba(0, 0, 0, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-3 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-3:before {
    background: rgba(0, 0, 0, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-4 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-4:before {
    background: rgba(0, 0, 0, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-5 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-5:before {
    background: rgba(0, 0, 0, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-6 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-6:before {
    background: rgba(0, 0, 0, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-7 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-7:before {
    background: rgba(0, 0, 0, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-8 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-8:before {
    background: rgba(0, 0, 0, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-9 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-9:before {
    background: rgba(0, 0, 0, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-1 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-1:before {
    background: rgba(255, 255, 255, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-secondary-2 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-2:before {
    background: rgba(255, 255, 255, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-3 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-3:before {
    background: rgba(255, 255, 255, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-4 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-4:before {
    background: rgba(255, 255, 255, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-5 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-5:before {
    background: rgba(255, 255, 255, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-secondary-6 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-6:before {
    background: rgba(255, 255, 255, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-secondary-7 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-7:before {
    background: rgba(255, 255, 255, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-8 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-8:before {
    background: rgba(255, 255, 255, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-9 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-9:before {
    background: rgba(255, 255, 255, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-dark-1 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-1:before {
    background: rgba(18, 17, 26, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-dark-2 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-2:before {
    background: rgba(18, 17, 26, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-dark-3 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-3:before {
    background: rgba(18, 17, 26, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-dark-4 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-4:before {
    background: rgba(18, 17, 26, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-dark-5 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-5:before {
    background: rgba(18, 17, 26, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-dark-6 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-6:before {
    background: rgba(18, 17, 26, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-dark-7 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-7:before {
    background: rgba(18, 17, 26, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-dark-8 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-8:before {
    background: rgba(18, 17, 26, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-dark-9 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-9:before {
    background: rgba(18, 17, 26, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.overlay-top {
  margin-top: -270px; }

/*List*/
.list ul li {
  margin-bottom: 8px; }
  .list ul li i {
    color: #d41640; }
  .list ul li:last-child {
    margin-bottom: 0; }

ul.list li {
  margin-bottom: 8px; }
  ul.list li i {
    color: #d41640;
    font-size: 14px;
    margin-right: 8px; }
  ul.list li:last-child {
    margin-bottom: 0; }

@media (max-width: 1200px) {
  .space-ptb {
    padding: 80px 0; }
  .space-pt {
    padding: 80px 0 0; }
  .space-pb {
    padding: 0 0 80px; } }

@media (max-width: 991px) {
  .space-ptb {
    padding: 60px 0; }
  .space-pt {
    padding: 60px 0 0; }
  .space-pb {
    padding: 0 0 60px; } }

@media (max-width: 767px) {
  .space-ptb {
    padding: 50px 0; }
  .space-pt {
    padding: 50px 0 0; }
  .space-pb {
    padding: 0 0 50px; } }

@media (max-width: 575px) {
  .space-ptb {
    padding: 40px 0; }
  .space-pt {
    padding: 40px 0 0; }
  .space-pb {
    padding: 0 0 40px; } }

/*****************************
    Typography
*****************************/
body {
  font-family: "Josefin Sans", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 1.5;
  color: #8d8c8c;
  overflow-x: hidden;
  font-weight: 300;
  background: #12111a; }

ul {
  margin-bottom: 0; }

a {
  outline: medium none !important;
  color: #d41640;
  transition: all 0.3s ease-in-out;
  text-decoration: none !important; }
  a:focus {
    color: #d41640;
    text-decoration: none !important; }
  a:hover {
    color: #d41640;
    text-decoration: none !important; }

input {
  outline: medium none !important;
  color: #d41640; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Josefin Sans", sans-serif;
  font-weight: normal;
  color: #ffffff;
  margin-top: 0px;
  font-weight: 400;
  margin-bottom: 0; }
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit; }

label {
  font-weight: normal; }

h1 {
  font-size: 92px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.1; }

h2 {
  font-size: 48px;
  font-weight: 500; }

h3 {
  font-size: 34px;
  font-weight: 500; }

h4 {
  font-size: 28px;
  font-weight: 500; }

h5 {
  font-size: 24px;
  font-weight: 500; }

h6 {
  font-size: 22px;
  font-weight: 500; }

p {
  line-height: 1.5;
  color: #ffffff; }

*::-moz-selection {
  background: #d41640;
  color: #ffffff;
  text-shadow: none; }

::-moz-selection {
  background: #d41640;
  color: #ffffff;
  text-shadow: none; }

::selection {
  background: #d41640;
  color: #ffffff;
  text-shadow: none; }

.fw-medium {
  font-weight: 500; }

.fw-600 {
  font-weight: 600; }

.border-radius {
  border-radius: 6px; }

.border-radius-sm {
  border-radius: 4px; }

.border-radius-md {
  border-radius: 10px; }

.border-radius-lg {
  border-radius: 50%; }

.box-shadow {
  box-shadow: 0px 1px 30px 0px rgba(255, 255, 255, 0.09); }

.box-shadow-sm {
  box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.1); }

.box-shadow-lg {
  box-shadow: 0 1rem 3rem rgba(255, 255, 255, 0.175); }

/* form-control */
.form-control, .form-select {
  border: 1px solid #ededed;
  border-radius: 0px;
  height: 60px;
  padding: 10px 20px;
  box-shadow: none;
  font-size: 16px;
  font-weight: 500;
  color: #12111a;
  background-clip: inherit !important; }
  .form-control:focus, .form-select:focus {
    box-shadow: none;
    border-color: #d41640; }

textarea.form-control {
  height: auto; }

.avatar-lg {
  min-width: 80px;
  width: 80px; }

/* input-group-prepend */
.input-group-prepend .input-group-text,
.input-group-append .input-group-text {
  width: 50px;
  background: transparent;
  border-color: #ededed;
  box-shadow: none;
  justify-content: center; }

/* file-upload */
.input-group.file-upload {
  height: 50px; }
  .input-group.file-upload .form-control {
    height: 50px;
    color: #8d8c8c;
    border-radius: 3px;
    border-color: #ededed;
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 0;
    opacity: 0;
    border-radius: 3px; }
  .input-group.file-upload .input-group-text {
    height: 50px;
    font-size: 14px;
    font-weight: 600;
    color: #8d8c8c;
    border-color: #ededed;
    padding: 15px 90px 15px 20px;
    border-radius: 3px;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    line-height: 1.5;
    background-color: #ffffff;
    justify-content: space-between;
    margin-left: 0; }
    .input-group.file-upload .input-group-text:after {
      content: "Browse";
      height: 48px;
      padding: 14px 25px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      display: block;
      line-height: 1.5;
      color: #ffffff;
      background-color: #35343c;
      border-left: inherit;
      border-radius: 0px 3px 3px 0px; }

@media (max-width: 1400px) {
  h1 {
    font-size: 90px; } }

@media (max-width: 1199px) {
  h1 {
    font-size: 80px; } }

@media (max-width: 991px) {
  h1 {
    font-size: 65px;
    line-height: 1; } }

@media (max-width: 767px) {
  h1 {
    font-size: 60px; }
  h2 {
    font-size: 30px; }
  h3 {
    font-size: 26px; }
  h4 {
    font-size: 24px; } }

@media (max-width: 575px) {
  h1 {
    font-size: 30px; }
  h2 {
    font-size: 28px; } }

/*****************************
 Accordion
*****************************/
.accordion .accordion-item {
  border: 0;
  background-color: inherit;
  margin-bottom: 20px; }
  .accordion .accordion-item .accordion-header {
    border-bottom: none;
    padding: 0;
    margin-bottom: 0px; }
    .accordion .accordion-item .accordion-header .accordion-button {
      font-size: 18px;
      padding: 15px 20px;
      font-weight: 600;
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.1); }
      .accordion .accordion-item .accordion-header .accordion-button:after {
        content: "\2b";
        background-image: inherit;
        font-family: 'Font Awesome 5 Free';
        font-size: 18px;
        margin-right: 0px;
        color: #ffffff; }
      .accordion .accordion-item .accordion-header .accordion-button:hover {
        color: #d41640; }
    .accordion .accordion-item .accordion-header .accordion-button:hover:after {
      color: #d41640; }
    .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
      background-color: #d41640;
      box-shadow: inherit;
      outline: inherit;
      color: #ffffff; }
      .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):after {
        content: "\f068";
        background-image: inherit;
        font-family: 'Font Awesome 5 Free';
        transform: inherit;
        color: #ffffff; }
  .accordion .accordion-item .accordion-button:focus {
    border-color: inherit;
    box-shadow: inherit; }
  .accordion .accordion-item .accordion-collapse .accordion-body {
    padding: 20px 15px;
    color: #8d8c8c; }
  .accordion .accordion-item:last-child .accordion-header {
    margin-bottom: 0; }
  .accordion .accordion-item:last-child .accordion-body {
    padding-top: 30px;
    padding-bottom: 0px; }

.frequently-asked-questions .sidebar .widget .submit-form .form-control {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.1); }

.checkout-review .accordion .card {
  border: none;
  padding: 8px 0px;
  margin-bottom: 5px;
  background-color: inherit; }
  .checkout-review .accordion .card .accordion-icon.card-header {
    background: none;
    padding: 0;
    border-bottom: none; }
    .checkout-review .accordion .card .accordion-icon.card-header button {
      padding: 0px 0px 0px 30px;
      font-size: 18px;
      font-weight: 600;
      color: #d41640; }
      .checkout-review .accordion .card .accordion-icon.card-header button:before {
        font-size: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        content: "\f068";
        font-family: "Font Awesome 5 Free";
        border: 2px solid #d41640;
        width: 18px;
        height: 18px;
        line-height: 14px;
        border-radius: 0;
        z-index: 9;
        text-align: center;
        font-weight: 600; }
    .checkout-review .accordion .card .accordion-icon.card-header button.collapsed {
      color: #ffffff; }
      .checkout-review .accordion .card .accordion-icon.card-header button.collapsed:before {
        content: "\f067"; }
  .checkout-review .accordion .card .card-body {
    padding: 10px 0px 0px 30px;
    color: #ffffff; }

.map-section .accordion {
  margin-left: 50px; }

.asked-questions .questions-title {
  font-weight: 600;
  display: flex;
  font-size: 20px;
  line-height: 30px; }
  .asked-questions .questions-title .number {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #d41640;
    display: flex;
    flex: 0 0 32px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-right: 20px;
    color: #ffffff; }

.booking-02 {
  padding: 40px 28px;
  box-shadow: 0px 1px 30px 0px rgba(255, 255, 255, 0.09);
  border-radius: 6px; }
  .booking-02 .accordion .custom-control .custom-control-label .accordion-title {
    font-weight: 700; }

@media (max-width: 991px) {
  .map-section .accordion {
    margin-left: 0px; } }

@media (max-width: 767px) {
  .accordion .accordion-item h3.accordion-header {
    padding-bottom: 15px;
    margin-bottom: 20px;
    font-size: 20px; }
    .accordion .accordion-item h3.accordion-header .accordion-button:after {
      margin-right: 0px; } }

/*****************************
  Button
*****************************/
.btn {
  border: none;
  z-index: 1;
  position: relative;
  font-size: 16px;
  padding: 15px 40px;
  overflow: hidden;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize; }
  .btn:focus {
    box-shadow: none;
    outline: none; }
  .btn:active {
    box-shadow: none;
    outline: none; }

.btn.btn-link {
  padding: 0 !important; }

/*Btn Color*/
.btn.btn-primary {
  background: #d41640;
  color: #ffffff; }
  .btn.btn-primary:hover {
    background: #ffffff;
    color: #000000;
    transform: translateY(-2px); }
  .btn.btn-primary:active {
    box-shadow: none; }
  .btn.btn-primary:focus {
    box-shadow: none; }

.btn-white {
  background: #ffffff;
  color: #000000; }
  .btn-white:hover {
    background: #d41640;
    color: #ffffff;
    transform: translateY(-2px); }
  .btn-white:focus {
    color: #000000; }

.btn-secondary {
  background: #ffffff;
  color: #ffffff; }
  .btn-secondary:hover {
    background: #d41640;
    transform: translateY(-2px); }

.btn-yellow {
  background-color: #e5af13;
  color: #ffffff; }
  .btn-yellow:hover {
    background-color: #ffffff !important;
    color: #e5af13 !important;
    transform: translateY(-2px); }

.bg-dark .btn-primary:hover {
  background: #ffffff;
  color: #d41640; }

.btn.text-secondary:hover {
  color: #d41640 !important; }

.btn.text-white:hover {
  color: #d41640 !important; }

.btn.text-primary:hover {
  color: #ffffff !important; }

.btn-list .btn-white:hover {
  background: #d41640;
  color: #ffffff; }

/*Btn Size*/
.btn.btn-lg {
  font-size: 20px;
  padding: 20px 40px; }

.btn.btn-md {
  font-size: 14px;
  padding: 15px 30px; }

.btn.btn-sm {
  font-size: 14px;
  padding: 12px 20px; }

@media (max-width: 767px) {
  .btn {
    font-size: 14px;
    padding: 12px 20px; }
    .btn.btn-lg {
      font-size: 15px;
      padding: 15px 30px; } }

/*****************************
  Countdown
*****************************/
.countdown-main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .countdown-main .countdown {
    display: flex;
    border: 1px solid #ffffff;
    padding: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 100%; }
    .countdown-main .countdown span {
      color: #ffffff;
      font-size: 28px; }
    .countdown-main .countdown p {
      margin-bottom: 0;
      color: #ffffff; }

/*Game Countdown*/
.game-countdown .countdown-main {
  position: relative; }
  .game-countdown .countdown-main .countdown {
    position: relative;
    display: flex;
    border: none;
    padding: 50px;
    height: inherit;
    width: inherit;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: inherit;
    margin: 0px; }
    .game-countdown .countdown-main .countdown span {
      color: #ffffff;
      font-size: 100px;
      line-height: 100px;
      font-weight: bold; }
      .game-countdown .countdown-main .countdown span:before {
        position: absolute;
        content: ":";
        top: 35%;
        transform: translateY(-50%);
        right: -8px;
        color: #ffffff;
        font-size: 100px;
        font-weight: bold; }
    .game-countdown .countdown-main .countdown p {
      margin-bottom: 0;
      color: #ffffff;
      font-size: 40px;
      line-height: 40px;
      font-weight: bold;
      text-transform: uppercase; }
    .game-countdown .countdown-main .countdown:last-child:before {
      display: none; }
    .game-countdown .countdown-main .countdown:last-child span:before {
      display: none; }

/*Coming Soon*/
.coming-soon-section .countdown-main {
  margin: 50px 0px; }
  .coming-soon-section .countdown-main .countdown {
    margin: 0px 20px;
    border: none;
    border-radius: 0; }
    .coming-soon-section .countdown-main .countdown span {
      font-size: 80px;
      color: #ffffff; }
    .coming-soon-section .countdown-main .countdown p {
      font-size: 20px;
      color: #d41640; }

.coming-soon-section .coming-soon-form {
  position: relative;
  padding-right: 175px; }
  .coming-soon-section .coming-soon-form .btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px 40px 16px; }

@media (min-width: 1500px) {
  .countdown-main .countdown {
    height: 100px;
    width: 100px;
    margin: 5px; } }

@media (max-width: 1499px) {
  .countdown-main .countdown {
    height: 80px;
    width: 80px;
    margin: 5px; }
    .countdown-main .countdown span {
      font-size: 22px;
      line-height: 23px; } }

@media (max-width: 1299px) {
  .countdown-main .countdown {
    height: 110px;
    width: 110px;
    margin: 10px; }
    .countdown-main .countdown span {
      font-size: 28px;
      line-height: 30px; } }

@media (max-width: 1199px) {
  .game-countdown .countdown-main .countdown {
    padding: 30px; }
    .game-countdown .countdown-main .countdown span {
      font-size: 60px;
      line-height: 60px; }
      .game-countdown .countdown-main .countdown span:before {
        font-size: 60px; }
    .game-countdown .countdown-main .countdown p {
      font-size: 30px;
      line-height: 30px; } }

@media (max-width: 991px) {
  .game-countdown .countdown-main .countdown {
    padding: 20px; }
    .game-countdown .countdown-main .countdown span {
      font-size: 48px;
      line-height: 48px; }
      .game-countdown .countdown-main .countdown span:before {
        font-size: 48px; }
    .game-countdown .countdown-main .countdown p {
      font-size: 24px;
      line-height: 24px; } }

@media (max-width: 767px) {
  .countdown-main .countdown {
    height: 90px;
    width: 90px;
    margin: 5px; }
    .countdown-main .countdown span {
      font-size: 22px; }
  .game-countdown .countdown-main .countdown {
    padding: 15px; }
    .game-countdown .countdown-main .countdown span {
      font-size: 30px;
      line-height: 30px; }
      .game-countdown .countdown-main .countdown span:before {
        font-size: 30px; }
    .game-countdown .countdown-main .countdown p {
      font-size: 18px;
      line-height: 18px; }
  /*Coming Soon*/
  .coming-soon-section h1 {
    font-size: 50px; }
  .coming-soon-section .countdown-main {
    margin-top: 30px 0px; }
    .coming-soon-section .countdown-main .countdown {
      margin: 0px 10px; }
      .coming-soon-section .countdown-main .countdown span {
        font-size: 42px;
        line-height: 50px; }
      .coming-soon-section .countdown-main .countdown p {
        font-size: 18px; }
  .coming-soon-section .coming-soon-form {
    padding-right: 115px; }
    .coming-soon-section .coming-soon-form .btn {
      padding: 20px 20px 19px; } }

@media (max-width: 575px) {
  .game-countdown .countdown-main .countdown {
    width: 50%; }
    .game-countdown .countdown-main .countdown span:before {
      content: inherit; } }

/*****************************
  Counter
*****************************/
.counter {
  position: relative;
  padding: 40px 30px;
  z-index: 2; }
  .counter .counter-number {
    text-align: center; }
    .counter .counter-number .counter-text {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%; }
      .counter .counter-number .counter-text .timer {
        transition: all 0.5s ease; }
      .counter .counter-number .counter-text span {
        font-size: 50px;
        line-height: 1;
        color: #d41640;
        font-weight: bold; }
    .counter .counter-number p {
      font-size: 18px;
      color: #d41640;
      font-weight: bold;
      transition: all 0.3s ease; }
  .counter:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: all 0.3s ease;
    z-index: -1; }
  .counter:hover .counter-number .counter-text .timer {
    transform: scale(1.1); }
  .counter:hover:before {
    background-color: rgba(212, 22, 64, 0.3); }
  .counter:hover p {
    color: #ffffff; }

/*****************************
  Event
*****************************/
.events-box {
  position: relative; }
  .events-box .events-img {
    position: relative;
    overflow: hidden;
    transition: transform 0.28s ease; }
    .events-box .events-img:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 85%;
      display: block;
      z-index: 1;
      content: "";
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(15, 15, 15, 0.9) 100%);
      transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
      cursor: pointer; }
  .events-box .events-details {
    position: absolute;
    padding: 20px;
    padding-top: 30px;
    width: 90%;
    bottom: 20px;
    z-index: 2;
    background-color: rgba(18, 17, 26, 0.9);
    left: 0;
    right: 0;
    margin: 0 auto; }
    .events-box .events-details .events-date {
      position: absolute;
      top: -60px;
      background-color: #d41640;
      width: 90px;
      height: 70px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      text-align: center; }
      .events-box .events-details .events-date span {
        display: block;
        font-size: 26px;
        font-weight: 700;
        margin-top: 4px; }
    .events-box .events-details .events-title {
      margin-bottom: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical; }
    .events-box .events-details .events-meta {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      color: #d41640; }
      .events-box .events-details .events-meta .events-time .time {
        color: #ffffff;
        padding-left: 8px; }
      .events-box .events-details .events-meta .events-location .location {
        color: #ffffff;
        padding-left: 8px; }

/*****************************
  Feature Info
*****************************/
.feature-item .feature-icon {
  padding-bottom: 15px;
  display: flex; }

.feature-item .feature-content .feature-title {
  padding-bottom: 15px;
  margin-bottom: 0;
  font-weight: 600; }

.feature-item .feature-content p {
  margin-bottom: 0; }

/*feature style 01*/
.feature-item.feature-info-style-01 {
  margin-bottom: 30px;
  display: flex; }
  .feature-item.feature-info-style-01 .feature-icon {
    padding-bottom: 0;
    height: 100px;
    min-width: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 30px;
    background-color: #ffffff; }
    .feature-item.feature-info-style-01 .feature-icon object {
      width: 40px;
      filter: invert(17%) sepia(83%) saturate(3482%) hue-rotate(335deg) brightness(89%) contrast(99%);
      transition: all 0.3s ease-in-out; }
  .feature-item.feature-info-style-01 .feature-content .feature-title {
    color: #ffffff;
    padding-bottom: 7px;
    display: block;
    margin-bottom: 0;
    font-weight: 600; }
  .feature-item.feature-info-style-01 .feature-content span {
    display: block;
    color: #ffffff; }
  .feature-item.feature-info-style-01 .feature-content p {
    margin-bottom: 0;
    color: #ffffff; }

/*feature style 02*/
.feature-item.feature-info-style-02 {
  display: block;
  padding: 23px 40px;
  border: 1px solid rgba(255, 255, 255, 0.2); }
  .feature-item.feature-info-style-02 .feature-icon {
    margin-right: 0;
    background-color: inherit; }
    .feature-item.feature-info-style-02 .feature-icon object {
      width: 70px;
      filter: invert(17%) sepia(83%) saturate(3482%) hue-rotate(335deg) brightness(89%) contrast(99%);
      transition: all 0.3s ease-in-out; }

.feature-item.feature-info-style-02.feature-info-center {
  text-align: center; }
  .feature-item.feature-info-style-02.feature-info-center .feature-icon {
    display: inherit;
    justify-content: inherit; }

/*History info*/
.history-info .history-info-inner {
  position: relative; }
  .history-info .history-info-inner .history-info-item {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 100px; }
    .history-info .history-info-inner .history-info-item .history-info-img {
      flex: 0 0 40%; }
    .history-info .history-info-inner .history-info-item .history-info-number {
      flex: 0 0 20%;
      text-align: center; }
      .history-info .history-info-inner .history-info-item .history-info-number .time {
        height: 90px;
        width: 90px;
        display: inline-block;
        background-color: #ffffff;
        border-radius: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0; }
        .history-info .history-info-inner .history-info-item .history-info-number .time span {
          display: block; }
        .history-info .history-info-inner .history-info-item .history-info-number .time .year {
          color: #d41640; }
    .history-info .history-info-inner .history-info-item .history-info-content {
      flex: 0 0 40%; }
    .history-info .history-info-inner .history-info-item:before {
      position: absolute;
      top: 0;
      content: "";
      left: 50%;
      height: 100%;
      width: 2px;
      background: rgba(255, 255, 255, 0.2); }
    .history-info .history-info-inner .history-info-item:last-child:before {
      content: inherit; }

@media (max-width: 991px) {
  .feature-item.feature-info-style-02 {
    margin-bottom: 30px; }
  .history-info .history-info-inner .history-info-item {
    padding-bottom: 60px; } }

@media (max-width: 767px) {
  .history-info .history-info-inner .history-info-item {
    display: block;
    padding-bottom: 30px; }
    .history-info .history-info-inner .history-info-item:before {
      content: inherit; }
    .history-info .history-info-inner .history-info-item .history-info-number {
      position: relative;
      margin-top: -40px;
      margin-bottom: 120px; } }

/*****************************
  Owl carousel
*****************************/
.owl-carousel .owl-nav button {
  position: absolute;
  bottom: 0;
  display: flex;
  height: 35px;
  width: 35px;
  flex: 0 0 35px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff !important;
  color: #12111a !important;
  transition: all 0.3s ease-in-out; }
  .owl-carousel .owl-nav button:hover {
    background-color: #d41640 !important;
    color: #ffffff !important; }

.owl-carousel .owl-nav button.owl-prev {
  left: 0px; }

.owl-carousel .owl-nav button.owl-next {
  left: 45px; }

.owl-carousel .owl-dots {
  position: absolute;
  bottom: 0px; }
  .owl-carousel .owl-dots .owl-dot {
    margin-right: 10px; }
    .owl-carousel .owl-dots .owl-dot span {
      background-color: #ffffff;
      height: 15px;
      width: 15px;
      display: inline-block; }
      .owl-carousel .owl-dots .owl-dot span:hover {
        background-color: #d41640; }
    .owl-carousel .owl-dots .owl-dot:last-child {
      margin-right: 0px; }
  .owl-carousel .owl-dots .owl-dot.active span {
    background-color: #d41640; }

.owl-carousel.arrow-top-right .owl-nav {
  position: absolute;
  top: -60px;
  right: 80px; }

.match-schedule-slider .item {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 15px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center; }

/*Dots Bottom Center*/
.owl-carousel.dots-bottom-center {
  padding-bottom: 40px; }
  .owl-carousel.dots-bottom-center .owl-dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px; }

@media (max-width: 570px) {
  .match-schedule-slider .item {
    border-right: 1px solid #ccc; } }

@media (max-width: 479px) {
  .client-logo .owl-carousel .item {
    display: flex;
    justify-content: center; }
  .owl-carousel.arrow-top-right .owl-nav {
    display: none; } }

/*****************************
    Portfolio
*****************************/
.portfolio {
  position: relative;
  z-index: 9; }
  .portfolio .portfolio-img {
    position: relative;
    overflow: hidden; }
    .portfolio .portfolio-img img {
      transform: scale(1);
      transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    .portfolio .portfolio-img:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0%;
      display: block;
      z-index: 1;
      content: "";
      background: linear-gradient(to bottom, rgba(18, 17, 26, 0.87), rgba(18, 17, 26, 0.87) 100%);
      transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
      transition: all 0.3s ease-in-out;
      cursor: pointer; }
  .portfolio .portfolio-info {
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;
    transition: all 0.5s ease-in-out;
    opacity: 0; }
    .portfolio .portfolio-info .portfolio-sub-title {
      font-size: 18px;
      line-height: 26px;
      font-weight: bold;
      color: #ffffff;
      display: block;
      margin-bottom: 8px; }
      .portfolio .portfolio-info .portfolio-sub-title:hover {
        color: #d41640; }
    .portfolio .portfolio-info .portfolio-title {
      font-size: 32px;
      line-height: 40px;
      font-weight: bold;
      color: #ffffff;
      display: block;
      margin-bottom: 18px; }
      .portfolio .portfolio-info .portfolio-title:hover {
        color: #d41640; }
    .portfolio .portfolio-info .btn {
      color: #d41640; }
      .portfolio .portfolio-info .btn:hover {
        color: #ffffff; }
  .portfolio:hover .portfolio-img:before {
    height: 100%; }
  .portfolio:hover .portfolio-img img {
    transform: scale(1.1); }
  .portfolio:hover .portfolio-info {
    opacity: 1; }

/*portfolio-style-02*/
.portfolio-style-02 {
  position: relative;
  overflow: hidden;
  border-radius: 0; }
  .portfolio-style-02 .portfolio-images {
    position: relative;
    border-radius: 0; }
  .portfolio-style-02 .portfolio-img {
    border-radius: 0;
    overflow: hidden; }
  .portfolio-style-02 .portfolio-info-02 {
    background: transparent; }
    .portfolio-style-02 .portfolio-info-02 .popup-icon {
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 0;
      position: absolute;
      margin: 0 auto;
      top: 40%;
      opacity: 0;
      visibility: hidden;
      right: 0;
      left: 0;
      text-align: center;
      transition: all 0.3s ease-in-out;
      background: #ffffff;
      transform: translateY(-50%); }
      .portfolio-style-02 .portfolio-info-02 .popup-icon:before {
        content: inherit; }
  .portfolio-style-02:hover img {
    transform: scale(1.1); }
  .portfolio-style-02:hover .portfolio-info-02 .popup-icon {
    opacity: 1;
    top: 50%;
    visibility: visible; }
  .portfolio-style-02 img {
    transform: scale(1);
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

/*Portfolio Style 03*/
.portfolio.portfolio-style-03 {
  position: relative; }
  .portfolio.portfolio-style-03 .portfolio-img {
    position: relative;
    border-radius: 0;
    overflow: hidden;
    transition: transform 0.28s ease;
    z-index: 2; }
    .portfolio.portfolio-style-03 .portfolio-img:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40%;
      display: block;
      z-index: 1;
      content: "";
      background: linear-gradient(to bottom, rgba(18, 17, 26, 0), rgba(18, 17, 26, 0.87) 100%);
      transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
      transition: all 0.3s ease-in-out;
      cursor: pointer; }
  .portfolio.portfolio-style-03 .portfolio-info {
    opacity: 1;
    position: absolute;
    left: 0;
    transform: inherit;
    text-align: left;
    top: inherit;
    bottom: 0px;
    padding: 20px;
    width: 100%; }
    .portfolio.portfolio-style-03 .portfolio-info .portfolio-title {
      margin-bottom: 0px; }
  .portfolio.portfolio-style-03:hover .portfolio-img:before {
    height: 100%;
    background: linear-gradient(to bottom, rgba(18, 17, 26, 0.3), rgba(18, 17, 26, 0.87) 100%); }

.filters-group {
  display: table;
  margin: 0 auto 30px;
  text-align: center; }
  .filters-group button {
    background: #d41640;
    border: none;
    z-index: 1;
    position: relative;
    font-size: 16px;
    padding: 15px 40px;
    color: #ffffff;
    border-radius: 0;
    transition: all 0.3s ease-in-out;
    margin: 0 5px; }
    .filters-group button:hover {
      background: #ffffff;
      color: #d41640;
      box-shadow: none;
      outline: none; }
    .filters-group button:focus {
      box-shadow: none;
      outline: none;
      color: #ffffff; }
    .filters-group button:active {
      box-shadow: none;
      outline: none;
      color: #ffffff; }
  .filters-group button.active {
    background-color: #ffffff;
    color: #d41640; }

.my-shuffle-container {
  margin-left: -15px;
  margin-right: -15px; }
  .my-shuffle-container .grid-item {
    width: 33.33%;
    padding: 15px; }

.my-shuffle-container .portfolio {
  margin-bottom: 0; }

/*************************************
         shuffle
 *************************************/
.my-shuffle-container.columns-1 .grid-item {
  width: 100%;
  padding: 15px; }

.my-shuffle-container.columns-2 .grid-item {
  width: 50%;
  padding: 15px;
  float: left; }

.my-shuffle-container.columns-3 .grid-item {
  width: 33.33333333%;
  padding: 15px; }

.my-shuffle-container.columns-4 .grid-item {
  width: 25%;
  padding: 10px; }

.my-shuffle-container.columns-5 .grid-item {
  width: 20%;
  padding: 15px; }

.my-shuffle-container.columns-6 .grid-item {
  width: 16.666666%;
  padding: 0px; }

.my-shuffle-container.columns-2 .grid-item-full,
.my-shuffle-container.columns-3 .grid-item-full,
.my-shuffle-container.columns-4 .grid-item-full,
.my-shuffle-container.columns-5 .grid-item-full,
.my-shuffle-container.columns-6 .grid-item-full {
  width: 100%; }

/* Responsive */
@media (max-width: 991px) {
  .filters-group button {
    margin: 0 5px 10px; }
  .filters-group button:first-child {
    margin-left: 0; }
  .filters-group button:last-child {
    margin-right: 0; } }

@media (max-width: 1199px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-5 .grid-item {
    width: 25%; } }

@media (max-width: 991px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 33.333%; } }

@media (max-width: 767px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-3 .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 50%; } }

@media (max-width: 575px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-2 .grid-item,
  .my-shuffle-container.columns-3 .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 100%; }
  .my-shuffle-container {
    margin: 0; } }

/*****************************
    Products
*****************************/
.product .product-thumbnail {
  position: relative;
  overflow: hidden; }
  .product .product-thumbnail img {
    transition: all 0.5s ease;
    transform: scale(1); }
  .product .product-thumbnail .product-action {
    position: absolute;
    bottom: -60px;
    left: 0;
    right: 0;
    text-align: center;
    transition: all 0.5s ease-in-out; }

.product .product-info {
  text-align: center;
  padding-top: 30px; }
  .product .product-info .product-name {
    margin-bottom: 10px; }
    .product .product-info .product-name a {
      color: #ffffff; }
      .product .product-info .product-name a:hover {
        color: #d41640; }
  .product .product-info .price {
    font-size: 18px;
    font-weight: 500; }
    .product .product-info .price ins {
      text-decoration: none;
      color: #d41640; }

.product:hover .product-thumbnail img {
  transform: scale(1.05); }

.product:hover .product-thumbnail .product-action {
  position: absolute;
  bottom: 10px; }

/*****************************
    Search
*****************************/
#search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #12111a;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 9999; }
  #search input[type="search"] {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 50%;
    color: #f5f5f5;
    background: #12111a;
    font-size: 36px;
    font-weight: 300;
    text-align: center;
    border: #ededed;
    border-radius: 0;
    margin: 0px auto;
    margin-top: -51px;
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    text-transform: capitalize; }
  #search .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 61px;
    margin-left: -45px; }
  #search .close {
    position: fixed;
    top: 15px;
    right: 15px;
    background-color: #ffffff;
    color: #d41640;
    opacity: 1;
    padding: 3px 17px;
    font-size: 27px;
    border: transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    #search .close:hover {
      background-color: #d41640;
      color: #ffffff; }

#search.open {
  opacity: 1;
  visibility: visible; }

/*****************************
	Select
*****************************/
.select2-container .select2-selection--single {
  height: auto;
  outline: none; }
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0px; }

.select2-container--default .select2-selection--single {
  border-radius: 0px;
  border: none; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #35343c;
    font-weight: normal;
    text-transform: capitalize; }

.select2-results__options {
  padding: 8px 2px; }

.select2-container--default .select2-results__option--highlighted {
  border-radius: 0;
  transition: all 0.2s ease-in-out; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  transition: all 0.3s ease-in-out; }

input[type="radio"]:radio {
  background-color: #d41640; }

/* Select Dropdown Arrow */
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 20px;
  right: 10px;
  height: 21px;
  width: 14px; }
  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: none; }
  .select2-container--default .select2-selection--single .select2-selection__arrow:before {
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    color: #8d8c8c; }

.select2-container--default .select2-search--dropdown {
  padding: 0; }
  .select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #ededed; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #35343c;
  transition: all 0.3s ease-in-out; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: rgba(212, 22, 64, 0.1);
  color: #d41640;
  transition: all 0.3s ease-in-out; }

.select2-dropdown {
  border: none;
  border-radius: 0px;
  z-index: 9; }

.select-border .select2-container--default .select2-selection--single {
  height: 60px;
  line-height: 20px;
  padding: 15px 20px;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1); }
  .select-border .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 20px;
    right: 20px; }

.select2-results__option[aria-selected] {
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  font-size: 14px; }

.select2-container--default .select2-results > .select2-results__options {
  border: none; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #ffffff; }

.select2-container--open .select2-dropdown {
  background: #ffffff;
  padding: 7px;
  border: 1px solid rgba(61, 209, 213, 0.1);
  box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.1); }

.select2-search--dropdown .select2-search__field {
  border: 1px solid #ededed;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04); }

.select2-container--default .select2-selection--single {
  padding: 17px 20px; }

.custom-control-input:radio {
  background-color: #d41640; }

.newsletter .search input::placeholder {
  color: #ffffff; }

.form-check .form-check-input {
  border-radius: 0;
  box-shadow: inherit; }

.form-check-input:checked {
  background-color: #d41640;
  border-color: #d41640;
  box-shadow: inherit; }

/*****************************
  sidebar
*****************************/
.sidebar .widget {
  margin-bottom: 40px;
  /*sidebar-search*/
  /*Recent Post*/
  /*sidebar-categories*/
  /*popular-tag*/
  /*social*/ }
  .sidebar .widget .widget-title {
    margin-bottom: 20px; }
  .sidebar .widget .search .search-form {
    position: relative;
    padding-right: 105px; }
    .sidebar .widget .search .search-form .form-control {
      background-color: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.1);
      color: #ffffff; }
  .sidebar .widget .search .btn {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 22px 40px;
    transition: all 0.5s ease; }
    .sidebar .widget .search .btn:hover {
      transform: inherit; }
  .sidebar .widget .search input {
    padding: 10px 20px;
    height: 60px;
    font-size: 14px;
    border: 0; }
  .sidebar .widget .recent-post {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .sidebar .widget .recent-post .post-img {
      flex: 0 0 100px;
      margin-right: 15px;
      overflow: hidden; }
      .sidebar .widget .recent-post .post-img img {
        transition: all 0.5s ease;
        transform: scale(1); }
    .sidebar .widget .recent-post .post-info .post-date {
      font-size: 14px;
      padding-bottom: 5px;
      display: block;
      color: white; }
      .sidebar .widget .recent-post .post-info .post-date i {
        margin-right: 8px; }
    .sidebar .widget .recent-post .post-info h6 {
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 0; }
    .sidebar .widget .recent-post:hover .post-img img {
      transform: scale(1.1); }
  .sidebar .widget .widget-categories ul.list-style li a {
    display: flex;
    color: #ffffff;
    width: 100%;
    line-height: 32px;
    justify-content: space-between; }
  .sidebar .widget .widget-categories .list-style-underline li a {
    padding: 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
    .sidebar .widget .widget-categories .list-style-underline li a span {
      font-size: 10px; }
    .sidebar .widget .widget-categories .list-style-underline li a:hover {
      color: #d41640; }
  .sidebar .widget .widget-categories .list-style-underline li:last-child a {
    border-bottom: inherit; }
  .sidebar .widget .popular-tag ul li {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 8px; }
    .sidebar .widget .popular-tag ul li:last-child {
      margin-bottom: 0; }
    .sidebar .widget .popular-tag ul li a {
      padding: 6px 20px;
      display: block;
      border: 1px solid rgba(255, 255, 255, 0.1);
      color: #ffffff;
      background: inherit; }
      .sidebar .widget .popular-tag ul li a:hover {
        color: #d41640;
        border: 1px solid #d41640; }
  .sidebar .widget .social ul {
    margin-bottom: 0; }
    .sidebar .widget .social ul li {
      padding: 10px 10px 10px 15px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      display: flex !important;
      margin-bottom: 10px;
      align-items: center; }
      .sidebar .widget .social ul li a {
        font-weight: 500; }
      .sidebar .widget .social ul li .follow {
        background: #35343c;
        padding: 3px 18px;
        font-size: 12px; }
      .sidebar .widget .social ul li:last-child {
        margin-bottom: 0; }
    .sidebar .widget .social ul .facebook a {
      color: #466ca9; }
    .sidebar .widget .social ul .facebook .follow:hover {
      background: #466ca9;
      color: #ffffff; }
    .sidebar .widget .social ul .twitter a {
      color: #20b5e6; }
    .sidebar .widget .social ul .twitter .follow:hover {
      background: #20b5e6;
      color: #ffffff; }
    .sidebar .widget .social ul .youtube a {
      color: #d92c20; }
    .sidebar .widget .social ul .youtube .follow:hover {
      background: #d92c20;
      color: #ffffff; }
    .sidebar .widget .social ul .instagram a {
      color: #2673f8; }
    .sidebar .widget .social ul .instagram .follow:hover {
      background: #2673f8;
      color: #ffffff; }
    .sidebar .widget .social ul .linkedIn a {
      color: #13799f; }
    .sidebar .widget .social ul .linkedIn .follow:hover {
      background: #13799f;
      color: #ffffff; }

@media (max-width: 767px) {
  .sidebar .widget .search .btn {
    padding: 23px 40px; } }

/*****************************
    Tab
*****************************/
.tab {
  position: relative; }
  .tab .nav .nav-item {
    margin: 0px 16px; }
    .tab .nav .nav-item .nav-link {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: normal;
      color: #ffffff;
      padding: 0; }
      .tab .nav .nav-item .nav-link:hover {
        color: #d41640;
        text-decoration: underline;
        background-color: inherit; }
    .tab .nav .nav-item .nav-link.active {
      color: #d41640;
      text-decoration: underline;
      background-color: inherit; }

.news-tab-nav.nav {
  margin-left: 40px; }
  .news-tab-nav.nav .nav-item {
    margin-right: 35px; }
    .news-tab-nav.nav .nav-item .nav-link {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: normal;
      color: #ffffff;
      padding: 0; }
      .news-tab-nav.nav .nav-item .nav-link:hover {
        color: #d41640;
        text-decoration: underline;
        background-color: inherit; }
    .news-tab-nav.nav .nav-item .nav-link.active {
      color: #d41640;
      text-decoration: underline;
      background-color: inherit; }

@media (max-width: 575px) {
  .news-tab-nav.nav {
    margin-left: 0px; } }

/*****************************
    Points Table
*****************************/
.table thead tr th {
  background-color: #d41640;
  padding: 16px 20px 9px;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  border-bottom: 1px solid #d41640; }

.table tbody tr th,
.table tbody tr td {
  padding: 16px 20px 12px;
  font-size: 18px;
  color: #ffffff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

.table tbody tr:last-child th,
.table tbody tr:last-child td {
  border-bottom: 0px; }

.team-name {
  display: flex;
  align-items: center; }
  .team-name img {
    flex: 0 0 25px;
    width: 25px;
    margin-right: 8px; }

.match-points-table {
  background-image: url(../images/home-01/bg-02.jpg);
  background-repeat: no-repeat; }
  .match-points-table .table thead tr th {
    background-color: #d41640;
    border-color: #d41640; }
    .match-points-table .table thead tr th:first-child {
      width: 60px; }
  .match-points-table .table tbody {
    background: rgba(18, 17, 26, 0.9); }
    .match-points-table .table tbody tr th {
      width: 60px; }

/*Points Table Style 02*/
.section-title-table {
  text-align: center;
  padding: 15px; }

.points-table-style-02 {
  background-color: rgba(53, 52, 60, 0.3); }
  .points-table-style-02 .table thead tr th {
    background: inherit;
    border-color: rgba(255, 255, 255, 0.1); }
  .points-table-style-02 .table tbody tr th,
  .points-table-style-02 .table tbody tr td {
    border-color: rgba(255, 255, 255, 0.1); }

.fixtures-table {
  background-color: rgba(53, 52, 60, 0.3); }
  .fixtures-table .fixtures-match {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 30px 0; }
    .fixtures-table .fixtures-match .fixtures-team img {
      transition: all 0.5s ease;
      transform: scale(1); }
    .fixtures-table .fixtures-match:last-child {
      border: none; }
    .fixtures-table .fixtures-match:hover .fixtures-team img {
      transform: scale(1.05); }

.player-details .table {
  background-color: rgba(53, 52, 60, 0.3); }
  .player-details .table thead tr th {
    background-color: #d41640;
    padding: 11px 15px 4px;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    border-bottom: 1px solid #d41640; }
  .player-details .table tbody tr th,
  .player-details .table tbody tr td {
    padding: 16px 20px 12px;
    font-size: 18px;
    color: #ffffff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .player-details .table tbody tr:last-child th,
  .player-details .table tbody tr:last-child td {
    border-bottom: 0px; }

.points-table-02:before {
  background-image: url(../images/home-02/pattern-01.png);
  content: "";
  position: absolute;
  height: 92px;
  width: 92px;
  left: 100px;
  top: 230px;
  z-index: -1;
  background-repeat: no-repeat; }

.points-table-02:after {
  background-image: url(../images/home-02/pattern-03.png);
  content: "";
  position: absolute;
  height: 405px;
  width: 197px;
  right: 30px;
  top: 200px;
  z-index: -1;
  background-repeat: no-repeat;
  -webkit-animation: mover 2s infinite  alternate;
  animation: mover 2s infinite  alternate; }

.points-table-02 .pattern-2 {
  position: absolute;
  left: -180px;
  bottom: 80px;
  z-index: -1;
  -webkit-animation: upmover 1.5s infinite  alternate;
  animation: upmover 1.5s infinite  alternate; }

/*Match Schedule Table*/
.match-schedule-table .table {
  background-color: rgba(255, 255, 255, 0.08); }
  .match-schedule-table .table thead tr th {
    background-color: #d41640;
    border-bottom: 1px solid #d41640; }
  .match-schedule-table .table tbody tr td {
    vertical-align: middle; }
  .match-schedule-table .table .upcoming-match {
    justify-content: flex-start; }
    .match-schedule-table .table .upcoming-match h2 {
      font-size: 18px; }
    .match-schedule-table .table .upcoming-match .match-team img {
      height: 40px; }

/*Player Career Detail Table*/
.player-career-detail-table {
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.2); }
  .player-career-detail-table .table thead tr th {
    background-color: inherit;
    padding: 10px 10px 4px;
    font-size: 18px;
    font-weight: normal;
    color: #ffffff;
    border-bottom: none; }
  .player-career-detail-table .table tbody tr th,
  .player-career-detail-table .table tbody tr td {
    padding: 10px 12px 12px;
    font-size: 16px;
    color: #ffffff;
    border-bottom: none; }
  .player-career-detail-table .table tbody tr:last-child th,
  .player-career-detail-table .table tbody tr:last-child td {
    border-bottom: 0px; }

@media (max-width: 767px) {
  .points-table-02:before {
    background-size: cover;
    height: 50px;
    width: 50px;
    left: 30px;
    top: 130px; }
  .points-table-02:after {
    background-size: cover;
    height: 145px;
    width: 70px;
    right: 30px; }
  .points-table-02 .pattern-2 {
    max-width: 35%; } }

@media (max-width: 575px) {
  .points-table-02:before, .points-table-02:after {
    content: none; }
  .points-table-02 .pattern-2 {
    display: none; } }

@media (max-width: 479px) {
  .fixtures-table .fixtures-match {
    text-align: center; }
    .fixtures-table .fixtures-match > div {
      flex-wrap: wrap;
      flex-direction: column; }
      .fixtures-table .fixtures-match > div .team-point {
        margin: 15px 0px;
        justify-content: center; }
        .fixtures-table .fixtures-match > div .team-point h6 {
          font-size: 18px; }
    .fixtures-table .fixtures-match .fixtures-team {
      justify-content: center; }
      .fixtures-table .fixtures-match .fixtures-team h6 {
        font-size: 18px; } }

/*================================*/
/*body.theme-yellow .table thead tr th {
	background-color: $primary-yellow;
}

body.theme-yellow .table thead tr th {
	color: $primary-yellow;
}*/
/*****************************
    Team
*****************************/
.classic-players {
  position: relative; }
  .classic-players .owl-carousel {
    position: inherit; }
    .classic-players .owl-carousel .item {
      overflow: hidden; }
      .classic-players .owl-carousel .item .player {
        position: relative;
        transition: all 0.3s ease-in-out; }
        .classic-players .owl-carousel .item .player img {
          transition: all 0.3s ease; }
        .classic-players .owl-carousel .item .player:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 0;
          width: 100%;
          background-color: rgba(18, 17, 26, 0.6);
          z-index: 2; }
      .classic-players .owl-carousel .item .player-info {
        position: absolute;
        bottom: -150px;
        padding: 15px 30px 15px 30px;
        transition: all 0.5s ease-in-out;
        width: 100%;
        display: flex;
        z-index: 3; }
        .classic-players .owl-carousel .item .player-info .player-number {
          flex: 0 0 55px;
          width: 55px;
          display: flex;
          align-items: center;
          background-color: #d41640;
          margin-right: 15px;
          justify-content: center;
          font-size: 30px;
          color: #ffffff; }
        .classic-players .owl-carousel .item .player-info .player-name .title {
          font-size: 24px; }
      .classic-players .owl-carousel .item:hover .player img {
        transform: scale(1.15); }
      .classic-players .owl-carousel .item:hover .player:before {
        height: 100%; }
      .classic-players .owl-carousel .item:hover .player-info {
        bottom: 15px; }
    .classic-players .owl-carousel .owl-nav {
      left: 0px;
      position: absolute;
      bottom: 100px; }
      .classic-players .owl-carousel .owl-nav button {
        height: 70px;
        width: 70px;
        font-size: 26px; }
        .classic-players .owl-carousel .owl-nav button.owl-next {
          left: 90px; }
  .classic-players:before {
    background-image: url(../images/home-01/pattern-08.png);
    content: "";
    position: absolute;
    position: absolute;
    height: 321px;
    width: 172px;
    left: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover; }

.team {
  position: relative;
  overflow: hidden; }
  .team .player {
    position: relative;
    transition: all 0.3s ease-in-out; }
    .team .player img {
      transition: all 0.3s ease; }
    .team .player:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 0;
      width: 100%;
      background-color: rgba(18, 17, 26, 0.6);
      z-index: 2; }
  .team .player-info {
    position: absolute;
    bottom: -150px;
    padding: 15px 30px 15px 30px;
    transition: all 0.3s ease-in-out;
    width: 100%;
    display: flex;
    z-index: 3; }
    .team .player-info .player-number {
      flex: 0 0 55px;
      width: 55px;
      display: flex;
      align-items: center;
      background-color: #d41640;
      margin-right: 15px;
      justify-content: center;
      font-size: 30px;
      color: #ffffff; }
    .team .player-info .player-name .title {
      font-size: 24px; }
  .team:hover .player img {
    transform: scale(1.1); }
  .team:hover .player:before {
    height: 100%; }
  .team:hover .player-info {
    bottom: 15px; }

/*Team Single*/
.player-single .player-info {
  padding: 25px 30px;
  align-self: center;
  margin: 0 20px;
  z-index: 2;
  position: relative; }
  .player-single .player-info .player-position {
    font-size: 20px;
    font-weight: bold;
    color: #d41640; }
  .player-single .player-info ul li {
    color: #ffffff; }
    .player-single .player-info ul li span {
      font-size: 18px;
      font-weight: 600;
      width: 140px; }

/*Team Info*/
.team-info .team .player-info {
  width: 90%;
  left: 5%;
  bottom: 20px;
  background-color: #ffffff;
  padding: 15px;
  padding-left: 55px; }
  .team-info .team .player-info .player-num {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 100%;
    background-color: #d41640;
    display: flex;
    align-items: center;
    justify-content: center; }
  .team-info .team .player-info .player-name {
    font-size: 20px;
    color: #d41640; }
  .team-info .team .player-info span {
    color: #12111a; }

.player-info .player-position {
  font-size: 20px;
  font-weight: bold;
  color: #d41640; }

.player-info ul li {
  color: #ffffff; }
  .player-info ul li span {
    font-size: 18px;
    font-weight: 600;
    width: 140px; }

.team-sticky.is-sticky {
  top: 100px; }

/*Players Details*/
.players-details {
  position: relative;
  z-index: 2; }
  .players-details .text-area {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    overflow: hidden;
    width: 310px; }
    .players-details .text-area .baseball-text {
      position: absolute;
      font-size: 210px;
      line-height: 190px;
      font-weight: bold;
      text-align: right;
      color: rgba(18, 17, 26, 0.4);
      text-transform: uppercase;
      left: -290px;
      top: -310px;
      transform: rotate(-90deg); }
    .players-details .text-area .baseball-text.text-01 {
      left: -210px;
      top: 160px; }
    .players-details .text-area .baseball-text.text-02 {
      left: -294px;
      top: 260px; }
  .players-details .player-2 {
    position: absolute;
    bottom: 0;
    left: -120px;
    z-index: -1; }
  .players-details .player-detail .player-name {
    font-size: 72px;
    font-weight: bold;
    line-height: 70px;
    color: #ffffff;
    margin-bottom: 25px;
    display: block; }
  .players-details .player-detail .player-profile {
    display: flex;
    align-items: center; }
    .players-details .player-detail .player-profile .profile span {
      display: block;
      color: #ffffff;
      font-size: 18px;
      margin-bottom: 15px; }
      .players-details .player-detail .player-profile .profile span label {
        padding-right: 20px;
        margin-right: 20px;
        position: relative;
        width: 110px; }
        .players-details .player-detail .player-profile .profile span label:before {
          position: absolute;
          content: "";
          top: 50%;
          right: 0;
          height: 2px;
          width: 4px;
          background-color: #ffffff; }
    .players-details .player-detail .player-profile .rank {
      font-size: 142px;
      font-weight: bold;
      color: rgba(212, 22, 64, 0.1);
      padding-left: 80px; }
  .players-details .base-ball {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: -1; }
  .players-details .player-career-detail-table {
    padding-bottom: 80px; }

.single-player {
  position: relative; }
  .single-player::before {
    background-image: url(../images/home-01/pattern-05.png);
    content: "";
    position: absolute;
    height: 105px;
    width: 120px;
    right: 300px;
    bottom: 130px;
    background-repeat: no-repeat;
    backface-visibility: hidden;
    animation: jumping 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all .9s ease 5s;
    user-select: none; }
  .single-player .single-player-bg {
    position: absolute;
    right: 0;
    top: 50px; }

@media (max-width: 1499px) {
  .classic-players:before {
    width: 100px;
    height: 190px; } }

@media (max-width: 991px) {
  .classic-players .owl-carousel .owl-nav {
    display: none; }
  .players-details .player-2 {
    position: inherit;
    bottom: 0;
    left: inherit;
    z-index: -1;
    margin-bottom: 50px; }
  .players-details .base-ball {
    display: none; }
  .players-details .player-career-detail-table {
    padding-bottom: 50px; } }

@media (max-width: 767px) {
  .team-single .team-info {
    margin: 0 15px;
    margin-top: -50px; }
  .players-details .text-area {
    display: none; }
  .players-details .player-detail .player-name {
    font-size: 40px;
    line-height: 40px; }
  .players-details .player-detail .player-profile .rank {
    font-size: 80px;
    padding-left: 50px; }
  .players-details .player-career-detail-table {
    padding-bottom: 20px; }
  .single-player:before {
    right: 0; } }

@media (max-width: 575px) {
  .players-details .player-2 {
    max-width: 100%; }
  .players-details .player-detail .player-name {
    font-size: 40px;
    line-height: 40px; }
  .players-details .player-detail .player-profile {
    display: block; }
    .players-details .player-detail .player-profile .rank {
      padding-left: 0px;
      margin-top: 20px; } }

@media (max-width: 479px) {
  .players-details .player-detail .player-name {
    font-size: 24px;
    line-height: 24px; } }

/*****************************
    Testimonial
*****************************/
.testimonial {
  position: relative; }
  .testimonial .testimonial-star-icon {
    margin-bottom: 60px; }
    .testimonial .testimonial-star-icon ul {
      display: flex;
      padding-left: 0;
      margin-bottom: 0px; }
      .testimonial .testimonial-star-icon ul li {
        list-style-type: none;
        margin-right: 10px;
        font-size: 30px; }
        .testimonial .testimonial-star-icon ul li i {
          color: #d41640; }
  .testimonial .testimonial-content {
    margin-bottom: 50px;
    padding-right: 40px; }
    .testimonial .testimonial-content p {
      font-size: 22px;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical; }
  .testimonial .testimonial-author {
    margin-top: 30px; }
    .testimonial .testimonial-author .testimonial-name .author-tittle {
      font-size: 26px;
      font-weight: 500;
      color: #d41640; }
    .testimonial .testimonial-author .testimonial-name span {
      position: relative;
      font-size: 18px;
      color: #ffffff;
      padding-left: 20px; }
      .testimonial .testimonial-author .testimonial-name span:before {
        content: "";
        height: 2px;
        width: 4px;
        background: #ffffff;
        position: absolute;
        left: 0;
        top: 50%; }

/*testimonial style-02*/
.testimonial-style-02 {
  padding: 50px 40px; }
  .testimonial-style-02 .testimonial .testimonial-star-icon {
    margin-bottom: 40px; }
  .testimonial-style-02 .testimonial .testimonial-content {
    padding-right: 0;
    margin-bottom: 40px; }

@media (max-width: 991px) {
  .testimonial .testimonial-star-icon {
    margin-bottom: 30px; }
    .testimonial .testimonial-star-icon ul li {
      font-size: 20px; }
  .testimonial .testimonial-content {
    padding-right: 0px;
    margin-bottom: 30px; }
  .testimonial .testimonial-author .testimonial-name .author-tittle {
    font-size: 18px; }
  .testimonial .testimonial-author .testimonial-name span {
    font-size: 16px; } }

@media (max-width: 575px) {
  .testimonial .testimonial-star-icon {
    margin-bottom: 20px; }
    .testimonial .testimonial-star-icon ul li {
      font-size: 18px; }
  .testimonial .testimonial-content {
    padding-right: 0px;
    margin-bottom: 30px; }
    .testimonial .testimonial-content p {
      font-size: 18px;
      line-height: 24px; }
  .testimonial .testimonial-author .testimonial-name .author-tittle {
    font-size: 18px; }
  .testimonial .testimonial-author .testimonial-name span {
    font-size: 16px; } }

/*****************************
  Header Default
*****************************/
.header {
  padding: 15px 40px; }
  .header .navbar .navbar-brand img {
    height: 30px; }
  .header .navbar .navbar-brand .logo-blue,
  .header .navbar .navbar-brand .logo-yellow {
    display: none; }
  .header .navbar .navbar-brand .sticky-logo {
    display: none; }
  .header .navbar .navbar-collapse.collapse {
    margin-left: auto;
    flex-grow: inherit; }
  .header .navbar .navbar-nav {
    position: relative; }
    .header .navbar .navbar-nav .nav-item {
      margin-right: 30px; }
      .header .navbar .navbar-nav .nav-item:last-child {
        margin-right: 0; }
      .header .navbar .navbar-nav .nav-item .nav-link {
        color: #ffffff;
        font-family: "Josefin Sans", sans-serif;
        font-size: 16px;
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;
        padding: 30px 5px;
        font-weight: 500;
        text-transform: uppercase; }
        .header .navbar .navbar-nav .nav-item .nav-link i {
          margin-left: 8px;
          display: flex;
          align-items: baseline; }
        .header .navbar .navbar-nav .nav-item .nav-link:hover {
          color: #d41640; }
    .header .navbar .navbar-nav li.active > .nav-link {
      color: #d41640; }
  .header .navbar .add-listing {
    display: flex;
    align-items: center; }
    .header .navbar .add-listing .need-help {
      display: flex;
      align-items: center;
      padding-right: 50px;
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      height: 50px; }
      .header .navbar .add-listing .need-help .icon {
        width: 50px;
        background: #d41640;
        height: 50px;
        border-radius: 100%;
        padding: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px; }
        .header .navbar .add-listing .need-help .icon img {
          width: 24px; }
      .header .navbar .add-listing .need-help .help-info p {
        margin-bottom: 5px;
        color: #ffffff; }
      .header .navbar .add-listing .need-help .help-info span {
        color: #ffffff;
        font-size: 18px; }
    .header .navbar .add-listing .search-box {
      width: fit-content;
      height: fit-content;
      position: relative;
      display: flex;
      align-items: center; }
    .header .navbar .add-listing .input-search {
      height: 50px;
      width: 50px;
      border-style: none;
      padding: 10px;
      font-size: 16px;
      letter-spacing: 2px;
      outline: none;
      border-radius: 25px;
      transition: all .5s ease-in-out;
      background-color: inherit;
      padding-right: 40px;
      color: #ffffff; }
      .header .navbar .add-listing .input-search::placeholder {
        color: rgba(255, 255, 255, 0.5);
        font-size: 18px;
        letter-spacing: 2px;
        font-weight: 100; }
    .header .navbar .add-listing .btn-search {
      border-style: none;
      font-size: 16px;
      font-weight: bold;
      outline: none;
      cursor: pointer;
      border-radius: 50%;
      position: absolute;
      right: 5px;
      color: #ffffff;
      background-color: transparent;
      pointer-events: painted; }
      .header .navbar .add-listing .btn-search:focus ~ .input-search {
        width: 300px;
        border-radius: 0px;
        background-color: transparent;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2); }
    .header .navbar .add-listing .input-search:focus {
      width: 300px;
      border-radius: 0px;
      background-color: transparent;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2); }
    .header .navbar .add-listing .woo-action {
      padding: 10px 40px;
      border-right: 1px solid rgba(255, 255, 255, 0.3); }
      .header .navbar .add-listing .woo-action ul {
        margin: 0; }
        .header .navbar .add-listing .woo-action ul li {
          display: inline-block;
          margin-right: 20px; }
          .header .navbar .add-listing .woo-action ul li:last-child {
            margin-right: 0px; }
          .header .navbar .add-listing .woo-action ul li a {
            color: #ffffff;
            font-size: 12px;
            position: relative; }
            .header .navbar .add-listing .woo-action ul li a:hover {
              color: #d41640; }
          .header .navbar .add-listing .woo-action ul li .cart-count {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -15px;
            right: -8px;
            width: 16px;
            height: 16px;
            font-size: 10px;
            color: #ffffff;
            background: #d41640;
            border-radius: 50%; }
          .header .navbar .add-listing .woo-action ul li .cart-icon {
            font-size: 20px;
            color: #ffffff; }
        .header .navbar .add-listing .woo-action ul li.cart .dropdown-toggle {
          background: transparent;
          border: none;
          color: #ffffff;
          transition: all 0.3s ease-in-out;
          width: 33px;
          margin-top: 5px; }
          .header .navbar .add-listing .woo-action ul li.cart .dropdown-toggle object {
            width: 23px;
            filter: invert(10%) sepia(8%) saturate(5323%) hue-rotate(164deg) brightness(98%) contrast(92%);
            cursor: pointer;
            transition: all 0.3s ease-in-out; }
          .header .navbar .add-listing .woo-action ul li.cart .dropdown-toggle:hover {
            color: #d41640; }
            .header .navbar .add-listing .woo-action ul li.cart .dropdown-toggle:hover object {
              filter: invert(84%) sepia(25%) saturate(1301%) hue-rotate(134deg) brightness(90%) contrast(84%);
              transition: all 0.3s ease-in-out; }
          .header .navbar .add-listing .woo-action ul li.cart .dropdown-toggle:after {
            border-top: none; }
        .header .navbar .add-listing .woo-action ul li.cart:hover .dropdown-menu-right {
          transform: translate3d(0%, 0, 0);
          visibility: visible;
          opacity: 1; }
    .header .navbar .add-listing .side-menu {
      padding: 10px 0px 10px 40px; }
      .header .navbar .add-listing .side-menu img {
        width: 24px; }
      .header .navbar .add-listing .side-menu .menu-dark {
        display: none; }

header.header.header-sticky.is-sticky {
  position: fixed;
  background: #ffffff;
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 1px 30px 0px rgba(255, 255, 255, 0.09);
  top: -160px;
  z-index: 11;
  width: 100%;
  padding: 0 40px; }
  header.header.header-sticky.is-sticky.sticky-show {
    top: 0; }
  header.header.header-sticky.is-sticky .navbar .navbar-nav .nav-item .nav-link {
    color: #12111a;
    padding: 20px 5px; }
  header.header.header-sticky.is-sticky .navbar .navbar-nav .nav-item.active .nav-link {
    color: #d41640; }
  header.header.header-sticky.is-sticky .navbar .navbar-brand {
    border-right: 1px solid rgba(255, 255, 255, 0.3); }
    header.header.header-sticky.is-sticky .navbar .navbar-brand .logo {
      display: none; }
    header.header.header-sticky.is-sticky .navbar .navbar-brand img {
      height: 25px; }
    header.header.header-sticky.is-sticky .navbar .navbar-brand .sticky-logo {
      display: flex; }
    header.header.header-sticky.is-sticky .navbar .navbar-brand .sticky-logo-blue,
    header.header.header-sticky.is-sticky .navbar .navbar-brand .sticky-logo-yellow {
      display: none; }
  header.header.header-sticky.is-sticky .navbar .add-listing .need-help {
    border-right: 1px solid rgba(255, 255, 255, 0.3); }
    header.header.header-sticky.is-sticky .navbar .add-listing .need-help .help-info p {
      color: #ffffff; }
    header.header.header-sticky.is-sticky .navbar .add-listing .need-help .help-info span {
      color: #ffffff; }
  header.header.header-sticky.is-sticky .navbar .add-listing .woo-action {
    border-right: 1px solid rgba(255, 255, 255, 0.3); }
    header.header.header-sticky.is-sticky .navbar .add-listing .woo-action ul li a .cart-icon {
      color: #ffffff; }
  header.header.header-sticky.is-sticky .navbar .add-listing .side-menu img {
    display: none; }
  header.header.header-sticky.is-sticky .navbar .add-listing .side-menu .menu-dark {
    display: flex; }

.header.header-sticky.is-sticky .navbar .navbar-toggler {
  border: inherit;
  color: #000000; }

/*Header Style 02*/
.header.header-style-02 {
  position: absolute;
  z-index: 11;
  width: 100%;
  padding: 0; }
  .header.header-style-02 .topbar {
    padding: 15px 0; }
    .header.header-style-02 .topbar .topbar-left p {
      color: #ffffff;
      margin-bottom: 0; }
    .header.header-style-02 .topbar .topbar-left .social-media li {
      margin: 0 10px; }
      .header.header-style-02 .topbar .topbar-left .social-media li a {
        color: #ffffff; }
        .header.header-style-02 .topbar .topbar-left .social-media li a:hover {
          color: #d41640; }
    .header.header-style-02 .topbar .topbar-right .topbar-menu ul li {
      margin-right: 15px; }
      .header.header-style-02 .topbar .topbar-right .topbar-menu ul li a {
        color: #ffffff; }
        .header.header-style-02 .topbar .topbar-right .topbar-menu ul li a:hover {
          color: #d41640; }
    .header.header-style-02 .topbar .topbar-right .login a {
      color: #ffffff; }
      .header.header-style-02 .topbar .topbar-right .login a:hover {
        color: #d41640; }
  .header.header-style-02 .navbar {
    padding: 0; }
    .header.header-style-02 .navbar .main-header {
      justify-content: center;
      padding-top: 20px;
      border-top: 1px solid rgba(255, 255, 255, 0.3); }
      .header.header-style-02 .navbar .main-header .navbar-collapse.collapse {
        margin-left: initial; }
    .header.header-style-02 .navbar .navbar-nav {
      align-items: center; }
      .header.header-style-02 .navbar .navbar-nav .nav-item {
        margin-right: 30px; }
      .header.header-style-02 .navbar .navbar-nav .navbar-brand {
        border: none;
        position: relative;
        display: block;
        margin: 0 20px; }

.header.header-style-02.header-sticky.is-sticky {
  padding: 0; }
  .header.header-style-02.header-sticky.is-sticky .topbar {
    padding: 15px 0px; }
    .header.header-style-02.header-sticky.is-sticky .topbar .topbar-left p {
      color: #12111a; }
    .header.header-style-02.header-sticky.is-sticky .topbar .topbar-left .social-media li a {
      color: #12111a; }
      .header.header-style-02.header-sticky.is-sticky .topbar .topbar-left .social-media li a:hover {
        color: #d41640; }
    .header.header-style-02.header-sticky.is-sticky .topbar .topbar-right .topbar-menu ul li a {
      color: #12111a; }
      .header.header-style-02.header-sticky.is-sticky .topbar .topbar-right .topbar-menu ul li a:hover {
        color: #d41640; }
    .header.header-style-02.header-sticky.is-sticky .topbar .topbar-right .login a {
      color: #12111a; }
      .header.header-style-02.header-sticky.is-sticky .topbar .topbar-right .login a:hover {
        color: #d41640; }
  .header.header-style-02.header-sticky.is-sticky .navbar .main-header {
    border-top: 1px solid rgba(18, 17, 26, 0.1);
    padding-top: 15px; }
    .header.header-style-02.header-sticky.is-sticky .navbar .main-header .navbar-nav .nav-item .nav-link {
      padding: 20px 5px; }

/*Header Style 03*/
.header.header-style-03 {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 9; }
  .header.header-style-03 .navbar .navbar-collapse.collapse {
    margin-left: 20px;
    margin-right: auto; }
  .header.header-style-03 .navbar .navbar-nav li.active > .nav-link {
    color: #d41640; }
  .header.header-style-03 .navbar .navbar-nav .nav-item .nav-link {
    font-size: 18px; }
    .header.header-style-03 .navbar .navbar-nav .nav-item .nav-link:hover {
      color: #d41640; }
  .header.header-style-03 .navbar .dropdown > .dropdown-menu li.active a {
    color: #d41640; }
  .header.header-style-03 .navbar .add-listing .side-menu {
    padding-left: 30px; }
  .header.header-style-03 .navbar .add-listing .header-search .search a {
    font-size: 20px;
    color: #ffffff; }
    .header.header-style-03 .navbar .add-listing .header-search .search a:hover {
      color: #d41640; }

.header.header-style-03.header-sticky.is-sticky .navbar {
  padding-top: 0px;
  padding-bottom: 0px; }
  .header.header-style-03.header-sticky.is-sticky .navbar .navbar-brand {
    border-right: 0; }
  .header.header-style-03.header-sticky.is-sticky .navbar .navbar-nav .nav-item .nav-link {
    color: #12111a; }
    .header.header-style-03.header-sticky.is-sticky .navbar .navbar-nav .nav-item .nav-link:hover {
      color: #d41640; }
  .header.header-style-03.header-sticky.is-sticky .navbar .navbar-nav li.active > .nav-link {
    color: #d41640; }
  .header.header-style-03.header-sticky.is-sticky .navbar .add-listing .header-search .search a {
    color: #12111a; }
    .header.header-style-03.header-sticky.is-sticky .navbar .add-listing .header-search .search a:hover {
      color: #d41640; }

.offcanvas.offcanvas-end.offcanvas-sidebar-menu {
  right: 0;
  left: inherit;
  transform: translateX(100%);
  backdrop-filter: blur(13px) !important;
  -webkit-backdrop-filter: blur(13px) !important;
  -moz-backdrop-filter: blur(13px) !important;
  -ms-backdrop-filter: blur(13px) !important;
  -o-backdrop-filter: blur(13px) !important;
  background: rgba(18, 17, 26, 0.8) !important;
  width: 500px; }
  .offcanvas.offcanvas-end.offcanvas-sidebar-menu .offcanvas-header .btn-close {
    background: none;
    opacity: 1; }
    .offcanvas.offcanvas-end.offcanvas-sidebar-menu .offcanvas-header .btn-close i {
      font-size: 28px;
      color: #ffffff; }
    .offcanvas.offcanvas-end.offcanvas-sidebar-menu .offcanvas-header .btn-close:hover i {
      color: #d41640; }
    .offcanvas.offcanvas-end.offcanvas-sidebar-menu .offcanvas-header .btn-close:focus {
      box-shadow: none; }
  .offcanvas.offcanvas-end.offcanvas-sidebar-menu .sidebar-menu .sidebar-logo a img {
    height: 30px; }
  .offcanvas.offcanvas-end.offcanvas-sidebar-menu .sidebar-menu .sidebar-logo a .logo-yellow,
  .offcanvas.offcanvas-end.offcanvas-sidebar-menu .sidebar-menu .sidebar-logo a .logo-blue {
    display: none; }

.offcanvas.offcanvas-sidebar-menu.show {
  transform: none; }

.header .navbar .dropdown > .dropdown-menu li.active a {
  color: #d41640; }

@media (max-width: 1500px) {
  .header .navbar .navbar-brand {
    padding-right: 30px;
    margin-right: 20px; }
  .header .navbar .navbar-nav .nav-item {
    margin-right: 15px; }
  .header.header.header-style-02 .navbar .navbar-brand {
    padding-right: 0px; } }

@media (max-width: 1366px) {
  .header .navbar .navbar-nav .nav-item {
    margin-right: 8px; } }

@media (max-width: 1300px) {
  .header {
    padding: 15px 20px; }
  header.header.header-sticky.is-sticky {
    padding: 0px 20px; }
  .header.header-style-03.header-sticky.is-sticky .navbar {
    padding-bottom: 8px;
    padding-top: 8px; } }

@media (min-width: 1200px) {
  .header .navbar .dropdown-menu {
    margin: 0px;
    min-width: 200px;
    font-size: 16px;
    border-radius: 0;
    padding: 20px;
    border-color: transparent;
    left: 0;
    transform: translate3d(-10px, 15px, 0);
    transition: all 0.3s ease-in-out;
    transition-property: opacity, visibility, transform;
    transform-origin: top center;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    display: block !important; }
    .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu {
      left: 100%;
      right: auto;
      transform: translate3d(0, 10px, 0); }
    .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu.left-side {
      right: 100%;
      left: auto;
      transform: translate3d(-15px, 10px, 0); }
  .header .navbar .dropdown-menu.megamenu {
    left: 0;
    width: 100%; }
    .header .navbar .dropdown-menu.megamenu .nav-title {
      font-weight: 700;
      font-size: 18px; }
  .header .navbar .dropdown > .dropdown-menu {
    top: 100%;
    margin-top: 0px; }
  .header .navbar .dropdown > .dropdown-menu.megamenu {
    top: 100%; }
  .header .navbar .dropdown:hover > .dropdown-menu {
    transform: translate3d(-10px, 0px, 0);
    visibility: visible;
    opacity: 1; }
  .navbar-nav .dropdown-menu.dropdown-menu-lg {
    min-width: 630px; }
  .navbar-nav .dropdown-menu.dropdown-menu-md {
    min-width: 450px; }
  .navbar-nav .mega-menu {
    position: static; }
  .navbar-nav li:hover > ul.dropdown-menu {
    visibility: visible;
    opacity: 1; }
  .dropdown-submenu {
    position: relative;
    padding: 7px 0 7px 0;
    display: block;
    color: #12111a;
    font-weight: 400; }
    .dropdown-submenu > .dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -6px; }
  .dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg); }
  .dropdown-menu .dropdown-item {
    padding: 7px 0 7px 0;
    background: none;
    color: #12111a; }
    .dropdown-menu .dropdown-item:hover {
      color: #d41640; }
    .dropdown-menu .dropdown-item:focus {
      background-color: transparent; }
  /*Header Style 03*/
  .header.header-style-03 .dropdown-menu .dropdown-item:hover {
    color: #d41640; } }

@media (max-width: 1199px) {
  .header .navbar-collapse {
    position: absolute;
    top: 100%;
    z-index: 999;
    background: #12111a;
    width: 100%;
    max-height: 350px;
    overflow: auto;
    left: 0;
    margin-top: -1px;
    margin-left: 0px;
    border-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .header .navbar .navbar-brand .logo {
    height: 30px; }
  .header .navbar .navbar-nav {
    align-items: initial; }
    .header .navbar .navbar-nav .nav-item {
      margin-right: 0; }
      .header .navbar .navbar-nav .nav-item .nav-link {
        color: #ffffff;
        padding: 10px 20px;
        font-size: 16px; }
        .header .navbar .navbar-nav .nav-item .nav-link i {
          position: absolute;
          right: 20px; }
    .header .navbar .navbar-nav .dropdown.mega-menu .megamenu {
      width: 95%;
      margin: 0 auto; }
  .header .navbar .dropdown-menu {
    margin: 0px 15px;
    font-size: 16px;
    border-radius: 0px;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #35343c;
    padding: 15px; }
    .header .navbar .dropdown-menu .dropdown-item {
      padding: 10px 0px;
      color: #ffffff; }
      .header .navbar .dropdown-menu .dropdown-item:hover {
        background-color: inherit;
        color: #d41640; }
      .header .navbar .dropdown-menu .dropdown-item:active, .header .navbar .dropdown-menu .dropdown-item:focus {
        background-color: inherit; }
    .header .navbar .dropdown-menu .dropdown-item.active {
      background-color: inherit;
      color: #d41640; }
  .header .navbar .nav-title {
    margin-top: 20px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700; }
  .header .navbar .navbar-toggler {
    position: absolute;
    right: 60px;
    box-shadow: none;
    transition: 0.5s ease-in-out;
    background: none;
    color: #ffffff;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .header .navbar .add-listing {
    padding-right: 0px; }
    .header .navbar .add-listing .menu-btn {
      display: none; }
  header.header.header-sticky.is-sticky .navbar .navbar-brand .sticky-logo {
    height: 25px; }
  header.header.header-sticky.is-sticky .navbar .navbar-nav .nav-item .nav-link {
    padding: 10px 20px;
    color: #ffffff; }
    header.header.header-sticky.is-sticky .navbar .navbar-nav .nav-item .nav-link:hover {
      color: #d41640; }
  /*Header Style 02*/
  .header.header-style-02 .topbar {
    display: none !important; }
  .header.header-style-02 .navbar .navbar-nav {
    align-items: initial; }
    .header.header-style-02 .navbar .navbar-nav .nav-item {
      margin-right: 0px; }
    .header.header-style-02 .navbar .navbar-nav .dropdown-menu .dropdown-item {
      font-size: 16px;
      color: #ffffff; }
      .header.header-style-02 .navbar .navbar-nav .dropdown-menu .dropdown-item:hover, .header.header-style-02 .navbar .navbar-nav .dropdown-menu .dropdown-item:focus {
        background-color: inherit; }
    .header.header-style-02 .navbar .navbar-nav .dropdown-menu li.active .dropdown-item {
      color: #d41640; }
    .header.header-style-02 .navbar .navbar-nav .navbar-brand {
      display: none; }
  .header.header-style-02 .navbar .main-header {
    padding: 0 28px;
    padding-top: 25px;
    padding-bottom: 25px;
    justify-content: flex-start; }
    .header.header-style-02 .navbar .main-header .navbar-toggler {
      top: 35px;
      right: 28px; }
    .header.header-style-02 .navbar .main-header img {
      height: 30px; }
  .header.header-style-02.header-sticky.is-sticky .navbar .main-header {
    padding-top: 25px; }
    .header.header-style-02.header-sticky.is-sticky .navbar .main-header .navbar-nav .nav-item .nav-link {
      padding: 10px 20px; }
  /*Header Style 03*/
  .header.header-style-03 .navbar .navbar-toggler {
    right: 110px; }
  .header.header-style-03 .navbar .navbar-nav .nav-item .nav-link {
    font-size: 16px; }
  .header.header-style-03 .navbar .navbar-collapse.collapse {
    margin-left: 0; }
  .header.header-style-03.header-sticky.is-sticky .navbar .navbar-nav .nav-item .nav-link {
    color: #ffffff; }
  .dropdown-submenu {
    color: #ffffff;
    padding: 10px 0px;
    display: block; } }

@media (max-width: 991px) {
  .header .navbar .navbar-brand {
    padding-right: 20px; }
  /*Header Style 03*/
  .header.header-style-03 {
    position: inherit;
    padding: 15px 10px; } }

@media (max-width: 767px) {
  .header .navbar .add-listing .need-help {
    display: none; }
  .header .navbar .add-listing .search-box {
    display: none; }
  .header.header-style-02 .topbar {
    padding: 15px 0;
    text-align: center;
    justify-content: center !important;
    display: flex;
    flex-wrap: wrap; }
    .header.header-style-02 .topbar .topbar-right {
      margin-top: 10px; } }

@media (max-width: 575px) {
  .header {
    padding: 0px 10px; }
  header.header.header-sticky.is-sticky {
    padding: 0px 10px; }
  .header.header-style-02 .add-listing .search-product {
    display: none; }
  .header.header-style-03 .navbar .navbar-toggler {
    right: 100px; }
  .header.header-style-03 .navbar .add-listing .side-menu {
    padding: 10px 0px 10px 20px; }
  .header.header-style-03 .navbar .add-listing .btn {
    display: none; } }

@media (max-width: 479px) {
  .header {
    padding: 0px 10px; }
    .header .navbar .navbar-brand {
      flex: 0 0 120px; }
  .header.header-style-02 .topbar .topbar-left {
    display: none !important; } }

/*****************************
    Banner
*****************************/
.banner {
  margin: 0 50px; }
  .banner .banner-sub-title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 20px;
    display: flex; }
  .banner .slider-social {
    display: flex;
    position: absolute;
    z-index: 2;
    bottom: 50px;
    left: 150px;
    text-align: center; }
    .banner .slider-social .slider-social-info ul {
      display: flex;
      align-items: center; }
      .banner .slider-social .slider-social-info ul li a {
        color: #ffffff;
        padding: 0px 10px;
        font-size: 20px; }
        .banner .slider-social .slider-social-info ul li a:hover {
          color: #d41640; }
  .banner .swiper-slide {
    height: 750px;
    padding: 0px 150px;
    opacity: 0 !important; }
    .banner .swiper-slide.swiper-slide-active {
      opacity: 1 !important; }
    .banner .swiper-slide .title {
      font-size: 134px;
      line-height: 1; }
    .banner .swiper-slide p {
      font-size: 20px;
      line-height: 28px;
      margin: 20px 0 20px;
      justify-content: space-between; }
    .banner .swiper-slide h1 {
      text-transform: uppercase; }
    .banner .swiper-slide .pattern-01 {
      position: absolute;
      left: -50px;
      bottom: 150px; }
    .banner .swiper-slide .pattern-02 {
      position: absolute;
      left: 65%;
      bottom: 0px; }
    .banner .swiper-slide .pattern-03 {
      position: absolute;
      right: 50px;
      top: 50px; }
    .banner .swiper-slide .btn.btn-white:hover {
      background: #000000;
      color: #ffffff; }
  .banner .pagination-button .swiper-button-prev {
    background-image: none;
    display: flex;
    color: #ffffff;
    align-items: center;
    top: auto;
    right: 200px;
    bottom: 50px;
    left: auto;
    font-size: 20px;
    transition: all 0.3s ease-in-out; }
    .banner .pagination-button .swiper-button-prev:hover {
      color: #d41640; }
    .banner .pagination-button .swiper-button-prev:after {
      content: "";
      background: #ffffff;
      width: 110px;
      height: 2px;
      left: 60px;
      position: absolute; }
  .banner .pagination-button .swiper-button-next {
    background-image: none;
    display: flex;
    color: #ffffff;
    align-items: center;
    bottom: 50px;
    top: auto;
    left: auto;
    right: 10px;
    transition: all 0.3s ease-in-out;
    font-size: 20px; }
    .banner .pagination-button .swiper-button-next:hover {
      color: #d41640; }
  .banner .swiper-container {
    overflow: inherit; }

/*Banner 01*/
.banner.banner-01 .slider-social .slider-social-info ul li a:hover {
  color: #000000; }

.banner.banner-01 .pagination-button .swiper-button-prev:hover {
  color: #000000; }

.banner.banner-01 .pagination-button .swiper-button-next:hover {
  color: #000000; }

.banner.banner-01 .slide-02 .pattern-04 {
  position: absolute;
  right: 200px;
  top: 50%; }

/*Banner 02*/
.banner.banner-02 {
  margin: 0; }
  .banner.banner-02 .swiper-slide {
    height: 950px;
    padding-top: 150px; }
    .banner.banner-02 .swiper-slide h1 {
      font-weight: bold; }
    .banner.banner-02 .swiper-slide .btn {
      font-size: 32px; }
      .banner.banner-02 .swiper-slide .btn:hover {
        background-color: #d41640;
        color: #ffffff; }
    .banner.banner-02 .swiper-slide .banner-img {
      position: absolute;
      bottom: 70px;
      right: 250px; }
  .banner.banner-02 .slide-02 .banner-img {
    position: absolute;
    bottom: -90px;
    right: inherit;
    left: 0; }

/*Banner 03*/
.banner.banner-03 {
  margin: 0px; }
  .banner.banner-03 .swiper-slide {
    height: 940px;
    padding: 0px;
    padding-top: 130px; }
    .banner.banner-03 .swiper-slide h1 span {
      font-size: 230px;
      color: rgba(212, 22, 64, 0.4);
      font-weight: bold; }
    .banner.banner-03 .swiper-slide .banner-img {
      position: relative;
      background-color: #d41640;
      margin-left: 140px;
      height: 600px;
      width: 100%; }
      .banner.banner-03 .swiper-slide .banner-img .btn {
        position: absolute;
        left: -140px;
        bottom: 65px; }
        .banner.banner-03 .swiper-slide .banner-img .btn:hover {
          background-color: #12111a; }
      .banner.banner-03 .swiper-slide .banner-img img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%); }
    .banner.banner-03 .swiper-slide .btn.btn-white {
      font-size: 24px;
      color: #d41640;
      padding: 24px 60px;
      line-height: 32px;
      text-transform: uppercase; }
  .banner.banner-03 .swiper-slide.slide-02 .banner-img img {
    left: 51%; }
  .banner.banner-03 .pagination-button {
    position: absolute;
    left: 200px; }
    .banner.banner-03 .pagination-button .swiper-button-next:hover,
    .banner.banner-03 .pagination-button .swiper-button-prev:hover {
      color: #d41640; }

@media (max-width: 1800px) {
  .banner .swiper-slide {
    padding: 0px 50px; }
    .banner .swiper-slide h1 {
      font-size: 80px; }
    .banner .swiper-slide .pattern-01 {
      left: -120px; }
  .banner .slider-social {
    left: 50px; }
  .banner.banner-02 .swiper-slide .banner-img {
    right: 130px; } }

@media (max-width: 1399px) {
  .banner.banner-02 .swiper-slide h1 {
    font-size: 70px; }
  /*Banner 03*/
  .banner.banner-03 {
    margin: 0px; }
    .banner.banner-03 .swiper-slide {
      height: 850px; }
      .banner.banner-03 .swiper-slide h1 span {
        font-size: 200px; } }

@media (max-width: 1300px) {
  .banner .swiper-slide {
    height: 500px;
    padding-bottom: 50px; }
    .banner .swiper-slide h1 {
      font-size: 47px; }
    .banner .swiper-slide p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0; }
    .banner .swiper-slide .pattern-01 {
      left: -60px;
      bottom: 30px; }
      .banner .swiper-slide .pattern-01 img {
        width: 100px; }
    .banner .swiper-slide .pattern-02 {
      left: 75%; }
      .banner .swiper-slide .pattern-02 img {
        width: 60px; }
    .banner .swiper-slide .pattern-03 {
      right: 30px;
      top: 20px; }
      .banner .swiper-slide .pattern-03 img {
        width: 30px; }
  .banner.banner-02 .swiper-slide {
    height: 750px; }
    .banner.banner-02 .swiper-slide h1 {
      font-size: 47px; }
    .banner.banner-02 .swiper-slide .banner-img {
      right: 50px; }
      .banner.banner-02 .swiper-slide .banner-img img {
        width: 400px; } }

@media (max-width: 1199px) {
  .banner.banner-01 .pagination-button .swiper-button-prev {
    right: 250px; }
  .banner.banner-01 .pagination-button .swiper-button-next {
    right: 62px; }
  /*Banner 03*/
  .banner.banner-03 {
    margin: 0px; }
    .banner.banner-03 .swiper-slide h1 span {
      font-size: 150px; }
    .banner.banner-03 .swiper-slide .banner-img {
      margin-left: 60px; } }

@media (max-width: 991px) {
  .banner .swiper-slide {
    height: 450px;
    padding-bottom: 80px; }
  .banner.banner-01 .pagination-button .swiper-button-next {
    right: 50px; }
  /*Banner 03*/
  .banner.banner-03 {
    margin: 0px; }
    .banner.banner-03 .swiper-slide {
      height: 450px;
      padding-top: 0px; }
      .banner.banner-03 .swiper-slide h1 span {
        font-size: 80px; }
      .banner.banner-03 .swiper-slide .btn.btn-white {
        font-size: 16px;
        padding: 15px 40px;
        line-height: 26px; }
    .banner.banner-03 .pagination-button {
      left: inherit;
      right: 0; }
  .banner.banner-02 .swiper-slide {
    padding-top: 250px;
    height: 550px; }
    .banner.banner-02 .swiper-slide .btn {
      font-size: 20px; } }

@media (max-width: 767px) {
  .banner {
    margin: 0 20px; }
    .banner .swiper-slide {
      height: 350px; }
      .banner .swiper-slide h1 {
        font-size: 32px; }
      .banner .swiper-slide p {
        font-size: 14px;
        margin-top: 10px;
        line-height: 20px; }
      .banner .swiper-slide .pattern-01 img {
        width: 75px; }
      .banner .swiper-slide .pattern-02 {
        bottom: 0; }
      .banner .swiper-slide .pattern-03 {
        right: 15px;
        top: 15px; }
    .banner .slider-social {
      bottom: 30px; }
      .banner .slider-social .slider-social-info ul li a {
        font-size: 16px; }
    .banner .pagination-button .swiper-button-prev,
    .banner .pagination-button .swiper-button-next {
      bottom: 20px; } }

@media (max-width: 575px) {
  .banner .swiper-slide {
    padding: 0;
    padding-bottom: 50px; }
  .banner .pagination-button {
    display: none; }
    .banner .pagination-button .swiper-button-prev {
      right: 150px;
      font-size: 16px; }
      .banner .pagination-button .swiper-button-prev:after {
        width: 60px;
        left: 40px; }
    .banner .pagination-button .swiper-button-next {
      right: 40px;
      font-size: 16px; }
  .banner .slider-social {
    display: none; }
  .banner.banner-01 .pagination-button .swiper-button-prev {
    right: 140px; }
  .banner.banner-01 .pagination-button .swiper-button-next {
    right: 30px; }
  .banner.banner-02 .swiper-slide {
    height: 450px;
    padding-bottom: 130px; }
    .banner.banner-02 .swiper-slide h1 {
      font-size: 36px; }
    .banner.banner-02 .swiper-slide .btn {
      font-size: 16px; } }

/*****************************
  Layout
*****************************/
/*Tournament Countdown*/
.tournament-countdown {
  padding: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

/*Back To Top*/
.back-to-top a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 50px;
  right: 40px;
  width: 50px;
  height: 50px;
  margin: 0px;
  color: #ffffff;
  font-size: 18px;
  background: #d41640;
  transition: all 0.3s ease-in-out;
  z-index: 999; }

/*Prevent text selection*/
.qty {
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-right: 10px;
  align-items: center;
  display: flex;
  padding: 13px 10px;
  border-radius: 0;
  width: 170px;
  justify-content: center;
  justify-content: space-between; }
  .qty .count {
    color: #ffffff;
    display: inline-block;
    vertical-align: top;
    font-size: 18px;
    line-height: 10px;
    padding: 0 2px;
    min-width: 35px;
    text-align: center;
    background-color: inherit; }
  .qty .plus {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    font-size: 30px;
    line-height: 30px;
    color: rgba(255, 255, 255, 0.2);
    min-width: 30px;
    text-align: center; }
  .qty input {
    color: #ffffff;
    border-color: inherit;
    text-align: center;
    width: auto; }
  .qty .minus {
    cursor: pointer;
    display: inline-block;
    font-size: 30px;
    line-height: 30px;
    min-width: 30px;
    color: rgba(255, 255, 255, 0.2);
    text-align: center; }
  .qty input {
    border: 0;
    width: 2%; }
    .qty input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .qty input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .qty input:disabled {
      background-color: white; }

/*Social Icon*/
.social-icon ul {
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0; }
  .social-icon ul li {
    display: inline-flex;
    list-style-type: none;
    float: left; }
    .social-icon ul li:last-child a {
      margin-right: 0; }
    .social-icon ul li a {
      display: flex;
      font-size: 18px;
      color: #f5f5f5;
      margin-right: 25px;
      transition: all 0.5s ease; }
      .social-icon ul li a:hover {
        color: #d41640; }

/*Live Videos*/
.video-section {
  position: relative; }
  .video-section .video-box {
    overflow: hidden; }
    .video-section .video-box .video-image img {
      transform: scale(1);
      transition: all 0.5s ease; }
    .video-section .video-box:hover .video-image img {
      transform: scale(1.1); }
  .video-section .video-style-vertical {
    overflow: auto;
    height: 550px;
    padding-right: 20px; }
    .video-section .video-style-vertical .video-post {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 30px; }
      .video-section .video-style-vertical .video-post .video-post-date {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 13%;
        flex: 0 0 13%; }
      .video-section .video-style-vertical .video-post .video-image {
        min-width: 160px;
        width: 160px;
        margin-right: 30px;
        position: relative;
        overflow: hidden;
        cursor: pointer; }
        .video-section .video-style-vertical .video-post .video-image img {
          transform: scale(1);
          transition: transform .3s ease; }
        .video-section .video-style-vertical .video-post .video-image:hover img {
          transform: scale(1.1); }
      .video-section .video-style-vertical .video-post .video-post-details .badge {
        background: #d41640;
        border-radius: 0;
        text-transform: uppercase;
        margin-bottom: 15px; }
      .video-section .video-style-vertical .video-post .video-post-details .video-view a {
        color: #ffffff; }
        .video-section .video-style-vertical .video-post .video-post-details .video-view a:hover {
          color: #d41640; }
        .video-section .video-style-vertical .video-post .video-post-details .video-view a:last-child {
          padding-left: 35px;
          position: relative; }
          .video-section .video-style-vertical .video-post .video-post-details .video-view a:last-child:before {
            content: "";
            position: absolute;
            background: #ffffff;
            height: 4px;
            width: 4px;
            border-radius: 50%;
            left: 15px;
            top: 6px; }
      .video-section .video-style-vertical .video-post:last-child {
        margin-bottom: 0; }
    .video-section .video-style-vertical::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .video-section .video-style-vertical::-webkit-scrollbar {
      width: 3px;
      background-color: #f5f5f5; }
    .video-section .video-style-vertical::-webkit-scrollbar-thumb {
      background-color: #d41640; }
  .video-section .video-image {
    position: relative; }
    .video-section .video-image .video-btn.popup-youtube {
      position: absolute;
      top: 40%;
      left: 45%;
      font-size: 80px;
      color: #ffffff; }
      .video-section .video-image .video-btn.popup-youtube:hover {
        color: #d41640; }
    .video-section .video-image .video-icon a {
      position: absolute;
      bottom: 5px;
      right: 5px;
      background: #ffffff;
      display: flex;
      padding: 7px;
      justify-content: center;
      align-items: center; }
  .video-section:before {
    background-image: url(../images/home-01/pattern-06.png);
    content: "";
    position: absolute;
    height: 70px;
    width: 70px;
    left: 110px;
    top: 200px;
    background-repeat: no-repeat;
    backface-visibility: hidden;
    animation: jumping 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all .9s ease 5s;
    user-select: none; }
  .video-section:after {
    background-image: url(../images/home-01/pattern-07.png);
    content: "";
    position: absolute;
    height: 70px;
    width: 70px;
    right: 130px;
    bottom: 250px;
    background-repeat: no-repeat;
    -webkit-animation: upmover 1.5s infinite  alternate;
    animation: upmover 1.5s infinite  alternate; }

/*Video Style 02*/
.video-style-02 {
  padding: 0px 150px; }
  .video-style-02 .video-bg {
    background-image: url(../images/home-03/video-bg.jpg);
    background-repeat: no-repeat;
    padding: 100px 0px;
    background-size: cover;
    background-position: center; }
  .video-style-02 .match-box .match-team {
    display: flex;
    align-items: center; }
    .video-style-02 .match-box .match-team span {
      color: #ffffff;
      font-weight: bold; }
    .video-style-02 .match-box .match-team .team-vs {
      padding: 0px 15px; }
  .video-style-02 .match-box .match-time {
    margin-top: 10px;
    margin-bottom: 20px; }
    .video-style-02 .match-box .match-time span {
      color: #ffffff;
      font-size: 20px; }
  .video-style-02 .match-box p {
    color: #ffffff;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0; }
  .video-style-02 .match-video {
    padding-top: 100px; }
    .video-style-02 .match-video .play-btn {
      position: relative;
      padding-left: 0px;
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      color: #ffffff;
      display: flex;
      align-items: center; }
      .video-style-02 .match-video .play-btn i {
        color: #d41640;
        font-size: 30px;
        transition: all 0.3s ease-in-out;
        z-index: 3;
        padding-right: 15px; }

/*Video Style 03*/
.video-style-03 {
  padding-left: 40px;
  position: relative; }
  .video-style-03 .play-btn {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex: 0 0 80px;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: #d41640;
    color: #ffffff;
    transition: all 0.3s ease-in-out;
    z-index: 9; }
    .video-style-03 .play-btn i {
      font-size: 30px; }
    .video-style-03 .play-btn:hover {
      background-color: #ffffff;
      color: #d41640; }

.about {
  position: relative; }
  .about:before {
    background-image: url(../images/home-01/pattern-03.png);
    content: "";
    position: absolute;
    height: 158px;
    width: 69px;
    left: 130px;
    top: 130px;
    background-repeat: no-repeat;
    -webkit-animation: upmover 1.5s infinite  alternate;
    animation: upmover 1.5s infinite  alternate; }
  .about:after {
    background-image: url(../images/home-01/pattern-05.png);
    content: "";
    position: absolute;
    height: 102px;
    width: 120px;
    right: 180px;
    top: 100px;
    background-repeat: no-repeat;
    background-size: cover; }
  .about .about-img {
    margin-right: 110px; }
  .about .about-bg img {
    position: absolute;
    right: 0;
    bottom: 0; }

/*Latest Results*/
.latest-results {
  background-image: url(../images/home-01/bg-01.jpg);
  padding-bottom: 280px;
  background-repeat: no-repeat; }

.time-location {
  color: #ffffff; }
  .time-location .time {
    margin-right: 20px; }
  .time-location .time i,
  .time-location .location i {
    color: #d41640;
    margin-right: 8px; }

.latest-results-02 {
  background-image: url(../images/home-01/bg-03.jpg);
  padding-bottom: 280px;
  background-repeat: no-repeat; }

/*Upcoming Match*/
.upcoming-match {
  display: flex;
  align-items: center;
  justify-content: center; }
  .upcoming-match .match-date-time {
    padding: 0 30px; }
    .upcoming-match .match-date-time span {
      display: block; }
    .upcoming-match .match-date-time .match-time {
      font-size: 32px;
      line-height: 32px;
      color: #ffffff; }
    .upcoming-match .match-date-time .match-date {
      font-size: 14px;
      line-height: 24px;
      color: #ffffff; }
      .upcoming-match .match-date-time .match-date i {
        color: #d41640;
        padding-right: 8px; }

/*Performers*/
.top-performers {
  position: relative;
  z-index: 2; }
  .top-performers .icon-1 {
    left: 185px;
    top: 150px;
    z-index: -1; }
  .top-performers .icon-2 {
    left: 210px;
    bottom: 125px;
    z-index: -1;
    backface-visibility: hidden;
    animation: jumping3 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 7s ease 2s;
    user-select: none; }
  .top-performers .icon-3 {
    right: 340px;
    top: 150px;
    z-index: -1;
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 2s infinite  alternate; }
  .top-performers .icon-4 {
    right: 125px;
    bottom: 155px;
    z-index: -1; }

.performers-box {
  display: flex;
  align-items: center;
  position: relative; }
  .performers-box .performers-info {
    flex: 0 0 55%;
    position: relative;
    z-index: 9; }
    .performers-box .performers-info .player-name {
      font-size: 42px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 16px; }
    .performers-box .performers-info .player-rank {
      font-size: 42px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 5px; }
    .performers-box .performers-info .player-position,
    .performers-box .performers-info .player-height,
    .performers-box .performers-info .player-weight {
      font-size: 16px;
      color: #ffffff;
      font-weight: normal;
      margin-bottom: 8px; }
      .performers-box .performers-info .player-position span,
      .performers-box .performers-info .player-height span,
      .performers-box .performers-info .player-weight span {
        font-size: 22px;
        font-weight: bold;
        color: #d41640;
        margin-right: 5px; }
  .performers-box .performers-img {
    position: relative;
    z-index: 2; }
    .performers-box .performers-img img {
      position: relative;
      left: -90px; }
  .performers-box .performers-img.player-02 img {
    position: relative;
    left: inherit;
    right: -20px; }

.testimonial-video-section .icon-1 {
  top: 150px;
  left: 65px;
  z-index: -1;
  -webkit-animation: upmover 1.5s infinite  alternate;
  animation: upmover 1.5s infinite  alternate; }

.testimonial-video-section .icon-2 {
  left: 135px;
  bottom: 65px;
  z-index: -1; }

.testimonial-video-section .icon-3 {
  left: 41%;
  bottom: 185px;
  z-index: -1;
  backface-visibility: hidden;
  animation: jumping3 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all 7s ease 2s;
  user-select: none; }

.testimonial-video-section .icon-4 {
  top: 160px;
  right: 80px;
  z-index: -1; }

.testimonial-video-section .icon-5 {
  right: 50px;
  bottom: 20px;
  z-index: -1;
  -webkit-animation: mover 2s infinite  alternate;
  animation: mover 2s infinite  alternate; }

.images-gallery .portfolio.portfolio-style-02 .portfolio-images {
  position: relative;
  z-index: 3; }
  .images-gallery .portfolio.portfolio-style-02 .portfolio-images:before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 0;
    top: 0;
    background-color: rgba(212, 22, 64, 0.5);
    transition: all 0.3s ease-in-out;
    z-index: 1; }

.images-gallery .portfolio.portfolio-style-02 .portfolio-info-02 .popup-icon {
  z-index: 5; }

.images-gallery .portfolio.portfolio-style-02:hover .portfolio-images:before {
  height: 100%; }

/*Client Logo*/
.client-logo .owl-carousel .owl-stage {
  display: flex;
  align-items: center; }
  .client-logo .owl-carousel .owl-stage .owl-item {
    display: flex;
    align-items: center;
    justify-content: center; }
    .client-logo .owl-carousel .owl-stage .owl-item img {
      width: auto; }

/* Home 02 */
.game-countdown {
  background-image: url(../images/home-02/bg-01.jpg);
  padding-top: 240px;
  padding-bottom: 240px;
  background-repeat: no-repeat; }
  .game-countdown .section-title h2 {
    font-size: 42px;
    font-weight: bold; }

.featured-news {
  padding-bottom: 250px; }

.players-details {
  background-color: rgba(53, 52, 60, 0.3); }

.products {
  position: relative;
  z-index: 2; }
  .products:before {
    background-image: url(../images/home-02/pattern-05.png);
    content: "";
    position: absolute;
    height: 70px;
    width: 70px;
    left: 35%;
    top: 55px;
    z-index: -1;
    background-repeat: no-repeat;
    backface-visibility: hidden;
    animation: jumping10 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 8s ease 4s;
    user-select: none; }
  .products:after {
    background-image: url(../images/home-02/pattern-01.png);
    content: "";
    position: absolute;
    height: 93px;
    width: 93px;
    right: 90px;
    bottom: 160px;
    z-index: -1;
    background-repeat: no-repeat;
    backface-visibility: hidden;
    animation: jumping11 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 8s ease 4s;
    user-select: none; }
  .products .section-title {
    margin-bottom: 35px; }
  .products .pattern-7 {
    position: absolute;
    bottom: 62px;
    left: -163px;
    z-index: -1; }

.video-section-02 {
  background-image: url(../images/home-02/bg-02.png);
  background-repeat: no-repeat;
  padding-top: 170px;
  padding-bottom: 170px; }
  .video-section-02 .video-style-04 {
    text-align: center; }
    .video-section-02 .video-style-04 .play-btn {
      margin: 0 auto;
      margin-bottom: 40px;
      display: flex;
      flex: 0 0 90px;
      align-items: center;
      justify-content: center;
      height: 90px;
      width: 90px;
      border-radius: 50%;
      background-color: #ffffff;
      color: #d41640;
      transition: all 0.3s ease-in-out;
      z-index: 99; }
      .video-section-02 .video-style-04 .play-btn i {
        font-size: 30px; }
      .video-section-02 .video-style-04 .play-btn:hover {
        background-color: #d41640;
        color: #ffffff; }

/*Home 03*/
.match-schedule {
  position: relative; }
  .match-schedule .pattern-1 {
    top: 30px;
    left: 0;
    -webkit-animation: upmover 1.5s infinite  alternate;
    animation: upmover 1.5s infinite  alternate; }
  .match-schedule .pattern-2 {
    top: 30px;
    right: 0;
    -webkit-animation: upmover 1.5s infinite  alternate;
    animation: upmover 1.5s infinite  alternate; }

.pattern-5 {
  bottom: 20px; }

.latest-news {
  position: relative;
  z-index: 2; }
  .latest-news .section-title .title {
    margin-bottom: 0; }

.latest-news-tab {
  position: relative;
  z-index: 2; }
  .latest-news-tab .icon-1 {
    bottom: 370px;
    left: 165px;
    z-index: -1; }
  .latest-news-tab .icon-2 {
    bottom: 115px;
    left: 80px;
    z-index: -1;
    -webkit-animation: upmover 1.5s infinite  alternate;
    animation: upmover 1.5s infinite  alternate; }
  .latest-news-tab .icon-3 {
    top: 285px;
    right: 190px;
    z-index: -1;
    backface-visibility: hidden;
    animation: jumping10 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 8s ease 4s;
    user-select: none; }
  .latest-news-tab .icon-4 {
    bottom: 193px;
    right: 95px;
    z-index: -1;
    backface-visibility: hidden;
    animation: jumping3 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 7s ease 2s;
    user-select: none; }
  .latest-news-tab .points-table .table {
    background-color: rgba(53, 52, 60, 0.3); }

/*inner-page*/
.header.inner-page-header {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99; }

.inner-banner {
  height: 500px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 45px; }
  .inner-banner .inner-banner-tittle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; }
    .inner-banner .inner-banner-tittle h2 {
      color: #ffffff;
      font-size: 60px;
      line-height: 70px;
      position: relative;
      margin-bottom: 15px; }
    .inner-banner .inner-banner-tittle .breadcrumb {
      margin-bottom: 15px; }
      .inner-banner .inner-banner-tittle .breadcrumb .breadcrumb-item {
        color: #ffffff;
        text-transform: capitalize; }
        .inner-banner .inner-banner-tittle .breadcrumb .breadcrumb-item:before {
          color: #ffffff; }
        .inner-banner .inner-banner-tittle .breadcrumb .breadcrumb-item a {
          color: #ffffff; }
          .inner-banner .inner-banner-tittle .breadcrumb .breadcrumb-item a:hover {
            color: #d41640; }
      .inner-banner .inner-banner-tittle .breadcrumb .breadcrumb-item.active {
        color: #d41640; }

/*Progress Bar*/
.progress {
  position: relative;
  overflow: visible;
  height: .5rem; }
  .progress .progress-bar {
    border-radius: 6px;
    background-color: #d41640; }

/*Newsletter*/
.newsletter form {
  position: relative;
  padding-right: 193px; }
  .newsletter form button.btn {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 18px;
    padding-bottom: 18px; }
    .newsletter form button.btn:hover {
      transform: translateY(0);
      background-color: #12111a;
      color: #ffffff; }

.player-about {
  background-image: url(../images/player/bg-01.png);
  background-repeat: no-repeat;
  padding: 100px 0px;
  background-size: cover;
  background-position: center; }

/* Pagination */
.pagination {
  display: flex;
  justify-content: center; }
  .pagination .page-item {
    margin: 0 6px;
    display: flex; }
    .pagination .page-item .page-link {
      color: #d41640;
      padding: 10px 18px;
      border-radius: 0;
      font-weight: 500;
      border: none;
      background: #f5f5f5;
      border-color: #f5f5f5; }
      .pagination .page-item .page-link:hover {
        background: #d41640;
        border-color: #d41640;
        color: #ffffff; }
      .pagination .page-item .page-link:focus {
        background: #d41640;
        border-color: #d41640;
        color: #ffffff;
        box-shadow: none; }
  .pagination .page-item.active .page-link {
    background: #d41640;
    border-color: #d41640;
    color: #ffffff; }

.message-info {
  padding: 40px 30px; }
  .message-info .section-title {
    margin-bottom: 50px; }
    .message-info .section-title p {
      margin-top: 20px; }

.contact-us-form {
  padding-left: 60px; }
  .contact-us-form .section-title {
    margin-bottom: 50px; }
    .contact-us-form .section-title p {
      margin-top: 20px;
      margin-bottom: 0; }
  .contact-us-form form .form-control {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff; }
    .contact-us-form form .form-control::placeholder {
      color: #ffffff; }
  .contact-us-form form .select2-container--default .select2-selection--single {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 15px 20px; }
    .contact-us-form form .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: #ffffff; }

/*Coming Soon*/
.coming-soon-section {
  height: 100vh; }
  .coming-soon-section .coming-soon-logo img {
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
    height: 50px; }
  .coming-soon-section .coming-soon-logo .logo-blue,
  .coming-soon-section .coming-soon-logo .logo-yellow {
    display: none; }

/*404*/
.contant-404 h1 {
  font-size: 250px;
  color: #ffffff; }
  .contant-404 h1 span {
    color: #d41640;
    padding: 0px 25px; }

/*Ticket*/
.ticket-box {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding-right: 30px; }
  .ticket-box .match-img {
    width: 200px; }
  .ticket-box .ticket-details {
    padding-left: 30px;
    display: flex;
    align-items: center;
    width: calc(100% - 150px);
    justify-content: space-between; }
    .ticket-box .ticket-details .ticket-info {
      padding-right: 30px; }
      .ticket-box .ticket-details .ticket-info .ticket-description {
        font-size: 30px;
        line-height: 38px;
        color: #ffffff;
        margin-bottom: 10px; }
      .ticket-box .ticket-details .ticket-info .ticket-price {
        font-size: 24px;
        color: #d41640; }
    .ticket-box .ticket-details .match-details {
      padding-right: 30px;
      text-align: center; }
      .ticket-box .ticket-details .match-details .team {
        font-size: 20px;
        color: #ffffff;
        display: block; }
      .ticket-box .ticket-details .match-details .match-vs {
        padding: 15px 0px;
        font-size: 24px;
        color: #d41640; }

/*Match Schedule Table*/
.upcoming-match-table {
  background-image: url(../images/home-01/bg-02.jpg);
  background-repeat: no-repeat;
  background-position: bottom; }
  .upcoming-match-table .match-schedule {
    position: relative;
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    width: 100%;
    padding: 40px 40px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    transition: all 0.3s ease-in-out; }
    .upcoming-match-table .match-schedule:before {
      content: "";
      width: 0;
      height: 3px;
      position: absolute;
      left: auto;
      right: 0;
      bottom: 0px;
      background-color: #d41640;
      -webkit-transition: width 500ms ease 0s, left 500ms ease 0s;
      -moz-transition: width 500ms ease 0s, left 500ms ease 0s;
      transition: width 500ms ease 0s, left 500ms ease 0s; }
    .upcoming-match-table .match-schedule > div {
      padding-left: 30px;
      position: relative;
      width: 216px;
      flex: 0 0 auto; }
      .upcoming-match-table .match-schedule > div label.title {
        margin-bottom: 10px;
        font-size: 16px;
        color: #8d8c8c;
        width: 100%; }
      .upcoming-match-table .match-schedule > div:before {
        position: absolute;
        content: "";
        width: 1px;
        height: 40px;
        background-color: rgba(255, 255, 255, 0.3);
        left: 0px;
        top: 50%;
        transform: translateY(-50%); }
    .upcoming-match-table .match-schedule .upcoming-match {
      padding-left: 0px;
      display: block;
      text-align: center; }
      .upcoming-match-table .match-schedule .upcoming-match h2 {
        font-size: 24px; }
      .upcoming-match-table .match-schedule .upcoming-match .match-team img {
        height: 70px; }
      .upcoming-match-table .match-schedule .upcoming-match:before {
        content: inherit; }
    .upcoming-match-table .match-schedule .match-date span {
      font-size: 18px;
      color: #ffffff;
      display: block; }
    .upcoming-match-table .match-schedule .match-date .time {
      font-size: 18px; }
    .upcoming-match-table .match-schedule .tournament {
      font-size: 18px;
      color: #ffffff; }
    .upcoming-match-table .match-schedule .venue {
      font-size: 18px;
      color: #ffffff; }
    .upcoming-match-table .match-schedule .article {
      padding-right: 0px; }
    .upcoming-match-table .match-schedule:hover {
      background-color: black; }
      .upcoming-match-table .match-schedule:hover:before {
        width: 100%;
        left: 0;
        right: auto; }

/*Keyframes*/
img.vert-move {
  -webkit-animation: upmover 1.5s infinite  alternate;
  animation: upmover 1.5s infinite  alternate; }

img.hori-move {
  -webkit-animation: mover 2s infinite  alternate;
  animation: mover 2s infinite  alternate; }

@-webkit-keyframes upmover {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-10px); } }

@keyframes mover {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-20px); } }

.custom-animation {
  backface-visibility: hidden;
  animation: jumping 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all .9s ease 5s;
  user-select: none; }

.custom-animation2 {
  backface-visibility: hidden;
  animation: jumping2 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all 1s ease 3s;
  user-select: none; }

.custom-animation3 {
  backface-visibility: hidden;
  animation: jumping3 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all 7s ease 2s;
  user-select: none; }

.custom-animation4 {
  backface-visibility: hidden;
  animation: jumping4 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all 8s ease 4s;
  user-select: none; }

.custom-animation10 {
  backface-visibility: hidden;
  animation: jumping10 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all 8s ease 4s;
  user-select: none; }

.custom-animation11 {
  backface-visibility: hidden;
  animation: jumping11 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all 8s ease 4s;
  user-select: none; }

@keyframes jumping {
  0% {
    transform: translateY(0px) translateX(0) rotate(0) scale(1);
    opacity: .8; }
  25% {
    transform: translateY(-10px) translateX(-10px) rotate(20deg) scale(0.8);
    opacity: .9; }
  50% {
    transform: translateY(-15px) translateX(-15px) rotate(10deg) scale(0.9);
    opacity: .8; }
  75% {
    transform: translateY(-20px) translateX(-20px) rotate(20deg) scale(0.75);
    opacity: .6; }
  85% {
    transform: translateY(-25px) translateX(-25px) rotate(20deg) scale(0.9);
    opacity: .7; }
  100% {
    transform: translateY(-15px) translateX(-15px) rotate(0) scale(0.95);
    opacity: .85; } }

@keyframes jumping2 {
  0% {
    transform: translateY(0px) translateX(0) rotate(0) scale(1);
    opacity: .5; }
  25% {
    transform: translateY(-30px) translateX(10px) rotate(20deg) scale(0.8);
    opacity: .8; }
  50% {
    transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.7);
    opacity: .8; }
  75% {
    transform: translateY(30px) translateX(20px) rotate(20deg) scale(0.75);
    opacity: .7; }
  100% {
    transform: translateY(-15px) translateX(15px) rotate(0) scale(0.75);
    opacity: .9; } }

@keyframes jumping3 {
  0% {
    transform: translateY(10px) translateX(0) rotate(0) scale(1);
    opacity: .9; }
  20% {
    transform: translateY(20px) translateX(10px) rotate(-20deg) scale(0.8);
    opacity: .8; }
  40% {
    transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.75);
    opacity: .8; }
  40% {
    transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(0.5);
    opacity: 1; }
  80% {
    transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(0.75);
    opacity: .6; }
  100% {
    transform: translateY(15px) translateX(15px) rotate(0) scale(0.95);
    opacity: .7; } }

@keyframes jumping4 {
  0% {
    transform: translateY(-30px) translateX(20px) rotate(0) scale(1.2);
    opacity: .7; }
  25% {
    transform: translateY(-20px) translateX(10px) rotate(50deg) scale(0.6);
    opacity: .8; }
  50% {
    transform: translateY(15px) translateX(-15px) rotate(20deg) scale(0.5);
    opacity: .9; }
  75% {
    transform: translateY(30px) translateX(20px) rotate(50deg) scale(0.75);
    opacity: .7; }
  100% {
    transform: translateY(-15px) translateX(15px) rotate(0) scale(0.5);
    opacity: .9; } }

@keyframes jumping10 {
  0% {
    transform: rotate(180deg);
    display: block; }
  100% {
    transform: rotate(30deg);
    display: block; } }

@keyframes jumping11 {
  0% {
    transform: rotate(180deg) translate(-20px, 20px);
    display: block; }
  100% {
    transform: rotate(30deg) translate(0px, 0px);
    display: block; } }

@media (max-width: 1699px) {
  .video-style-02 {
    padding: 0px 100px; }
  .about:before {
    display: none; }
  .about:after {
    height: 61px;
    width: 70px;
    right: 90px;
    top: 60px; }
  .video-section:before {
    display: none; }
  .video-section::after {
    display: none; } }

@media (max-width: 1499px) {
  .video-style-02 {
    padding: 0px 30px; }
  .about-bg img {
    width: 150px; }
  .tournament-countdown .tournament-title h2 {
    font-size: 36px; }
  .tournament-countdown .upcoming-match .match-team {
    width: 100px; } }

@media (max-width: 1199px) {
  .video-style-02 {
    padding: 0px 30px; }
    .video-style-02 .video-bg {
      padding: 80px 0px; }
    .video-style-02 .match-video {
      padding-top: 80px; }
  .tournament-countdown .tournament-title h2 {
    font-size: 48px; }
  .upcoming-match-table .match-schedule > div {
    padding: 0px 15px; }
  .upcoming-match-table .match-schedule .upcoming-match .match-team img {
    height: 60px; }
  .upcoming-match-table .match-schedule .match-date span {
    font-size: 18px; }
  .upcoming-match-table .match-schedule .match-date .time {
    font-size: 16px; }
  .upcoming-match-table .match-schedule .tournament {
    font-size: 18px; }
  .upcoming-match-table .match-schedule .venue {
    font-size: 18px; }
  .upcoming-match-table .match-schedule .article .btn {
    padding: 10px 25px; }
  .about .about-img {
    margin-right: 20px; } }

@media (max-width: 991px) {
  .inner-banner {
    height: 400px; }
    .inner-banner .inner-banner-tittle h2 {
      font-size: 40px;
      line-height: 50px; }
  .video-style-02 {
    padding: 0px 0px; }
    .video-style-02 .video-bg {
      padding: 60px 0px; }
    .video-style-02 .match-video {
      padding-top: 60px; }
  .about:after {
    display: none; }
  .about .about-img {
    margin-right: 0px; }
  /*Video Style 03*/
  .video-style-03 {
    padding-left: 30px; }
    .video-style-03 .play-btn {
      flex: 0 0 60px;
      height: 60px;
      width: 60px; }
      .video-style-03 .play-btn i {
        font-size: 24px; }
  .about-bg img {
    display: none; }
  .game-countdown {
    padding-top: 60px;
    padding-bottom: 60px; }
  .featured-news {
    padding-bottom: 60px; }
  .video-section-02 {
    padding-top: 100px;
    padding-bottom: 100px; }
  .message-info .section-title {
    margin-bottom: 40px; }
  .contact-us-form {
    padding-left: 0px;
    margin-top: 30px; }
    .contact-us-form .section-title {
      margin-bottom: 40px; }
  /*Ticket*/
  .ticket-box {
    padding-right: 0;
    display: block;
    padding-bottom: 30px; }
    .ticket-box .match-img {
      width: 100%;
      margin-bottom: 30px; }
    .ticket-box .ticket-details {
      padding-right: 20px;
      padding-left: 20px;
      justify-content: space-between;
      width: 100%; }
  .coming-soon-section {
    height: auto; }
  .upcoming-match-table .match-schedule {
    flex-wrap: wrap; }
    .upcoming-match-table .match-schedule > div {
      padding: 0px 15px;
      width: 50%;
      text-align: center;
      margin-bottom: 20px; }
      .upcoming-match-table .match-schedule > div:before {
        left: 0; }
    .upcoming-match-table .match-schedule .upcoming-match {
      width: 100%; }
    .upcoming-match-table .match-schedule .match-date:before {
      content: inherit; }
    .upcoming-match-table .match-schedule .venue:before {
      content: inherit; }
  .latest-results.latest-results-about {
    padding-bottom: 80px; }
  .player-about {
    padding: 80px 0px; }
  .upcoming-match .match-date-time .match-time {
    font-size: 18px; }
  .upcoming-match .match-date-time {
    padding: 0 20px; } }

@media (max-width: 767px) {
  .tournament-countdown {
    padding: 40px 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
  .video-section .video-style-vertical {
    margin-top: 40px; }
    .video-section .video-style-vertical .video-post {
      flex-direction: column;
      align-items: start; }
      .video-section .video-style-vertical .video-post .video-image {
        width: 100%; }
      .video-section .video-style-vertical .video-post .video-post-details {
        margin-top: 20px; }
  .video-style-02 .video-bg {
    padding: 50px 0px; }
  .video-style-02 .match-box .match-team {
    display: block; }
    .video-style-02 .match-box .match-team span {
      display: block; }
    .video-style-02 .match-box .match-team .team-vs {
      padding: 0; }
  .video-style-02 .match-video {
    padding-top: 50px; }
  /*performers*/
  .performers-box .performers-info .player-name {
    font-size: 30px; }
  .performers-box .performers-info .player-rank {
    font-size: 30px; }
  .performers-box .performers-info .player-position span,
  .performers-box .performers-info .player-height span,
  .performers-box .performers-info .player-weight span {
    font-size: 18px; }
  .game-countdown {
    padding-top: 50px;
    padding-bottom: 50px; }
  .featured-news {
    padding-bottom: 50px; }
  .video-section-02 {
    padding-top: 80px;
    padding-bottom: 80px; }
  .products:after, .products:before {
    height: 50px;
    width: 50px;
    background-size: cover; }
  .products:after {
    right: 30px; }
  .products .pattern-7 {
    max-width: 35%; }
  .inner-banner {
    height: 300px; }
    .inner-banner .inner-banner-tittle h2 {
      font-size: 40px;
      line-height: 50px; }
  /*newsletter*/
  .newsletter form {
    padding-right: 130px; }
    .newsletter form button.btn.btn-primary {
      padding-top: 20px;
      padding-bottom: 19px; }
  .latest-results.latest-results-about {
    padding-bottom: 40px; }
  .player-about {
    padding: 50px 0px; }
  /*Ticket*/
  .ticket-box {
    padding-bottom: 20px; }
    .ticket-box .match-img {
      width: 100%;
      margin-bottom: 25px; }
    .ticket-box .ticket-details {
      padding: 0px 20px;
      display: block; }
      .ticket-box .ticket-details .ticket-info {
        padding-right: 0;
        margin-bottom: 25px; }
      .ticket-box .ticket-details .upcoming-match {
        justify-content: flex-start;
        margin-bottom: 25px; }
      .ticket-box .ticket-details .match-details {
        padding: 25px 0;
        text-align: left; } }

@media (max-width: 575px) {
  .tournament-countdown .tournament-title h2 {
    font-size: 28px; }
  .video-section .video-image .video-btn.popup-youtube {
    top: 40%;
    left: 45%;
    font-size: 40px; }
  .video-style-02 .video-bg {
    padding: 40px 0px; }
  .video-style-02 .match-box .match-team span {
    font-size: 30px; }
  .video-style-02 .match-video {
    padding-top: 40px; }
  /*performers*/
  .performers-box {
    display: block; }
    .performers-box .performers-info .player-name {
      font-size: 24px;
      margin-bottom: 8px; }
    .performers-box .performers-info .player-rank {
      font-size: 24px; }
    .performers-box .performers-img {
      margin-bottom: 30px; }
      .performers-box .performers-img img {
        left: 0px; }
    .performers-box .performers-img.player-02 img {
      right: 0; }
  .video-section-02 {
    padding-top: 40px;
    padding-bottom: 40px; }
  .products:after, .products:before {
    content: none; }
  .products .pattern-7 {
    display: none; }
  .game-countdown {
    padding-top: 40px;
    padding-bottom: 40px; }
  .featured-news {
    padding-bottom: 40px; }
  .inner-banner .inner-banner-tittle h2 {
    font-size: 30px;
    line-height: 40px; } }

@media (max-width: 479px) {
  /*Upcoming Match*/
  .upcoming-match .match-date-time {
    padding: 0 15px; }
    .upcoming-match .match-date-time .match-time {
      font-size: 20px;
      line-height: 20px; }
  .upcoming-match-table .match-schedule {
    padding: 30px 20px; }
    .upcoming-match-table .match-schedule > div {
      width: 100%; }
      .upcoming-match-table .match-schedule > div:before {
        content: inherit; }
    .upcoming-match-table .match-schedule .article {
      margin-bottom: 0px; }
  .contant-404 h1 {
    font-size: 120px; } }

.map {
  height: 100vh;
  filter: grayscale(100%) invert(92%) contrast(90%); }

/*****************************
    Theme Yellow
*****************************/
.theme-yellow {
  /* form-control */
  /* Input Radio */
  /* Select Dropdown Arrow */
  /*Color*/
  /*Background*/
  /*Btn Color*/
  /*Header*/
  /*Header Style 02*/
  /*Search*/
  /*Banner*/
  /*Banner 02*/
  /*Banner 03*/
  /*Upcoming Match*/
  /*Blog*/
  /*Blog Style 01*/
  /*Blog Style 02*/
  /*Featured News*/
  /*Blog video*/
  /*Blog Detail*/
  /*Owl Carousel*/
  /*Portfolio*/
  /*Images Gallery*/
  /*Testimonial*/
  /*Tab*/
  /*footer*/
  /*Table*/
  /*Video*/
  /*Video Style 02*/
  /*Video Style 03*/
  /*Performers*/
  /*Social Icon*/
  /*Inner Banner*/
  /*Progress Bar*/
  /*Counter*/
  /*Time Location*/
  /*Time Location*/
  /*Team Single*/
  /*Team Info*/
  /*Players Details*/
  /*History info*/
  /*Match Schedule Table*/
  /*Events*/
  /*Sidebar*/
  /*Ticket*/
  /*Accordion*/
  /*Coming Soon*/
  /*404*/
  /*List*/
  /*Portfolio*/
  /*portfolio-style-02*/
  /*Portfolio Style 03*/
  /*Filters Group*/
  /* Product */
  /* Product Detail */
  /*commentlist*/
  /* Checkout */
  /* Cart */
  /* Shop Sidebar */
  /* Pagination */
  /*feature style 01*/
  /*feature style 02*/
  /*Back To Top*/ }
  .theme-yellow *::-moz-selection {
    background: #e5af13;
    color: #ffffff;
    text-shadow: none; }
  .theme-yellow ::-moz-selection {
    background: #e5af13;
    color: #ffffff;
    text-shadow: none; }
  .theme-yellow ::selection {
    background: #e5af13;
    color: #ffffff;
    text-shadow: none; }
  .theme-yellow .form-control:focus, .theme-yellow .form-select:focus {
    border-color: #e5af13; }
  .theme-yellow input[type="radio"]:radio {
    background-color: #e5af13; }
  .theme-yellow .custom-control-input:radio {
    background-color: #e5af13; }
  .theme-yellow .form-check-input:checked {
    background-color: #e5af13;
    border-color: #e5af13; }
  .theme-yellow .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: rgba(229, 175, 19, 0.1);
    color: #e5af13; }
  .theme-yellow .text-primary {
    color: #e5af13 !important; }
  .theme-yellow .bg-primary {
    background: #e5af13 !important; }
  .theme-yellow .btn.btn-primary {
    background: #e5af13;
    color: #ffffff; }
    .theme-yellow .btn.btn-primary:hover {
      background: #ffffff;
      color: #000000;
      transform: translateY(-2px); }
    .theme-yellow .btn.btn-primary:active {
      box-shadow: none; }
    .theme-yellow .btn.btn-primary:focus {
      box-shadow: none; }
  .theme-yellow .btn-list .btn-white:hover {
    background: #e5af13;
    color: #ffffff; }
  .theme-yellow .header .navbar .navbar-brand .logo-yellow {
    display: block; }
  .theme-yellow .header .navbar .navbar-brand .logo-blue,
  .theme-yellow .header .navbar .navbar-brand .logo {
    display: none; }
  .theme-yellow .header .navbar .navbar-nav li.active > .nav-link {
    color: #e5af13; }
  .theme-yellow .header .navbar .navbar-nav .nav-item .nav-link:hover {
    color: #e5af13; }
  .theme-yellow .header .navbar .navbar-nav .nav-item.mega-menu .dropdown-menu.megamenu .dropdown-submenu:hover {
    color: #e5af13; }
  .theme-yellow .header .navbar .add-listing .header-search .search a:hover {
    color: #e5af13; }
  .theme-yellow .header .navbar .dropdown > .dropdown-menu li.active a {
    color: #e5af13; }
  .theme-yellow .header .dropdown-menu .dropdown-item:hover {
    color: #e5af13; }
  .theme-yellow header.header.header-sticky.is-sticky .navbar .navbar-brand .sticky-logo.sticky-logo-yellow {
    display: block; }
  .theme-yellow header.header.header-sticky.is-sticky .navbar .navbar-brand .logo-blue,
  .theme-yellow header.header.header-sticky.is-sticky .navbar .navbar-brand .logo-yellow {
    display: none; }
  .theme-yellow header.header.header-sticky.is-sticky .navbar .navbar-brand .sticky-logo {
    display: none; }
  .theme-yellow header.header.header-sticky.is-sticky .navbar .navbar-nav .nav-item.active .nav-link {
    color: #e5af13; }
  .theme-yellow .header.header-style-02 .topbar .topbar-left .social-media li a {
    color: #ffffff; }
    .theme-yellow .header.header-style-02 .topbar .topbar-left .social-media li a:hover {
      color: #e5af13; }
  .theme-yellow .header.header-style-02 .topbar .topbar-right .topbar-menu ul li a {
    color: #ffffff; }
    .theme-yellow .header.header-style-02 .topbar .topbar-right .topbar-menu ul li a:hover {
      color: #e5af13; }
  .theme-yellow .header.header-style-02 .topbar .topbar-right .login a {
    color: #ffffff; }
    .theme-yellow .header.header-style-02 .topbar .topbar-right .login a:hover {
      color: #e5af13; }
  .theme-yellow .header.header-style-02.header-sticky.is-sticky .topbar .topbar-left .social-media li a {
    color: #12111a; }
    .theme-yellow .header.header-style-02.header-sticky.is-sticky .topbar .topbar-left .social-media li a:hover {
      color: #e5af13; }
  .theme-yellow .header.header-style-02.header-sticky.is-sticky .topbar .topbar-right .topbar-menu ul li a {
    color: #12111a; }
    .theme-yellow .header.header-style-02.header-sticky.is-sticky .topbar .topbar-right .topbar-menu ul li a:hover {
      color: #e5af13; }
  .theme-yellow .header.header-style-02.header-sticky.is-sticky .topbar .topbar-right .login a {
    color: #12111a; }
    .theme-yellow .header.header-style-02.header-sticky.is-sticky .topbar .topbar-right .login a:hover {
      color: #e5af13; }
  .theme-yellow .header.header-style-03.header-sticky.is-sticky .navbar .navbar-nav .nav-item .nav-link {
    color: #12111a; }
    .theme-yellow .header.header-style-03.header-sticky.is-sticky .navbar .navbar-nav .nav-item .nav-link:hover {
      color: #e5af13; }
  .theme-yellow .header.header-style-03.header-sticky.is-sticky .navbar .navbar-nav .nav-item.active .nav-link {
    color: #e5af13; }
  .theme-yellow .header.header-style-03.header-sticky.is-sticky .navbar .add-listing .header-search .search a:hover {
    color: #e5af13; }
  .theme-yellow .offcanvas.offcanvas-end.offcanvas-sidebar-menu .offcanvas-header .btn-close:hover i {
    color: #e5af13; }
  .theme-yellow .offcanvas.offcanvas-end.offcanvas-sidebar-menu .sidebar-menu .sidebar-logo a .logo-yellow {
    display: block; }
  .theme-yellow .offcanvas.offcanvas-end.offcanvas-sidebar-menu .sidebar-menu .sidebar-logo a .logo,
  .theme-yellow .offcanvas.offcanvas-end.offcanvas-sidebar-menu .sidebar-menu .sidebar-logo a .logo-blue {
    display: none; }
  .theme-yellow #search .close {
    background-color: #ffffff;
    color: #e5af13; }
    .theme-yellow #search .close:hover {
      background-color: #e5af13;
      color: #ffffff; }
  .theme-yellow .banner .slider-social .slider-social-info ul li a {
    color: #ffffff; }
    .theme-yellow .banner .slider-social .slider-social-info ul li a:hover {
      color: #e5af13; }
  .theme-yellow .banner .swiper-slide .btn.btn-white:hover {
    background: #000000;
    color: #ffffff; }
  .theme-yellow .banner .pagination-button .swiper-button-prev {
    color: #ffffff; }
    .theme-yellow .banner .pagination-button .swiper-button-prev:hover {
      color: #e5af13; }
    .theme-yellow .banner .pagination-button .swiper-button-prev:after {
      background: #ffffff; }
  .theme-yellow .banner .pagination-button .swiper-button-next {
    color: #ffffff; }
    .theme-yellow .banner .pagination-button .swiper-button-next:hover {
      color: #e5af13; }
  .theme-yellow .banner.banner-02 .swiper-slide .btn:hover {
    background-color: #e5af13;
    color: #ffffff; }
  .theme-yellow .banner.banner-03 .swiper-slide h1 span {
    color: rgba(229, 175, 19, 0.4); }
  .theme-yellow .banner.banner-03 .swiper-slide .banner-img {
    background-color: #e5af13; }
  .theme-yellow .banner.banner-03 .swiper-slide .btn.btn-white {
    color: #e5af13; }
  .theme-yellow .banner.banner-03 .pagination-button .swiper-button-next:hover,
  .theme-yellow .banner.banner-03 .pagination-button .swiper-button-prev:hover {
    color: #e5af13; }
  .theme-yellow .upcoming-match .match-date-time .match-date i {
    color: #e5af13; }
  .theme-yellow .blog-post .blog-post-details .tag {
    color: #e5af13; }
  .theme-yellow .blog-post .blog-post-details h5 a:hover {
    color: #e5af13; }
  .theme-yellow .blog-post .blog-post-details .blog-link {
    color: #e5af13; }
    .theme-yellow .blog-post .blog-post-details .blog-link:hover {
      color: #ffffff; }
  .theme-yellow .blog-post.post-style-01 .blog-image .blog-post-date {
    background-color: #e5af13;
    color: #ffffff; }
  .theme-yellow .blog-post.post-style-01 .blog-image .blog-post-meta .blog-post-author a:hover {
    color: #e5af13; }
  .theme-yellow .blog-post.post-style-01 .blog-image .blog-post-meta .blog-post-time a:hover {
    color: #e5af13; }
  .theme-yellow .blog-post.post-style-01 .blog-post-details .blog-title a:hover {
    color: #e5af13; }
  .theme-yellow .blog-post.post-style-01 .blog-post-details .blog-title:hover {
    color: #e5af13; }
  .theme-yellow .blog-post.post-style-02 .blog-image .blog-post-date {
    background-color: #e5af13;
    color: #ffffff; }
  .theme-yellow .blog-post.post-style-02 .blog-post-details .tag {
    background-color: #ffffff;
    color: #e5af13; }
  .theme-yellow .blog-post.post-style-02 .blog-post-details h5 a:hover {
    color: #e5af13; }
  .theme-yellow .featured-news .blog-post.post-style-03 .blog-post-details .blog-title a:hover {
    color: #e5af13; }
  .theme-yellow .featured-news .blog-post.post-style-02 .blog-post-details .blog-title a:hover {
    color: #e5af13; }
  .theme-yellow .video-bg-section .video-image .video-btn.popup-youtube {
    background-color: #ffffff;
    color: #e5af13; }
    .theme-yellow .video-bg-section .video-image .video-btn.popup-youtube i {
      font-size: 30px; }
    .theme-yellow .video-bg-section .video-image .video-btn.popup-youtube:hover {
      background-color: #e5af13;
      color: #ffffff; }
  .theme-yellow .blog-post-social span {
    color: #e5af13; }
  .theme-yellow .blog-post-social ul li a {
    color: #ffffff; }
    .theme-yellow .blog-post-social ul li a:hover {
      color: #e5af13; }
  .theme-yellow .blog-post-navigation .post-navigation .nav-link .nav-previous a:before {
    background-color: #e5af13; }
  .theme-yellow .blog-post-navigation .post-navigation .nav-link .nav-previous a:hover i {
    color: #e5af13; }
  .theme-yellow .blog-post-navigation .post-navigation .nav-link .nav-previous a:hover span {
    color: #e5af13; }
  .theme-yellow .blog-post-navigation .post-navigation .nav-link .nav-next a:before {
    background-color: #e5af13; }
  .theme-yellow .blog-post-navigation .post-navigation .nav-link .nav-next a:hover i {
    color: #e5af13; }
  .theme-yellow .blog-post-navigation .post-navigation .nav-link .nav-next a:hover span {
    color: #e5af13; }
  .theme-yellow .blog-detail .quote-yellow {
    display: inline-block; }
  .theme-yellow .blog-detail .quote-primary,
  .theme-yellow .blog-detail .quote-blue {
    display: none; }
  .theme-yellow .blog-detail .comments-01 .comment-content .reviews .port-social {
    color: #e5af13; }
    .theme-yellow .blog-detail .comments-01 .comment-content .reviews .port-social:hover {
      color: #ffffff; }
  .theme-yellow .blog-detail .comments-02 .comment-content .reviews .port-social {
    color: #e5af13; }
    .theme-yellow .blog-detail .comments-02 .comment-content .reviews .port-social:hover {
      color: #ffffff; }
  .theme-yellow .owl-carousel .owl-nav button:hover {
    background-color: #e5af13 !important; }
  .theme-yellow .owl-carousel .owl-dots .owl-dot span:hover {
    background-color: #e5af13; }
  .theme-yellow .owl-carousel .owl-dots .owl-dot.active span {
    background-color: #e5af13; }
  .theme-yellow .portfolio .portfolio-info .portfolio-sub-title:hover {
    color: #e5af13; }
  .theme-yellow .portfolio .portfolio-info .portfolio-title:hover {
    color: #e5af13; }
  .theme-yellow .portfolio .portfolio-info .btn {
    color: #e5af13; }
    .theme-yellow .portfolio .portfolio-info .btn:hover {
      color: #ffffff; }
  .theme-yellow .images-gallery .portfolio.portfolio-style-02 .portfolio-images:before {
    background-color: rgba(229, 175, 19, 0.5); }
  .theme-yellow .testimonial .testimonial-star-icon ul li i {
    color: #e5af13; }
  .theme-yellow .testimonial .testimonial-author .testimonial-name .author-tittle {
    color: #e5af13; }
  .theme-yellow .tab .nav .nav-item .nav-link:hover {
    color: #e5af13; }
  .theme-yellow .tab .nav .nav-item .nav-link.active {
    color: #e5af13; }
  .theme-yellow .news-tab-nav.nav .nav-item .nav-link:hover {
    color: #e5af13; }
  .theme-yellow .news-tab-nav.nav .nav-item .nav-link.active {
    color: #e5af13; }
  .theme-yellow .footer .min-footer .footer-logo .logo-yellow {
    display: inline-block; }
  .theme-yellow .footer .min-footer .footer-logo .logo-blue,
  .theme-yellow .footer .min-footer .footer-logo .logo {
    display: none; }
  .theme-yellow .footer .min-footer .footer-title {
    color: #e5af13; }
  .theme-yellow .footer .min-footer .footer-menu ul li a:hover {
    color: #e5af13; }
  .theme-yellow .footer .min-footer .newsletter-form .form-control:focus {
    border-color: #e5af13; }
  .theme-yellow .footer .min-footer .footer-social ul li a:hover {
    color: #e5af13; }
  .theme-yellow .footer .min-footer .contact-info li i {
    color: #e5af13; }
  .theme-yellow .footer .footer-bottom .copyright a:hover {
    color: #e5af13; }
  .theme-yellow .footer .footer-bottom .copyright .copyright-menu.footer-menu ul li a:hover {
    color: #e5af13; }
  .theme-yellow .footer-instagram ul li a i {
    color: #ffffff; }
  .theme-yellow .footer-instagram ul li a:before {
    background-color: rgba(229, 175, 19, 0.5); }
  .theme-yellow .footer.footer-style-02 .footer-bottom .footer-logo .logo-yellow {
    display: inline-block; }
  .theme-yellow .footer.footer-style-02 .footer-bottom .footer-logo .logo,
  .theme-yellow .footer.footer-style-02 .footer-bottom .footer-logo .logo-blue {
    display: none; }
  .theme-yellow .footer-style-03 .min-footer .footer-logo .logo-yellow {
    display: block; }
  .theme-yellow .footer-style-03 .min-footer .footer-logo .logo-blue,
  .theme-yellow .footer-style-03 .min-footer .footer-logo .logo {
    display: none; }
  .theme-yellow .footer-style-03 .min-footer .footer-menu ul li a:hover {
    color: #e5af13; }
  .theme-yellow .footer-style-03 .min-footer .footer-social ul li a:hover {
    color: #e5af13; }
  .theme-yellow .footer-style-03 .footer-title {
    color: #e5af13; }
  .theme-yellow .footer-style-03 .footer-bottom .copyright a:hover {
    color: #e5af13; }
  .theme-yellow .footer-style-03 .footer-bottom .copyright-menu.footer-menu ul li a:hover {
    color: #e5af13; }
  .theme-yellow .table thead tr th {
    background-color: #e5af13;
    color: #ffffff;
    border-bottom: 1px solid #e5af13; }
  .theme-yellow .points-table-style-02 .table thead tr th {
    background-color: transparent;
    color: #ffffff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .theme-yellow .video-style-vertical .video-post .video-image .video-icon a {
    color: #e5af13; }
  .theme-yellow .video-style-vertical .video-post .video-post-details .badge {
    background: #e5af13; }
  .theme-yellow .video-style-vertical .video-post .video-post-details .video-title a {
    color: #ffffff; }
    .theme-yellow .video-style-vertical .video-post .video-post-details .video-title a:hover {
      color: #e5af13; }
  .theme-yellow .video-style-vertical .video-post .video-post-details .video-view a {
    color: #ffffff; }
    .theme-yellow .video-style-vertical .video-post .video-post-details .video-view a:hover {
      color: #e5af13; }
  .theme-yellow .video-style-vertical::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .theme-yellow .video-style-vertical::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5; }
  .theme-yellow .video-style-vertical::-webkit-scrollbar-thumb {
    background-color: #e5af13; }
  .theme-yellow .video-image .video-btn.popup-youtube {
    color: #ffffff; }
    .theme-yellow .video-image .video-btn.popup-youtube:hover {
      color: #e5af13; }
  .theme-yellow .video-image .video-icon a {
    background: #ffffff; }
  .theme-yellow .video-style-02 .match-video .play-btn i {
    color: #e5af13; }
  .theme-yellow .video-style-03 .play-btn {
    background-color: #e5af13;
    color: #ffffff; }
    .theme-yellow .video-style-03 .play-btn:hover {
      background-color: #ffffff;
      color: #e5af13; }
  .theme-yellow .video-section-02 .video-style-04 .play-btn {
    color: #e5af13; }
    .theme-yellow .video-section-02 .video-style-04 .play-btn:hover {
      background-color: #e5af13;
      color: #ffffff; }
  .theme-yellow .performers-box .performers-info .player-position span,
  .theme-yellow .performers-box .performers-info .player-height span,
  .theme-yellow .performers-box .performers-info .player-weight span {
    color: #e5af13; }
  .theme-yellow .social-icon ul li a:hover {
    color: #e5af13; }
  .theme-yellow .inner-banner .inner-banner-tittle .breadcrumb .breadcrumb-item a:hover {
    color: #e5af13; }
  .theme-yellow .inner-banner .inner-banner-tittle .breadcrumb .breadcrumb-item.active {
    color: #e5af13; }
  .theme-yellow .progress .progress-bar {
    background-color: #e5af13; }
  .theme-yellow .counter .counter-number .counter-text span {
    color: #e5af13; }
  .theme-yellow .counter .counter-number p {
    color: #e5af13; }
  .theme-yellow .counter:hover:before {
    background-color: rgba(229, 175, 19, 0.3); }
  .theme-yellow .time-location .time i,
  .theme-yellow .time-location .location i {
    color: #e5af13; }
  .theme-yellow .classic-players .owl-carousel .item .player-info .player-number {
    background-color: #e5af13;
    color: #ffffff; }
  .theme-yellow .classic-players .owl-carousel .item .player-info .player-name .title a:hover {
    color: #e5af13; }
  .theme-yellow .team .player-info .player-number {
    background-color: #e5af13;
    color: #ffffff; }
  .theme-yellow .team .player-info .title a:hover {
    color: #e5af13; }
  .theme-yellow .player-single .player-info .player-position {
    color: #e5af13; }
  .theme-yellow .team-info .team .player-info .player-num {
    background-color: #e5af13; }
  .theme-yellow .team-info .team .player-info .player-name {
    color: #e5af13; }
  .theme-yellow .player-info .player-position {
    color: #e5af13; }
  .theme-yellow .players-details .player-detail .player-profile .rank {
    color: rgba(229, 175, 19, 0.1); }
  .theme-yellow .history-info .history-info-inner .history-info-item .history-info-number .time .year {
    color: #e5af13; }
  .theme-yellow .history-info .history-info-inner .history-info-item .history-info-content .history-info-title a:hover {
    color: #e5af13; }
  .theme-yellow .upcoming-match-table .match-schedule:before {
    background-color: #e5af13; }
  .theme-yellow .events-box .events-details .events-date {
    background-color: #e5af13;
    color: #ffffff; }
  .theme-yellow .events-box .events-details .events-title a:hover {
    color: #e5af13; }
  .theme-yellow .events-box .events-details .events-meta {
    color: #e5af13; }
  .theme-yellow .single-event .single-event-details .events-title a:hover {
    color: #e5af13; }
  .theme-yellow .sidebar .widget {
    /*Recent Post*/
    /*sidebar-categories*/
    /*popular-tag*/ }
    .theme-yellow .sidebar .widget .recent-post .post-info .post-title a:hover {
      color: #e5af13; }
    .theme-yellow .sidebar .widget .widget-categories ul.list-style li a:hover {
      color: #e5af13; }
    .theme-yellow .sidebar .widget .widget-categories .list-style-underline li a:hover {
      color: #e5af13; }
    .theme-yellow .sidebar .widget .popular-tag ul li a:hover {
      color: #e5af13;
      border: 1px solid #e5af13; }
  .theme-yellow .ticket-box .ticket-details .ticket-info .ticket-price {
    color: #e5af13; }
  .theme-yellow .ticket-box .ticket-details .match-details .match-vs {
    color: #e5af13; }
  .theme-yellow .accordion .accordion-item .accordion-header .accordion-button:hover {
    color: #e5af13; }
  .theme-yellow .accordion .accordion-item .accordion-header .accordion-button:hover:after {
    color: #e5af13; }
  .theme-yellow .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    background-color: #e5af13;
    color: #ffffff; }
    .theme-yellow .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):after {
      color: #ffffff; }
  .theme-yellow .checkout-review .accordion .card .accordion-icon.card-header button {
    color: #e5af13; }
    .theme-yellow .checkout-review .accordion .card .accordion-icon.card-header button:before {
      border: 2px solid #e5af13; }
  .theme-yellow .asked-questions .questions-title .number {
    background-color: #e5af13;
    color: #ffffff; }
  .theme-yellow .coming-soon-section .coming-soon-logo .logo-yellow {
    display: block; }
  .theme-yellow .coming-soon-section .coming-soon-logo .logo-blue,
  .theme-yellow .coming-soon-section .coming-soon-logo .logo {
    display: none; }
  .theme-yellow .coming-soon-section .countdown-main .countdown span {
    color: #ffffff; }
  .theme-yellow .coming-soon-section .countdown-main .countdown p {
    color: #e5af13; }
  .theme-yellow .contant-404 h1 {
    color: #ffffff; }
    .theme-yellow .contant-404 h1 span {
      color: #e5af13; }
  .theme-yellow .list ul li i {
    color: #e5af13; }
  .theme-yellow ul.list li i {
    color: #e5af13; }
  .theme-yellow .portfolio .portfolio-info .portfolio-sub-title {
    color: #ffffff; }
    .theme-yellow .portfolio .portfolio-info .portfolio-sub-title:hover {
      color: #e5af13; }
  .theme-yellow .portfolio .portfolio-info .portfolio-title {
    color: #ffffff; }
    .theme-yellow .portfolio .portfolio-info .portfolio-title:hover {
      color: #e5af13; }
  .theme-yellow .portfolio .portfolio-info .btn {
    color: #e5af13; }
    .theme-yellow .portfolio .portfolio-info .btn:hover {
      color: #ffffff; }
  .theme-yellow .portfolio-style-02 .portfolio-info-02 .popup-icon {
    background: #ffffff;
    color: #e5af13; }
  .theme-yellow .portfolio.portfolio-style-03 .portfolio-info .portfolio-title:hover {
    color: #e5af13; }
  .theme-yellow .filters-group button {
    background: #e5af13;
    color: #ffffff; }
    .theme-yellow .filters-group button:hover {
      background: #ffffff;
      color: #e5af13; }
    .theme-yellow .filters-group button:focus {
      color: #ffffff; }
    .theme-yellow .filters-group button:active {
      color: #ffffff; }
  .theme-yellow .filters-group button.active {
    background-color: #ffffff;
    color: #e5af13; }
  .theme-yellow .product .add-to-cart a {
    background: #e5af13;
    color: #ffffff; }
  .theme-yellow .product .product-description .product-title a {
    color: #ffffff; }
    .theme-yellow .product .product-description .product-title a:hover {
      color: #e5af13; }
  .theme-yellow .product .product-description .product-price ins {
    color: #e5af13; }
  .theme-yellow .product .product-info .product-name a {
    color: #ffffff; }
    .theme-yellow .product .product-info .product-name a:hover {
      color: #e5af13; }
  .theme-yellow .product .product-info .price ins {
    color: #e5af13; }
  .theme-yellow .shop-single .nav-tabs-02 .nav-item .nav-link {
    color: #ffffff; }
  .theme-yellow .shop-single .nav-tabs-02 .nav-item .nav-link.active {
    color: #e5af13; }
  .theme-yellow .shop-single .nav-tabs-02 .tab-content .tab-pane p {
    color: #ffffff; }
  .theme-yellow .shop-single .nav-tabs-02 .tab-content .tab-pane .list ul li i {
    color: #e5af13; }
  .theme-yellow .shop-single .product-detail .product-price-rating .product-prize p {
    color: #ffffff; }
    .theme-yellow .shop-single .product-detail .product-price-rating .product-prize p span.price {
      color: rgba(255, 255, 255, 0.6); }
      .theme-yellow .shop-single .product-detail .product-price-rating .product-prize p span.price ins {
        color: #e5af13; }
  .theme-yellow .shop-single .product-detail .product-price-rating .product-prize h4 span {
    color: #e5af13; }
  .theme-yellow .shop-single .product-detail .product-price-rating .product-rating i {
    color: #e5af13; }
  .theme-yellow .shop-single .product-detail .product-summary a {
    color: #e5af13; }
    .theme-yellow .shop-single .product-detail .product-summary a i {
      color: #e5af13; }
    .theme-yellow .shop-single .product-detail .product-summary a:hover {
      color: #ffffff; }
  .theme-yellow .shop-single .product-detail .product-detail-meta span a {
    color: #e5af13; }
    .theme-yellow .shop-single .product-detail .product-detail-meta span a:hover {
      color: #ffffff; }
  .theme-yellow .slider-slick .slider-nav .slick-next:before {
    color: #ffffff; }
  .theme-yellow .slider-slick .slider-nav .slick-prev:before {
    color: #ffffff; }
  .theme-yellow .slider-slick .slider-nav .slick-prev {
    background: #e5af13; }
  .theme-yellow .slider-slick .slider-nav .slick-next {
    background: #e5af13; }
  .theme-yellow .commentlist .comment-content .reviews .rating i {
    color: #e5af13; }
  .theme-yellow .checkout-review .table tr th {
    color: #ffffff; }
  .theme-yellow .checkout-review .table tr td {
    color: #ffffff; }
  .theme-yellow .checkout-review .table tr td.amount {
    font-size: 18px;
    color: #e5af13; }
  .theme-yellow .checkout-info > a {
    color: #e5af13; }
  .theme-yellow .cart-table .table tr th {
    color: #ffffff;
    background-color: #e5af13; }
  .theme-yellow .cart-table .table tr td a:hover {
    color: #e5af13; }
  .theme-yellow .cart-table .table tbody tr td.product-name a {
    color: #ffffff; }
    .theme-yellow .cart-table .table tbody tr td.product-name a:hover {
      color: #e5af13; }
  .theme-yellow .cart-totals .checkout-button:hover {
    background-color: #12111a;
    color: #ffffff; }
  .theme-yellow .gridlist-toggle-wrap .gridlist-toggle a:hover, .theme-yellow .gridlist-toggle-wrap .gridlist-toggle a.active {
    background-color: #e5af13; }
  .theme-yellow .shop-sidebar .widget .social ul li a {
    color: #8d8c8c; }
    .theme-yellow .shop-sidebar .widget .social ul li a:hover {
      color: #e5af13; }
  .theme-yellow .shop-sidebar .widget .price-filter a {
    color: #e5af13; }
  .theme-yellow .shop-sidebar .widget .tagcloud ul li a {
    color: #8d8c8c; }
    .theme-yellow .shop-sidebar .widget .tagcloud ul li a:hover {
      background: #e5af13;
      border: 1px solid #e5af13;
      color: #ffffff; }
  .theme-yellow .shop-sidebar .widget .widget-categories ul li a {
    color: #35343c; }
    .theme-yellow .shop-sidebar .widget .widget-categories ul li a:hover {
      color: #e5af13; }
  .theme-yellow .irs--flat .irs-bar {
    background: #e5af13; }
  .theme-yellow .irs--flat .irs-from,
  .theme-yellow .irs--flat .irs-to,
  .theme-yellow .irs--flat .irs-single {
    background: transparent;
    color: #e5af13; }
  .theme-yellow .irs--flat .irs-handle > i:first-child,
  .theme-yellow .irs--flat .irs-handle > i:first-child {
    background: #ffffff;
    border: 2px solid #e5af13; }
  .theme-yellow .irs--flat .irs-handle.state_hover > i:first-child,
  .theme-yellow .irs--flat .irs-handle:hover > i:first-child {
    background: #e5af13; }
  .theme-yellow .pagination .page-item .page-link {
    color: #e5af13;
    background: #f5f5f5;
    border-color: #f5f5f5; }
    .theme-yellow .pagination .page-item .page-link:hover {
      background: #e5af13;
      border-color: #e5af13;
      color: #ffffff; }
    .theme-yellow .pagination .page-item .page-link:focus {
      background: #e5af13;
      border-color: #e5af13;
      color: #ffffff; }
  .theme-yellow .pagination .page-item.active .page-link {
    background: #e5af13;
    border-color: #e5af13;
    color: #ffffff; }
  .theme-yellow .feature-item.feature-info-style-01 .feature-icon {
    background-color: #ffffff; }
    .theme-yellow .feature-item.feature-info-style-01 .feature-icon object {
      filter: invert(70%) sepia(62%) saturate(585%) hue-rotate(360deg) brightness(91%) contrast(101%); }
  .theme-yellow .feature-item.feature-info-style-02 .feature-icon object {
    filter: invert(70%) sepia(62%) saturate(585%) hue-rotate(360deg) brightness(91%) contrast(101%); }
  .theme-yellow .back-to-top a {
    background: #e5af13; }

@media (max-width: 1199px) {
  .theme-yellow .header.header-style-02 .navbar .navbar-nav .dropdown-menu li.active .dropdown-item {
    color: #e5af13; }
  .theme-yellow header.header.header-sticky.is-sticky .navbar .navbar-nav .nav-item .nav-link:hover {
    color: #e5af13; }
  .theme-yellow .header.header-style-03.header-sticky.is-sticky .navbar .navbar-nav .nav-item .nav-link {
    color: #ffffff; } }

/*****************************
    Theme Blue
*****************************/
.theme-blue {
  /* form-control */
  /* Input Radio */
  /* Select Dropdown Arrow */
  /*Color*/
  /*Background*/
  /*Btn Color*/
  /*Header*/
  /*Header Style 02*/
  /*Search*/
  /*Banner*/
  /*Banner 02*/
  /*Banner 03*/
  /*Upcoming Match*/
  /*Blog*/
  /*Blog Style 01*/
  /*Blog Style 02*/
  /*Featured News*/
  /*Blog video*/
  /*Blog Detail*/
  /*Owl Carousel*/
  /*Portfolio*/
  /*Images Gallery*/
  /*Testimonial*/
  /*Tab*/
  /*footer*/
  /*Table*/
  /*Video*/
  /*Video Style 02*/
  /*Video Style 03*/
  /*Performers*/
  /*Social Icon*/
  /*Inner Banner*/
  /*Progress Bar*/
  /*Counter*/
  /*Time Location*/
  /*Time Location*/
  /*Team Single*/
  /*Team Info*/
  /*Players Details*/
  /*History info*/
  /*Match Schedule Table*/
  /*Events*/
  /*Sidebar*/
  /*Ticket*/
  /*Accordion*/
  /*Coming Soon*/
  /*404*/
  /*List*/
  /*Portfolio*/
  /*portfolio-style-02*/
  /*Portfolio Style 03*/
  /*Filters Group*/
  /* Product */
  /* Product Detail */
  /*commentlist*/
  /* Checkout */
  /* Cart */
  /* Shop Sidebar */
  /* Pagination */
  /*feature style 01*/
  /*feature style 02*/
  /*Back To Top*/ }
  .theme-blue *::-moz-selection {
    background: #0751df;
    color: #ffffff;
    text-shadow: none; }
  .theme-blue ::-moz-selection {
    background: #0751df;
    color: #ffffff;
    text-shadow: none; }
  .theme-blue ::selection {
    background: #0751df;
    color: #ffffff;
    text-shadow: none; }
  .theme-blue .form-control:focus, .theme-blue .form-select:focus {
    border-color: #0751df; }
  .theme-blue input[type="radio"]:radio {
    background-color: #0751df; }
  .theme-blue .custom-control-input:radio {
    background-color: #0751df; }
  .theme-blue .form-check-input:checked {
    background-color: #0751df;
    border-color: #0751df; }
  .theme-blue .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: rgba(7, 81, 223, 0.1);
    color: #0751df; }
  .theme-blue .text-primary {
    color: #0751df !important; }
  .theme-blue .bg-primary {
    background: #0751df !important; }
  .theme-blue .btn.btn-primary {
    background: #0751df;
    color: #ffffff; }
    .theme-blue .btn.btn-primary:hover {
      background: #ffffff;
      color: #000000;
      transform: translateY(-2px); }
    .theme-blue .btn.btn-primary:active {
      box-shadow: none; }
    .theme-blue .btn.btn-primary:focus {
      box-shadow: none; }
  .theme-blue .btn-list .btn-white:hover {
    background: #0751df;
    color: #ffffff; }
  .theme-blue .header .navbar .navbar-brand .logo-blue {
    display: block; }
  .theme-blue .header .navbar .navbar-brand .logo-yellow,
  .theme-blue .header .navbar .navbar-brand .logo {
    display: none; }
  .theme-blue .header .navbar .navbar-nav li.active > .nav-link {
    color: #0751df; }
  .theme-blue .header .navbar .navbar-nav .nav-item .nav-link:hover {
    color: #0751df; }
  .theme-blue .header .navbar .navbar-nav .nav-item.mega-menu .dropdown-menu.megamenu .dropdown-submenu:hover {
    color: #0751df; }
  .theme-blue .header .navbar .add-listing .header-search .search a:hover {
    color: #0751df; }
  .theme-blue .header .navbar .dropdown > .dropdown-menu li.active a {
    color: #0751df; }
  .theme-blue .header .dropdown-menu .dropdown-item:hover {
    color: #0751df; }
  .theme-blue header.header.header-sticky.is-sticky .navbar .navbar-brand .sticky-logo.sticky-logo-blue {
    display: block; }
  .theme-blue header.header.header-sticky.is-sticky .navbar .navbar-brand .logo-blue,
  .theme-blue header.header.header-sticky.is-sticky .navbar .navbar-brand .logo-yellow {
    display: none; }
  .theme-blue header.header.header-sticky.is-sticky .navbar .navbar-brand .sticky-logo {
    display: none; }
  .theme-blue header.header.header-sticky.is-sticky .navbar .navbar-nav .nav-item.active .nav-link {
    color: #0751df; }
  .theme-blue .header.header-style-02 .topbar .topbar-left .social-media li a {
    color: #ffffff; }
    .theme-blue .header.header-style-02 .topbar .topbar-left .social-media li a:hover {
      color: #0751df; }
  .theme-blue .header.header-style-02 .topbar .topbar-right .topbar-menu ul li a {
    color: #ffffff; }
    .theme-blue .header.header-style-02 .topbar .topbar-right .topbar-menu ul li a:hover {
      color: #0751df; }
  .theme-blue .header.header-style-02 .topbar .topbar-right .login a {
    color: #ffffff; }
    .theme-blue .header.header-style-02 .topbar .topbar-right .login a:hover {
      color: #0751df; }
  .theme-blue .header.header-style-02.header-sticky.is-sticky .topbar .topbar-left .social-media li a {
    color: #12111a; }
    .theme-blue .header.header-style-02.header-sticky.is-sticky .topbar .topbar-left .social-media li a:hover {
      color: #0751df; }
  .theme-blue .header.header-style-02.header-sticky.is-sticky .topbar .topbar-right .topbar-menu ul li a {
    color: #12111a; }
    .theme-blue .header.header-style-02.header-sticky.is-sticky .topbar .topbar-right .topbar-menu ul li a:hover {
      color: #0751df; }
  .theme-blue .header.header-style-02.header-sticky.is-sticky .topbar .topbar-right .login a {
    color: #12111a; }
    .theme-blue .header.header-style-02.header-sticky.is-sticky .topbar .topbar-right .login a:hover {
      color: #0751df; }
  .theme-blue .header.header-style-03.header-sticky.is-sticky .navbar .navbar-nav .nav-item .nav-link {
    color: #12111a; }
    .theme-blue .header.header-style-03.header-sticky.is-sticky .navbar .navbar-nav .nav-item .nav-link:hover {
      color: #0751df; }
  .theme-blue .header.header-style-03.header-sticky.is-sticky .navbar .navbar-nav .nav-item.active .nav-link {
    color: #0751df; }
  .theme-blue .header.header-style-03.header-sticky.is-sticky .navbar .add-listing .header-search .search a:hover {
    color: #0751df; }
  .theme-blue .offcanvas.offcanvas-end.offcanvas-sidebar-menu .offcanvas-header .btn-close:hover i {
    color: #0751df; }
  .theme-blue .offcanvas.offcanvas-end.offcanvas-sidebar-menu .sidebar-menu .sidebar-logo a .logo-blue {
    display: block; }
  .theme-blue .offcanvas.offcanvas-end.offcanvas-sidebar-menu .sidebar-menu .sidebar-logo a .logo,
  .theme-blue .offcanvas.offcanvas-end.offcanvas-sidebar-menu .sidebar-menu .sidebar-logo a .logo-yellow {
    display: none; }
  .theme-blue #search .close {
    background-color: #ffffff;
    color: #0751df; }
    .theme-blue #search .close:hover {
      background-color: #0751df;
      color: #ffffff; }
  .theme-blue .banner .slider-social .slider-social-info ul li a {
    color: #ffffff; }
    .theme-blue .banner .slider-social .slider-social-info ul li a:hover {
      color: #0751df; }
  .theme-blue .banner .swiper-slide .btn.btn-white:hover {
    background: #000000;
    color: #ffffff; }
  .theme-blue .banner .pagination-button .swiper-button-prev {
    color: #ffffff; }
    .theme-blue .banner .pagination-button .swiper-button-prev:hover {
      color: #0751df; }
    .theme-blue .banner .pagination-button .swiper-button-prev:after {
      background: #ffffff; }
  .theme-blue .banner .pagination-button .swiper-button-next {
    color: #ffffff; }
    .theme-blue .banner .pagination-button .swiper-button-next:hover {
      color: #0751df; }
  .theme-blue .banner.banner-02 .swiper-slide .btn:hover {
    background-color: #0751df;
    color: #ffffff; }
  .theme-blue .banner.banner-03 .swiper-slide h1 span {
    color: rgba(7, 81, 223, 0.4); }
  .theme-blue .banner.banner-03 .swiper-slide .banner-img {
    background-color: #0751df; }
  .theme-blue .banner.banner-03 .swiper-slide .btn.btn-white {
    color: #0751df; }
  .theme-blue .banner.banner-03 .pagination-button .swiper-button-next:hover,
  .theme-blue .banner.banner-03 .pagination-button .swiper-button-prev:hover {
    color: #0751df; }
  .theme-blue .upcoming-match .match-date-time .match-date i {
    color: #0751df; }
  .theme-blue .blog-post .blog-post-details .tag {
    color: #0751df; }
  .theme-blue .blog-post .blog-post-details h5 a:hover {
    color: #0751df; }
  .theme-blue .blog-post .blog-post-details .blog-link {
    color: #0751df; }
    .theme-blue .blog-post .blog-post-details .blog-link:hover {
      color: #ffffff; }
  .theme-blue .blog-post.post-style-01 .blog-image .blog-post-date {
    background-color: #0751df;
    color: #ffffff; }
  .theme-blue .blog-post.post-style-01 .blog-image .blog-post-meta .blog-post-author a:hover {
    color: #0751df; }
  .theme-blue .blog-post.post-style-01 .blog-image .blog-post-meta .blog-post-time a:hover {
    color: #0751df; }
  .theme-blue .blog-post.post-style-01 .blog-post-details .blog-title a:hover {
    color: #0751df; }
  .theme-blue .blog-post.post-style-01 .blog-post-details .blog-title:hover {
    color: #0751df; }
  .theme-blue .blog-post.post-style-02 .blog-image .blog-post-date {
    background-color: #0751df;
    color: #ffffff; }
  .theme-blue .blog-post.post-style-02 .blog-post-details .tag {
    background-color: #ffffff;
    color: #0751df; }
  .theme-blue .blog-post.post-style-02 .blog-post-details h5 a:hover {
    color: #0751df; }
  .theme-blue .featured-news .blog-post.post-style-03 .blog-post-details .blog-title a:hover {
    color: #0751df; }
  .theme-blue .featured-news .blog-post.post-style-02 .blog-post-details .blog-title a:hover {
    color: #0751df; }
  .theme-blue .video-bg-section .video-image .video-btn.popup-youtube {
    background-color: #ffffff;
    color: #0751df; }
    .theme-blue .video-bg-section .video-image .video-btn.popup-youtube i {
      font-size: 30px; }
    .theme-blue .video-bg-section .video-image .video-btn.popup-youtube:hover {
      background-color: #0751df;
      color: #ffffff; }
  .theme-blue .blog-post-social span {
    color: #0751df; }
  .theme-blue .blog-post-social ul li a {
    color: #ffffff; }
    .theme-blue .blog-post-social ul li a:hover {
      color: #0751df; }
  .theme-blue .blog-post-navigation .post-navigation .nav-link .nav-previous a:before {
    background-color: #0751df; }
  .theme-blue .blog-post-navigation .post-navigation .nav-link .nav-previous a:hover i {
    color: #0751df; }
  .theme-blue .blog-post-navigation .post-navigation .nav-link .nav-previous a:hover span {
    color: #0751df; }
  .theme-blue .blog-post-navigation .post-navigation .nav-link .nav-next a:before {
    background-color: #0751df; }
  .theme-blue .blog-post-navigation .post-navigation .nav-link .nav-next a:hover i {
    color: #0751df; }
  .theme-blue .blog-post-navigation .post-navigation .nav-link .nav-next a:hover span {
    color: #0751df; }
  .theme-blue .blog-detail .quote-blue {
    display: inline-block; }
  .theme-blue .blog-detail .quote-primary,
  .theme-blue .blog-detail .quote-yellow {
    display: none; }
  .theme-blue .blog-detail .comments-01 .comment-content .reviews .port-social {
    color: #0751df; }
    .theme-blue .blog-detail .comments-01 .comment-content .reviews .port-social:hover {
      color: #ffffff; }
  .theme-blue .blog-detail .comments-02 .comment-content .reviews .port-social {
    color: #0751df; }
    .theme-blue .blog-detail .comments-02 .comment-content .reviews .port-social:hover {
      color: #ffffff; }
  .theme-blue .owl-carousel .owl-nav button:hover {
    background-color: #0751df !important; }
  .theme-blue .owl-carousel .owl-dots .owl-dot span:hover {
    background-color: #0751df; }
  .theme-blue .owl-carousel .owl-dots .owl-dot.active span {
    background-color: #0751df; }
  .theme-blue .portfolio .portfolio-info .portfolio-sub-title:hover {
    color: #0751df; }
  .theme-blue .portfolio .portfolio-info .portfolio-title:hover {
    color: #0751df; }
  .theme-blue .portfolio .portfolio-info .btn {
    color: #0751df; }
    .theme-blue .portfolio .portfolio-info .btn:hover {
      color: #ffffff; }
  .theme-blue .images-gallery .portfolio.portfolio-style-02 .portfolio-images:before {
    background-color: rgba(7, 81, 223, 0.5); }
  .theme-blue .testimonial .testimonial-star-icon ul li i {
    color: #0751df; }
  .theme-blue .testimonial .testimonial-author .testimonial-name .author-tittle {
    color: #0751df; }
  .theme-blue .tab .nav .nav-item .nav-link:hover {
    color: #0751df; }
  .theme-blue .tab .nav .nav-item .nav-link.active {
    color: #0751df; }
  .theme-blue .news-tab-nav.nav .nav-item .nav-link:hover {
    color: #0751df; }
  .theme-blue .news-tab-nav.nav .nav-item .nav-link.active {
    color: #0751df; }
  .theme-blue .footer .min-footer .footer-logo .logo-blue {
    display: inline-block; }
  .theme-blue .footer .min-footer .footer-logo .logo-yellow,
  .theme-blue .footer .min-footer .footer-logo .logo {
    display: none; }
  .theme-blue .footer .min-footer .footer-title {
    color: #0751df; }
  .theme-blue .footer .min-footer .footer-menu ul li a:hover {
    color: #0751df; }
  .theme-blue .footer .min-footer .newsletter-form .form-control:focus {
    border-color: #0751df; }
  .theme-blue .footer .min-footer .footer-social ul li a:hover {
    color: #0751df; }
  .theme-blue .footer .min-footer .contact-info li i {
    color: #0751df; }
  .theme-blue .footer .footer-bottom .copyright a:hover {
    color: #0751df; }
  .theme-blue .footer .footer-bottom .copyright .copyright-menu.footer-menu ul li a:hover {
    color: #0751df; }
  .theme-blue .footer-instagram ul li a i {
    color: #ffffff; }
  .theme-blue .footer-instagram ul li a:before {
    background-color: rgba(7, 81, 223, 0.5); }
  .theme-blue .footer.footer-style-02 .footer-bottom .footer-logo .logo-blue {
    display: inline-block; }
  .theme-blue .footer.footer-style-02 .footer-bottom .footer-logo .logo,
  .theme-blue .footer.footer-style-02 .footer-bottom .footer-logo .logo-yellow {
    display: none; }
  .theme-blue .footer-style-03 .min-footer .footer-logo .logo-blue {
    display: block; }
  .theme-blue .footer-style-03 .min-footer .footer-logo .logo-yellow,
  .theme-blue .footer-style-03 .min-footer .footer-logo .logo {
    display: none; }
  .theme-blue .footer-style-03 .min-footer .footer-menu ul li a:hover {
    color: #0751df; }
  .theme-blue .footer-style-03 .min-footer .footer-social ul li a:hover {
    color: #0751df; }
  .theme-blue .footer-style-03 .footer-title {
    color: #0751df; }
  .theme-blue .footer-style-03 .footer-bottom .copyright a:hover {
    color: #0751df; }
  .theme-blue .footer-style-03 .footer-bottom .copyright-menu.footer-menu ul li a:hover {
    color: #0751df; }
  .theme-blue .table thead tr th {
    background-color: #0751df;
    color: #ffffff;
    border-bottom: 1px solid #0751df; }
  .theme-blue .points-table-style-02 .table thead tr th {
    background-color: transparent;
    color: #ffffff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .theme-blue .video-style-vertical .video-post .video-image .video-icon a {
    color: #0751df; }
  .theme-blue .video-style-vertical .video-post .video-post-details .badge {
    background: #0751df; }
  .theme-blue .video-style-vertical .video-post .video-post-details .video-title a {
    color: #ffffff; }
    .theme-blue .video-style-vertical .video-post .video-post-details .video-title a:hover {
      color: #0751df; }
  .theme-blue .video-style-vertical .video-post .video-post-details .video-view a {
    color: #ffffff; }
    .theme-blue .video-style-vertical .video-post .video-post-details .video-view a:hover {
      color: #0751df; }
  .theme-blue .video-style-vertical::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .theme-blue .video-style-vertical::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5; }
  .theme-blue .video-style-vertical::-webkit-scrollbar-thumb {
    background-color: #0751df; }
  .theme-blue .video-image .video-btn.popup-youtube {
    color: #ffffff; }
    .theme-blue .video-image .video-btn.popup-youtube:hover {
      color: #0751df; }
  .theme-blue .video-image .video-icon a {
    background: #ffffff; }
  .theme-blue .video-style-02 .match-video .play-btn i {
    color: #0751df; }
  .theme-blue .video-style-03 .play-btn {
    background-color: #0751df;
    color: #ffffff; }
    .theme-blue .video-style-03 .play-btn:hover {
      background-color: #ffffff;
      color: #0751df; }
  .theme-blue .video-section-02 .video-style-04 .play-btn {
    color: #0751df; }
    .theme-blue .video-section-02 .video-style-04 .play-btn:hover {
      background-color: #0751df;
      color: #ffffff; }
  .theme-blue .performers-box .performers-info .player-position span,
  .theme-blue .performers-box .performers-info .player-height span,
  .theme-blue .performers-box .performers-info .player-weight span {
    color: #0751df; }
  .theme-blue .social-icon ul li a:hover {
    color: #0751df; }
  .theme-blue .inner-banner .inner-banner-tittle .breadcrumb .breadcrumb-item a:hover {
    color: #0751df; }
  .theme-blue .inner-banner .inner-banner-tittle .breadcrumb .breadcrumb-item.active {
    color: #0751df; }
  .theme-blue .progress .progress-bar {
    background-color: #0751df; }
  .theme-blue .counter .counter-number .counter-text span {
    color: #0751df; }
  .theme-blue .counter .counter-number p {
    color: #0751df; }
  .theme-blue .counter:hover:before {
    background-color: rgba(7, 81, 223, 0.3); }
  .theme-blue .time-location .time i,
  .theme-blue .time-location .location i {
    color: #0751df; }
  .theme-blue .classic-players .owl-carousel .item .player-info .player-number {
    background-color: #0751df;
    color: #ffffff; }
  .theme-blue .classic-players .owl-carousel .item .player-info .player-name .title a:hover {
    color: #0751df; }
  .theme-blue .team .player-info .player-number {
    background-color: #0751df;
    color: #ffffff; }
  .theme-blue .team .player-info .title a:hover {
    color: #0751df; }
  .theme-blue .player-single .player-info .player-position {
    color: #0751df; }
  .theme-blue .team-info .team .player-info .player-num {
    background-color: #0751df; }
  .theme-blue .team-info .team .player-info .player-name {
    color: #0751df; }
  .theme-blue .player-info .player-position {
    color: #0751df; }
  .theme-blue .players-details .player-detail .player-profile .rank {
    color: rgba(7, 81, 223, 0.1); }
  .theme-blue .history-info .history-info-inner .history-info-item .history-info-number .time .year {
    color: #0751df; }
  .theme-blue .history-info .history-info-inner .history-info-item .history-info-content .history-info-title a:hover {
    color: #0751df; }
  .theme-blue .upcoming-match-table .match-schedule:before {
    background-color: #0751df; }
  .theme-blue .events-box .events-details .events-date {
    background-color: #0751df;
    color: #ffffff; }
  .theme-blue .events-box .events-details .events-title a:hover {
    color: #0751df; }
  .theme-blue .events-box .events-details .events-meta {
    color: #0751df; }
  .theme-blue .single-event .single-event-details .events-title a:hover {
    color: #0751df; }
  .theme-blue .sidebar .widget {
    /*Recent Post*/
    /*sidebar-categories*/
    /*popular-tag*/ }
    .theme-blue .sidebar .widget .recent-post .post-info .post-title a:hover {
      color: #0751df; }
    .theme-blue .sidebar .widget .widget-categories ul.list-style li a:hover {
      color: #0751df; }
    .theme-blue .sidebar .widget .widget-categories .list-style-underline li a:hover {
      color: #0751df; }
    .theme-blue .sidebar .widget .popular-tag ul li a:hover {
      color: #0751df;
      border: 1px solid #0751df; }
  .theme-blue .ticket-box .ticket-details .ticket-info .ticket-price {
    color: #0751df; }
  .theme-blue .ticket-box .ticket-details .match-details .match-vs {
    color: #0751df; }
  .theme-blue .accordion .accordion-item .accordion-header .accordion-button:hover {
    color: #0751df; }
  .theme-blue .accordion .accordion-item .accordion-header .accordion-button:hover:after {
    color: #0751df; }
  .theme-blue .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    background-color: #0751df;
    color: #ffffff; }
    .theme-blue .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):after {
      color: #ffffff; }
  .theme-blue .checkout-review .accordion .card .accordion-icon.card-header button {
    color: #0751df; }
    .theme-blue .checkout-review .accordion .card .accordion-icon.card-header button:before {
      border: 2px solid #0751df; }
  .theme-blue .asked-questions .questions-title .number {
    background-color: #0751df;
    color: #ffffff; }
  .theme-blue .coming-soon-section .coming-soon-logo .logo-blue {
    display: block; }
  .theme-blue .coming-soon-section .coming-soon-logo .logo-yellow,
  .theme-blue .coming-soon-section .coming-soon-logo .logo {
    display: none; }
  .theme-blue .coming-soon-section .countdown-main .countdown span {
    color: #ffffff; }
  .theme-blue .coming-soon-section .countdown-main .countdown p {
    color: #0751df; }
  .theme-blue .contant-404 h1 {
    color: #ffffff; }
    .theme-blue .contant-404 h1 span {
      color: #0751df; }
  .theme-blue .list ul li i {
    color: #0751df; }
  .theme-blue ul.list li i {
    color: #0751df; }
  .theme-blue .portfolio .portfolio-info .portfolio-sub-title {
    color: #ffffff; }
    .theme-blue .portfolio .portfolio-info .portfolio-sub-title:hover {
      color: #0751df; }
  .theme-blue .portfolio .portfolio-info .portfolio-title {
    color: #ffffff; }
    .theme-blue .portfolio .portfolio-info .portfolio-title:hover {
      color: #0751df; }
  .theme-blue .portfolio .portfolio-info .btn {
    color: #0751df; }
    .theme-blue .portfolio .portfolio-info .btn:hover {
      color: #ffffff; }
  .theme-blue .portfolio-style-02 .portfolio-info-02 .popup-icon {
    background: #ffffff;
    color: #0751df; }
  .theme-blue .portfolio.portfolio-style-03 .portfolio-info .portfolio-title:hover {
    color: #0751df; }
  .theme-blue .filters-group button {
    background: #0751df;
    color: #ffffff; }
    .theme-blue .filters-group button:hover {
      background: #ffffff;
      color: #0751df; }
    .theme-blue .filters-group button:focus {
      color: #ffffff; }
    .theme-blue .filters-group button:active {
      color: #ffffff; }
  .theme-blue .filters-group button.active {
    background-color: #ffffff;
    color: #0751df; }
  .theme-blue .product .add-to-cart a {
    background: #0751df;
    color: #ffffff; }
  .theme-blue .product .product-description .product-title a {
    color: #ffffff; }
    .theme-blue .product .product-description .product-title a:hover {
      color: #0751df; }
  .theme-blue .product .product-description .product-price ins {
    color: #0751df; }
  .theme-blue .product .product-info .product-name a {
    color: #ffffff; }
    .theme-blue .product .product-info .product-name a:hover {
      color: #0751df; }
  .theme-blue .product .product-info .price ins {
    color: #0751df; }
  .theme-blue .shop-single .nav-tabs-02 .nav-item .nav-link {
    color: #ffffff; }
  .theme-blue .shop-single .nav-tabs-02 .nav-item .nav-link.active {
    color: #0751df; }
  .theme-blue .shop-single .nav-tabs-02 .tab-content .tab-pane p {
    color: #ffffff; }
  .theme-blue .shop-single .nav-tabs-02 .tab-content .tab-pane .list ul li i {
    color: #0751df; }
  .theme-blue .shop-single .product-detail .product-price-rating .product-prize p {
    color: #ffffff; }
    .theme-blue .shop-single .product-detail .product-price-rating .product-prize p span.price {
      color: rgba(255, 255, 255, 0.6); }
      .theme-blue .shop-single .product-detail .product-price-rating .product-prize p span.price ins {
        color: #0751df; }
  .theme-blue .shop-single .product-detail .product-price-rating .product-prize h4 span {
    color: #0751df; }
  .theme-blue .shop-single .product-detail .product-price-rating .product-rating i {
    color: #0751df; }
  .theme-blue .shop-single .product-detail .product-summary a {
    color: #0751df; }
    .theme-blue .shop-single .product-detail .product-summary a i {
      color: #0751df; }
    .theme-blue .shop-single .product-detail .product-summary a:hover {
      color: #ffffff; }
  .theme-blue .shop-single .product-detail .product-detail-meta span a {
    color: #0751df; }
    .theme-blue .shop-single .product-detail .product-detail-meta span a:hover {
      color: #ffffff; }
  .theme-blue .slider-slick .slider-nav .slick-next:before {
    color: #ffffff; }
  .theme-blue .slider-slick .slider-nav .slick-prev:before {
    color: #ffffff; }
  .theme-blue .slider-slick .slider-nav .slick-prev {
    background: #0751df; }
  .theme-blue .slider-slick .slider-nav .slick-next {
    background: #0751df; }
  .theme-blue .commentlist .comment-content .reviews .rating i {
    color: #0751df; }
  .theme-blue .checkout-review .table tr th {
    color: #ffffff; }
  .theme-blue .checkout-review .table tr td {
    color: #ffffff; }
  .theme-blue .checkout-review .table tr td.amount {
    font-size: 18px;
    color: #0751df; }
  .theme-blue .checkout-info > a {
    color: #0751df; }
  .theme-blue .cart-table .table tr th {
    color: #ffffff;
    background-color: #0751df; }
  .theme-blue .cart-table .table tr td a:hover {
    color: #0751df; }
  .theme-blue .cart-table .table tbody tr td.product-name a {
    color: #ffffff; }
    .theme-blue .cart-table .table tbody tr td.product-name a:hover {
      color: #0751df; }
  .theme-blue .cart-totals .checkout-button:hover {
    background-color: #12111a;
    color: #ffffff; }
  .theme-blue .gridlist-toggle-wrap .gridlist-toggle a:hover, .theme-blue .gridlist-toggle-wrap .gridlist-toggle a.active {
    background-color: #0751df; }
  .theme-blue .shop-sidebar .widget .social ul li a {
    color: #8d8c8c; }
    .theme-blue .shop-sidebar .widget .social ul li a:hover {
      color: #0751df; }
  .theme-blue .shop-sidebar .widget .price-filter a {
    color: #0751df; }
  .theme-blue .shop-sidebar .widget .tagcloud ul li a {
    color: #8d8c8c; }
    .theme-blue .shop-sidebar .widget .tagcloud ul li a:hover {
      background: #0751df;
      border: 1px solid #0751df;
      color: #ffffff; }
  .theme-blue .shop-sidebar .widget .widget-categories ul li a {
    color: #35343c; }
    .theme-blue .shop-sidebar .widget .widget-categories ul li a:hover {
      color: #0751df; }
  .theme-blue .irs--flat .irs-bar {
    background: #0751df; }
  .theme-blue .irs--flat .irs-from,
  .theme-blue .irs--flat .irs-to,
  .theme-blue .irs--flat .irs-single {
    background: transparent;
    color: #0751df; }
  .theme-blue .irs--flat .irs-handle > i:first-child,
  .theme-blue .irs--flat .irs-handle > i:first-child {
    background: #ffffff;
    border: 2px solid #0751df; }
  .theme-blue .irs--flat .irs-handle.state_hover > i:first-child,
  .theme-blue .irs--flat .irs-handle:hover > i:first-child {
    background: #0751df; }
  .theme-blue .pagination .page-item .page-link {
    color: #0751df;
    background: #f5f5f5;
    border-color: #f5f5f5; }
    .theme-blue .pagination .page-item .page-link:hover {
      background: #0751df;
      border-color: #0751df;
      color: #ffffff; }
    .theme-blue .pagination .page-item .page-link:focus {
      background: #0751df;
      border-color: #0751df;
      color: #ffffff; }
  .theme-blue .pagination .page-item.active .page-link {
    background: #0751df;
    border-color: #0751df;
    color: #ffffff; }
  .theme-blue .feature-item.feature-info-style-01 .feature-icon {
    background-color: #ffffff; }
    .theme-blue .feature-item.feature-info-style-01 .feature-icon object {
      filter: invert(21%) sepia(83%) saturate(2292%) hue-rotate(212deg) brightness(101%) contrast(110%); }
  .theme-blue .feature-item.feature-info-style-02 .feature-icon object {
    filter: invert(21%) sepia(83%) saturate(2292%) hue-rotate(212deg) brightness(101%) contrast(110%); }
  .theme-blue .back-to-top a {
    background: #0751df; }

@media (max-width: 1199px) {
  .theme-blue .header.header-style-02 .navbar .navbar-nav .dropdown-menu li.active .dropdown-item {
    color: #0751df; }
  .theme-blue header.header.header-sticky.is-sticky .navbar .navbar-nav .nav-item .nav-link:hover {
    color: #0751df; } }

/*****************************
    Blog
*****************************/
.blog-post {
  position: relative;
  cursor: pointer; }
  .blog-post .blog-image {
    position: relative; }
    .blog-post .blog-image:before {
      position: absolute;
      content: "";
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.6) 100%);
      left: 0;
      width: 100%;
      height: 75%;
      bottom: 0;
      z-index: 1;
      transition: all 0.3s ease; }
  .blog-post .blog-post-details {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    bottom: 20px;
    padding: 0px 30px; }
    .blog-post .blog-post-details .tag {
      background-color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      color: #d41640;
      padding: 5px 10px;
      display: inline-flex; }
    .blog-post .blog-post-details h5 {
      margin-top: 15px;
      color: #ffffff;
      font-weight: normal;
      margin-bottom: 0px;
      font-size: 24px;
      line-height: 28px; }
      .blog-post .blog-post-details h5 a:hover {
        color: #d41640; }
    .blog-post .blog-post-details .blog-post-meta {
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
      .blog-post .blog-post-details .blog-post-meta .blog-post-author {
        margin-right: 20px;
        margin-bottom: 10px; }
        .blog-post .blog-post-details .blog-post-meta .blog-post-author a {
          font-size: 16px;
          color: #ffffff; }
          .blog-post .blog-post-details .blog-post-meta .blog-post-author a img {
            height: 30px;
            border-radius: 50%;
            margin: 0px;
            width: auto;
            display: inline-block;
            margin-right: 10px;
            transform: inherit; }
      .blog-post .blog-post-details .blog-post-meta .blog-post-time {
        margin-bottom: 10px;
        margin-right: 20px; }
        .blog-post .blog-post-details .blog-post-meta .blog-post-time a {
          color: #ffffff; }
        .blog-post .blog-post-details .blog-post-meta .blog-post-time i {
          margin-right: 10px; }
      .blog-post .blog-post-details .blog-post-meta .blog-post-comments {
        margin-bottom: 10px; }
    .blog-post .blog-post-details .blog-link {
      color: #d41640;
      display: flex;
      align-items: center; }
      .blog-post .blog-post-details .blog-link:hover {
        color: #ffffff; }

/*Blog Style 01*/
.blog-post.post-style-01 .blog-image {
  overflow: hidden; }
  .blog-post.post-style-01 .blog-image img {
    transition: all 0.5s ease;
    transform: scale(1); }
  .blog-post.post-style-01 .blog-image .blog-post-date {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: #d41640;
    z-index: 3;
    color: #ffffff;
    text-align: center;
    width: 80px; }
    .blog-post.post-style-01 .blog-image .blog-post-date span {
      display: block; }
    .blog-post.post-style-01 .blog-image .blog-post-date .date {
      margin-top: 8px;
      font-size: 26px;
      font-weight: bold; }
    .blog-post.post-style-01 .blog-image .blog-post-date .month {
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 10px; }
  .blog-post.post-style-01 .blog-image .blog-post-meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 2; }
    .blog-post.post-style-01 .blog-image .blog-post-meta .blog-post-author {
      margin-right: 20px;
      margin-bottom: 10px; }
      .blog-post.post-style-01 .blog-image .blog-post-meta .blog-post-author a {
        font-size: 16px;
        color: #ffffff; }
        .blog-post.post-style-01 .blog-image .blog-post-meta .blog-post-author a img {
          height: 30px;
          border-radius: 50%;
          margin: 0px;
          width: auto;
          display: inline-block;
          margin-right: 10px;
          transform: inherit; }
    .blog-post.post-style-01 .blog-image .blog-post-meta .blog-post-time {
      margin-bottom: 10px;
      margin-right: 20px; }
      .blog-post.post-style-01 .blog-image .blog-post-meta .blog-post-time a {
        color: #ffffff; }
      .blog-post.post-style-01 .blog-image .blog-post-meta .blog-post-time i {
        margin-right: 10px; }
    .blog-post.post-style-01 .blog-image .blog-post-meta .blog-post-comments {
      margin-bottom: 10px; }

.blog-post.post-style-01 .blog-post-details {
  position: inherit;
  left: inherit;
  right: inherit;
  width: 100%;
  z-index: 2;
  bottom: inherit;
  padding: 0px;
  padding-top: 20px; }
  .blog-post.post-style-01 .blog-post-details .blog-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
    margin-top: 10px; }
    .blog-post.post-style-01 .blog-post-details .blog-title a:hover {
      color: #d41640; }
    .blog-post.post-style-01 .blog-post-details .blog-title:hover {
      color: #d41640; }
  .blog-post.post-style-01 .blog-post-details .blog-link {
    display: flex;
    align-items: center; }
    .blog-post.post-style-01 .blog-post-details .blog-link:hover {
      color: #ffffff; }

.blog-post.post-style-01:hover .blog-image img {
  transform: scale(1.1); }

/*Blog Style 02*/
.blog-post.post-style-02 .blog-image {
  overflow: hidden; }
  .blog-post.post-style-02 .blog-image img {
    transform: scale(1);
    transition: all 0.5s ease; }
  .blog-post.post-style-02 .blog-image .blog-post-date {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: #d41640;
    z-index: 3;
    color: #ffffff;
    text-align: center;
    width: 80px; }
    .blog-post.post-style-02 .blog-image .blog-post-date span {
      display: block; }
    .blog-post.post-style-02 .blog-image .blog-post-date .date {
      margin-top: 8px;
      font-size: 26px;
      font-weight: bold; }
    .blog-post.post-style-02 .blog-image .blog-post-date .month {
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 10px; }

.blog-post.post-style-02 .blog-post-details {
  position: inherit;
  left: inherit;
  right: inherit;
  width: 100%;
  z-index: 2;
  bottom: inherit;
  padding: 0px;
  padding-top: 20px; }
  .blog-post.post-style-02 .blog-post-details .tag {
    background-color: #ffffff;
    font-size: 14px;
    color: #d41640;
    padding: 5px 10px;
    display: inline-block; }
  .blog-post.post-style-02 .blog-post-details h5 {
    margin-top: 0px;
    color: #ffffff;
    font-weight: normal;
    margin-bottom: 0px;
    font-size: 24px;
    line-height: 28px; }
    .blog-post.post-style-02 .blog-post-details h5 a:hover {
      color: #d41640; }
  .blog-post.post-style-02 .blog-post-details .blog-post-meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px; }
    .blog-post.post-style-02 .blog-post-details .blog-post-meta .blog-post-author {
      margin-right: 20px;
      margin-bottom: 10px; }
      .blog-post.post-style-02 .blog-post-details .blog-post-meta .blog-post-author a {
        font-size: 16px;
        color: #ffffff; }
        .blog-post.post-style-02 .blog-post-details .blog-post-meta .blog-post-author a img {
          height: 30px;
          border-radius: 50%;
          margin: 0px;
          width: auto;
          display: inline-block;
          margin-right: 10px;
          transform: inherit; }

.blog-post.post-style-02:hover .blog-image img {
  transform: scale(1.1); }

.blog-post.post-style-03 .blog-image {
  overflow: hidden; }
  .blog-post.post-style-03 .blog-image img {
    transform: scale(1);
    transition: all 0.5s ease; }

.blog-post.post-style-03:hover .blog-image img {
  transform: scale(1.1); }

.latest-news-pattern {
  position: relative;
  z-index: 2; }
  .latest-news-pattern:before {
    background-image: url(../images/home-01/pattern-06.png);
    content: "";
    position: absolute;
    height: 70px;
    width: 70px;
    left: 110px;
    top: 200px;
    background-repeat: no-repeat;
    z-index: -1;
    backface-visibility: hidden;
    animation: jumping4 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 8s ease 4s;
    user-select: none; }
  .latest-news-pattern:after {
    background-image: url(../images/home-01/pattern-09.png);
    content: "";
    position: absolute;
    height: 305px;
    width: 140px;
    right: 0;
    top: 50px;
    background-repeat: no-repeat;
    z-index: -1;
    -webkit-animation: upmover 1.5s infinite  alternate;
    animation: upmover 1.5s infinite  alternate; }

/*Featured News*/
.featured-news {
  position: relative;
  z-index: 2; }
  .featured-news:before {
    background-image: url(../images/home-02/pattern-04.png);
    content: "";
    position: absolute;
    height: 327px;
    width: 101px;
    left: 110px;
    top: 50%;
    z-index: -1;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    -webkit-animation: upmover 1.5s infinite  alternate;
    animation: upmover 1.5s infinite  alternate; }
  .featured-news:after {
    background-image: url(../images/home-02/pattern-05.png);
    content: "";
    position: absolute;
    height: 70px;
    width: 70px;
    right: 115px;
    top: 206px;
    z-index: -1;
    background-repeat: no-repeat;
    backface-visibility: hidden;
    animation: jumping3 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 7s ease 2s;
    user-select: none; }
  .featured-news .pattern-6 {
    bottom: 0;
    right: 0;
    z-index: -1; }
  .featured-news .blog-post.post-style-03 .blog-post-details .blog-title {
    width: 470px; }
  .featured-news .blog-post.post-style-02 .blog-post-details .blog-title {
    width: 270px; }

/*Blog*/
.video-bg-section .video-image {
  position: relative; }
  .video-bg-section .video-image:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3); }
  .video-bg-section .video-image .video-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    margin-bottom: 40px;
    display: flex;
    flex: 0 0 90px;
    align-items: center;
    justify-content: center;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background-color: #ffffff;
    color: #d41640;
    transition: all 0.3s ease-in-out;
    z-index: 9; }
    .video-bg-section .video-image .video-btn i {
      font-size: 30px; }
    .video-bg-section .video-image .video-btn:hover {
      background-color: #d41640;
      color: #ffffff; }

.blog-post-social {
  display: flex; }
  .blog-post-social span {
    color: #d41640;
    padding-right: 15px; }
  .blog-post-social ul {
    display: flex;
    margin-bottom: 0; }
    .blog-post-social ul li {
      padding: 0 8px; }
      .blog-post-social ul li a {
        color: #ffffff; }
        .blog-post-social ul li a:hover {
          color: #d41640; }

.blog-post-navigation .post-navigation .nav-link {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 25px 0;
  margin-top: 50px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  .blog-post-navigation .post-navigation .nav-link a i {
    position: absolute;
    font-size: 18px;
    color: #ffffff;
    top: 50%;
    transform: translateY(-50%); }
  .blog-post-navigation .post-navigation .nav-link a span {
    display: block;
    color: #ffffff;
    transition: all 0.5s ease-in-out;
    font-weight: 500; }
  .blog-post-navigation .post-navigation .nav-link a .nav-title {
    font-size: 18px; }
  .blog-post-navigation .post-navigation .nav-link div {
    width: 50%; }
  .blog-post-navigation .post-navigation .nav-link .nav-previous a {
    position: relative;
    display: block;
    padding-left: 80px;
    padding-top: 11px;
    padding-bottom: 11px; }
    .blog-post-navigation .post-navigation .nav-link .nav-previous a i {
      left: 25px; }
    .blog-post-navigation .post-navigation .nav-link .nav-previous a:before {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 60px;
      height: 60px;
      background-color: #d41640;
      transition: all 0.5s ease-in-out; }
    .blog-post-navigation .post-navigation .nav-link .nav-previous a:hover i {
      color: #d41640; }
    .blog-post-navigation .post-navigation .nav-link .nav-previous a:hover span {
      color: #d41640; }
    .blog-post-navigation .post-navigation .nav-link .nav-previous a:hover:before {
      background-color: #ffffff; }
  .blog-post-navigation .post-navigation .nav-link .nav-next {
    text-align: right;
    margin-left: auto; }
    .blog-post-navigation .post-navigation .nav-link .nav-next a {
      position: relative;
      display: block;
      padding-right: 80px;
      padding-top: 11px;
      padding-bottom: 11px; }
      .blog-post-navigation .post-navigation .nav-link .nav-next a i {
        right: 25px; }
      .blog-post-navigation .post-navigation .nav-link .nav-next a:before {
        position: absolute;
        content: "";
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 60px;
        height: 60px;
        background-color: #d41640;
        transition: all 0.5s ease-in-out; }
      .blog-post-navigation .post-navigation .nav-link .nav-next a:hover i {
        color: #d41640; }
      .blog-post-navigation .post-navigation .nav-link .nav-next a:hover span {
        color: #d41640; }
      .blog-post-navigation .post-navigation .nav-link .nav-next a:hover:before {
        background-color: #ffffff; }

/*Blog Detail*/
.blog-detail .quote-yellow,
.blog-detail .quote-blue {
  display: none; }

.blog-detail .comments-01 {
  display: flex; }
  .blog-detail .comments-01 .comment-author {
    flex: 0 0 90px; }
    .blog-detail .comments-01 .comment-author img {
      width: 100%; }
  .blog-detail .comments-01 .comment-content {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 30px;
    margin-left: 20px;
    margin-bottom: 30px;
    width: 100%; }
    .blog-detail .comments-01 .comment-content .reviews {
      display: flex;
      margin-bottom: 10px;
      align-items: center; }
      .blog-detail .comments-01 .comment-content .reviews .meta {
        font-size: 20px; }
        .blog-detail .comments-01 .comment-content .reviews .meta strong {
          color: #ffffff; }
        .blog-detail .comments-01 .comment-content .reviews .meta span {
          font-weight: 500;
          font-size: 14px;
          color: #ffffff; }
      .blog-detail .comments-01 .comment-content .reviews p {
        margin-bottom: 0; }
      .blog-detail .comments-01 .comment-content .reviews .port-social {
        margin-left: auto;
        border-radius: 0;
        color: #d41640;
        padding: 5px 15px;
        display: block; }
        .blog-detail .comments-01 .comment-content .reviews .port-social:hover {
          color: #ffffff; }

.blog-detail .comments-02 {
  display: flex;
  padding-left: 110px; }
  .blog-detail .comments-02 .comment-author {
    flex: 0 0 90px; }
    .blog-detail .comments-02 .comment-author img {
      width: 100%;
      border-radius: 0; }
  .blog-detail .comments-02 .comment-content {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 30px;
    margin-left: 20px;
    margin-bottom: 30px;
    width: 100%; }
    .blog-detail .comments-02 .comment-content .reviews {
      display: flex;
      margin-bottom: 10px;
      align-items: center; }
      .blog-detail .comments-02 .comment-content .reviews .meta {
        font-size: 20px; }
        .blog-detail .comments-02 .comment-content .reviews .meta strong {
          color: #ffffff; }
        .blog-detail .comments-02 .comment-content .reviews .meta span {
          font-weight: 500;
          font-size: 14px;
          color: #ffffff; }
      .blog-detail .comments-02 .comment-content .reviews p {
        margin-bottom: 0; }
      .blog-detail .comments-02 .comment-content .reviews .port-social {
        margin-left: auto;
        border-radius: 0;
        color: #d41640;
        padding: 5px 15px;
        display: block; }
        .blog-detail .comments-02 .comment-content .reviews .port-social:hover {
          color: #ffffff; }

.blog-detail .leave-reply-form form .form-control {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff; }

@media (max-width: 991px) {
  .latest-news .my-shuffle-container.columns-3 .grid-item {
    width: 50%; } }

@media (max-width: 767px) {
  .featured-news:before {
    background-size: cover;
    height: 130px;
    width: 40px;
    left: 30px;
    top: 130px; }
  .featured-news:after {
    background-size: cover;
    height: 50px;
    width: 50px;
    right: 30px; }
  .featured-news .pattern-6 {
    max-width: 10%; }
  .latest-news .my-shuffle-container.columns-3 .grid-item {
    width: 100%; } }

@media (max-width: 575px) {
  .featured-news:before, .featured-news:after {
    content: none; }
  .featured-news .pattern-6 {
    display: none; }
  .featured-news .blog-post.post-style-03 .blog-post-details .blog-title {
    width: auto; }
  .featured-news .blog-post.post-style-02 .blog-post-details .blog-title {
    width: auto; }
  .blog-detail .video-bg-section .video-image {
    min-height: auto; }
  .blog-detail .comments-01 .comment-content {
    padding: 15px; }
  .blog-detail .comments-02 {
    padding-left: 10px; }
    .blog-detail .comments-02 .comment-content {
      padding: 15px; } }

@media (max-width: 479px) {
  .blog-post .blog-post-details {
    padding: 0px 20px; }
    .blog-post .blog-post-details h5 {
      font-size: 20px; } }

/*****************************
  Shop
*****************************/
/* Product */
.product .add-to-cart {
  padding-top: 20px; }
  .product .add-to-cart a {
    background: #d41640;
    color: #ffffff;
    text-transform: capitalize;
    padding: 10px 20px;
    border-radius: 6px;
    display: inline-block; }
    .product .add-to-cart a:hover {
      background: #ffffff; }

.product .product-description .product-title {
  margin: 20px 0px 10px; }
  .product .product-description .product-title a {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff; }
    .product .product-description .product-title a:hover {
      color: #d41640; }

.product .product-description .product-rating {
  margin-bottom: 10px; }

.product .product-description .product-price {
  font-size: 14px;
  font-weight: bold; }
  .product .product-description .product-price ins {
    color: #d41640;
    text-decoration: none; }

/* Product Detail */
.shop-single .nav-tabs-02 {
  border: inherit; }
  .shop-single .nav-tabs-02 .nav-tabs {
    border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
    padding: 20px 0 10px 0; }
  .shop-single .nav-tabs-02 .nav-item {
    margin: 0 5px 10px; }
    .shop-single .nav-tabs-02 .nav-item .nav-link {
      font-size: 18px;
      font-weight: 500;
      border-radius: 0;
      border: inherit;
      color: #ffffff; }
    .shop-single .nav-tabs-02 .nav-item .nav-link.active {
      border: inherit;
      color: #d41640;
      background-color: inherit; }
  .shop-single .nav-tabs-02 .tab-content .tab-pane p {
    color: #ffffff; }
  .shop-single .nav-tabs-02 .tab-content .tab-pane .list ul li {
    color: #ffffff; }
    .shop-single .nav-tabs-02 .tab-content .tab-pane .list ul li i {
      color: #d41640; }
  .shop-single .nav-tabs-02 .tab-content .tab-pane form .form-label {
    color: #ffffff; }
  .shop-single .nav-tabs-02 .tab-content .tab-pane form .form-control {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff; }

.shop-single .product-detail .product-price-rating .product-prize {
  margin-bottom: 20px; }
  .shop-single .product-detail .product-price-rating .product-prize p {
    font-size: 28px;
    color: #ffffff;
    font-weight: 500; }
    .shop-single .product-detail .product-price-rating .product-prize p span.price {
      color: rgba(255, 255, 255, 0.6); }
      .shop-single .product-detail .product-price-rating .product-prize p span.price del {
        margin-right: 8px; }
      .shop-single .product-detail .product-price-rating .product-prize p span.price ins {
        color: #d41640;
        text-decoration: none; }
  .shop-single .product-detail .product-price-rating .product-prize h4 {
    font-size: 20px;
    margin-bottom: 0; }
    .shop-single .product-detail .product-price-rating .product-prize h4 span {
      font-size: 24px;
      color: #d41640;
      margin-left: 8px; }

.shop-single .product-detail .product-price-rating .product-rating {
  margin: 20px 0; }
  .shop-single .product-detail .product-price-rating .product-rating i {
    color: #d41640; }

.shop-single .product-detail .add-to-cart-input .form-control {
  height: 54px; }

.shop-single .product-detail .add-to-cart-input .btn {
  text-transform: capitalize; }

.shop-single .product-detail .product-summary a {
  margin-right: 10px;
  color: #d41640;
  border: 1px solid #ffffff, 0.2;
  border-radius: 0px;
  height: 50px;
  padding: 17px 19px;
  text-align: center;
  justify-content: center;
  display: inline-flex; }
  .shop-single .product-detail .product-summary a i {
    color: #d41640;
    margin-right: 0px; }
  .shop-single .product-detail .product-summary a:hover {
    color: #ffffff; }

.shop-single .product-detail hr {
  margin: 30px 0; }

.shop-single .product-detail .product-detail-meta {
  margin-bottom: 20px; }
  .shop-single .product-detail .product-detail-meta span {
    display: block;
    margin: 10px 0; }
    .shop-single .product-detail .product-detail-meta span a {
      color: #d41640; }
      .shop-single .product-detail .product-detail-meta span a:hover {
        color: #ffffff; }

.shop-single .product-detail .input-group {
  width: 90px;
  float: left;
  margin-right: 20px; }

.slider-slick .slider-nav .slick-next:before {
  content: "\f178";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  z-index: 999;
  color: #ffffff;
  font-size: 20px; }

.slider-slick .slider-nav .slick-prev:before {
  content: "\f177";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  z-index: 999;
  color: #ffffff;
  font-size: 20px; }

.slider-slick .slider-nav:hover .slick-prev {
  left: 0;
  z-index: 99;
  opacity: 1;
  border-radius: 0; }

.slider-slick .slider-nav:hover .slick-next {
  right: 0;
  opacity: 1;
  border-radius: 0; }

.slider-slick .slider-nav .slick-prev {
  opacity: 0;
  width: 50px;
  height: 50px;
  background: #d41640;
  transition: all 0.3s ease; }

.slider-slick .slider-nav .slick-next {
  opacity: 0;
  width: 50px;
  height: 50px;
  background: #d41640;
  transition: all 0.3s ease; }

.slider-slick .slider-nav .slick-track .slick-slide {
  margin-left: 5px;
  margin-right: 5px; }

/*commentlist*/
.commentlist {
  display: flex; }
  .commentlist .comment-author {
    width: 70px;
    flex: 0 0 70px; }
    .commentlist .comment-author img {
      border-radius: 100%; }
  .commentlist .comment-content {
    padding: 15px;
    margin-left: 30px;
    margin-bottom: 20px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.2); }
    .commentlist .comment-content .reviews {
      display: flex;
      flex-wrap: wrap; }
      .commentlist .comment-content .reviews .rating {
        margin-left: auto; }
        .commentlist .comment-content .reviews .rating i {
          color: #d41640; }

.form-check-label {
  padding-left: 0px; }

.search-field {
  padding-left: 20px;
  box-shadow: none;
  border: none;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #ededed;
  width: 100%;
  background: #ffffff; }

/* Checkout */
.checkout.checkout-form {
  background-color: rgba(53, 52, 60, 0.3);
  padding: 30px; }
  .checkout.checkout-form form .form-control.basic-select {
    color: #8d8c8c; }
  .checkout.checkout-form form .form-label {
    color: #ffffff; }
  .checkout.checkout-form form .form-control {
    height: 60px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff; }
    .checkout.checkout-form form .form-control::placeholder {
      color: #ffffff; }
  .checkout.checkout-form form textarea.form-control {
    height: auto; }
  .checkout.checkout-form form .select2-container--default .select2-selection--single {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1); }
    .checkout.checkout-form form .select2-container--default .select2-selection--single .select2-selection__rendered {
      font-size: 14px;
      font-weight: 500; }
    .checkout.checkout-form form .select2-container--default .select2-selection--single .select2-selection__arrow {
      top: 18px; }

.checkout-info {
  padding: 25px 25px 25px 25px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(53, 52, 60, 0.3); }
  .checkout-info > a {
    color: #d41640; }
  .checkout-info .returning-icon {
    flex: 0 0 50px;
    margin-right: 20px; }

.checkout-info-returning .checkout-coupon {
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0px; }
  .checkout-info-returning .checkout-coupon form .form-control {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff; }
    .checkout-info-returning .checkout-coupon form .form-control::placeholder {
      color: #ffffff; }

.checkout-info-coupon .checkout-info {
  display: flex;
  align-items: center; }
  .checkout-info-coupon .checkout-info .coupon-icon {
    flex: 0 0 50px;
    margin-right: 20px; }

.checkout-info-coupon .checkout-coupon {
  padding: 20px;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  border-radius: 0; }
  .checkout-info-coupon .checkout-coupon form .form-control {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff; }
    .checkout-info-coupon .checkout-coupon form .form-control::placeholder {
      color: #ffffff; }

.checkout-review {
  padding: 15px 30px 30px;
  background-color: rgba(53, 52, 60, 0.3); }
  .checkout-review .table tr {
    border-color: rgba(255, 255, 255, 0.1); }
    .checkout-review .table tr th {
      padding: 15px 0;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
    .checkout-review .table tr th.shipping {
      font-size: 14px; }
    .checkout-review .table tr td {
      text-align: left;
      padding: 15px 0;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
      .checkout-review .table tr td .form-check .form-check-input {
        border-radius: 50%; }
    .checkout-review .table tr td.amount {
      font-size: 18px;
      color: #d41640; }
  .checkout-review .table tbody {
    border-bottom: 2px solid rgba(255, 255, 255, 0.1); }
  .checkout-review .table .tfoot {
    border-color: rgba(255, 255, 255, 0.2); }
  .checkout-review .form-check-input:focus {
    box-shadow: none; }

/* Cart */
.cart-table {
  background-color: rgba(53, 52, 60, 0.3); }
  .cart-table .table {
    margin-bottom: 0;
    font-size: 14px; }
    .cart-table .table tr th {
      border: none;
      padding: 15px 10px;
      color: #ffffff;
      background-color: #d41640;
      text-align: left;
      vertical-align: middle;
      border-radius: inherit !important; }
    .cart-table .table tr td {
      border: none;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      padding: 15px 10px;
      font-weight: 500;
      text-align: left;
      vertical-align: middle; }
      .cart-table .table tr td.product-remove {
        padding: 15px 25px;
        text-align: center; }
        .cart-table .table tr td.product-remove a {
          color: #ff0000; }
      .cart-table .table tr td.product-quantity .form-control {
        width: 70px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        height: 50px;
        background-color: inherit;
        color: #ffffff; }
      .cart-table .table tr td a:hover {
        color: #d41640; }
    .cart-table .table thead tr {
      border: none; }
      .cart-table .table thead tr th:last-child {
        border-radius: 6px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; }
      .cart-table .table thead tr th:first-child {
        border-radius: 6px;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .cart-table .table tbody {
      border-color: rgba(255, 255, 255, 0.2); }
      .cart-table .table tbody tr td.product-name a {
        color: #ffffff; }
        .cart-table .table tbody tr td.product-name a:hover {
          color: #d41640; }
  .cart-table .actions {
    display: flex;
    border-top: none;
    padding: 15px 10px;
    align-items: center; }
    .cart-table .actions .coupon form {
      display: flex; }
    .cart-table .actions .coupon .input-text {
      border-radius: 0px;
      height: 60px;
      padding: 10px 20px;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-size: 14px;
      font-weight: 500;
      margin-right: 10px;
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.1);
      color: #ffffff; }
    .cart-table .actions .coupon .btn {
      padding: 12px 20px; }
    .cart-table .actions .update-cart {
      margin-left: auto; }

.cart-table .table td.product-thumbnail img {
  width: 70px;
  height: auto; }

.cart-totals {
  padding: 20px;
  background-color: rgba(53, 52, 60, 0.3); }
  .cart-totals .cart-totals-title {
    margin-bottom: 20px;
    text-align: center;
    color: #ffffff; }
  .cart-totals .table {
    font-size: 14px; }
    .cart-totals .table > tbody {
      border-color: rgba(255, 255, 255, 0.1); }
    .cart-totals .table tr th {
      text-align: left;
      border: none;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      vertical-align: top;
      background: transparent;
      color: #8d8c8c; }
    .cart-totals .table tr td {
      text-align: right;
      border: none;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      background: transparent;
      color: #ffffff; }
      .cart-totals .table tr td .form-check .form-check-input {
        border-radius: 50%; }
    .cart-totals .table tr.shipping .form-check-label {
      padding-left: 0px; }
    .cart-totals .table tr.shipping p {
      margin-bottom: 0; }
    .cart-totals .table tr.order-total th {
      font-size: 18px;
      font-weight: 600;
      color: #8d8c8c; }
    .cart-totals .table tr.order-total td {
      font-size: 26px;
      font-weight: 600;
      color: #ffffff; }
    .cart-totals .table .cart-subtotal th {
      font-size: 18px;
      font-weight: 600;
      border: none; }
    .cart-totals .table .cart-subtotal td {
      font-size: 18px;
      font-weight: 600;
      border: none; }
  .cart-totals .checkout-button {
    width: 100%;
    text-align: center;
    padding: 15px 25px;
    display: block; }
    .cart-totals .checkout-button:hover {
      background-color: #ffffff;
      color: #000000; }

/* Shop Category */
.shop-category {
  padding: 25px 15px; }
  .shop-category h5 {
    margin-bottom: 20px; }
  .shop-category ul {
    margin-bottom: 0; }
    .shop-category ul li a {
      padding: 5px 0;
      display: block; }

.gridlist-toggle-wrap .gridlist-toggle .grid-2-column {
  background-image: url(../images/shop/grid-2-icon.png); }

.gridlist-toggle-wrap .gridlist-toggle .gridlist-button {
  display: inline-block;
  background-color: #ffffff;
  width: 19px;
  height: 19px;
  color: #ffffff;
  font-size: 0;
  line-height: normal !important;
  padding: 0;
  margin-left: 8px;
  text-align: center;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s; }

.gridlist-toggle-wrap .gridlist-toggle .grid-3-column {
  background-image: url(../images/shop/grid-3-icon.png); }

.gridlist-toggle-wrap .gridlist-toggle .grid-4-column {
  background-image: url(../images/shop/grid-4-icon.png); }

.gridlist-toggle-wrap .gridlist-toggle .gridlist-toggle-list {
  background-image: url(../images/shop/list-icon.png); }

.gridlist-toggle-wrap .gridlist-toggle a:hover, .gridlist-toggle-wrap .gridlist-toggle a.active {
  background-color: #d41640; }

.shop-single form .form-control {
  border: 1px solid #ededed; }

/* Shop Sidebar */
.offcanvas-header .title {
  color: #12111a; }

.shop-sidebar {
  padding: 30px 20px; }
  .shop-sidebar .widget {
    margin-bottom: 50px; }
    .shop-sidebar .widget .widget-title {
      margin-bottom: 30px; }
      .shop-sidebar .widget .widget-title .title {
        color: #12111a; }
    .shop-sidebar .widget .price-filter {
      display: flex; }
      .shop-sidebar .widget .price-filter a {
        margin-left: auto; }
    .shop-sidebar .widget .form-check {
      display: block;
      margin-bottom: 0px;
      padding-left: 25px; }
      .shop-sidebar .widget .form-check span {
        float: right; }
    .shop-sidebar .widget .social ul li {
      display: inline-block;
      list-style-type: none;
      margin-right: 20px;
      font-size: 16px; }
      .shop-sidebar .widget .social ul li a {
        color: #8d8c8c; }
        .shop-sidebar .widget .social ul li a:hover {
          color: #d41640; }
    .shop-sidebar .widget .tagcloud ul li {
      margin-bottom: 10px;
      display: inline-block; }
      .shop-sidebar .widget .tagcloud ul li a {
        color: #8d8c8c;
        line-height: 32px;
        border: 1px solid #ededed;
        padding: 0 12px;
        border-radius: 0;
        transition: all 0.3s ease-in-out;
        display: block; }
        .shop-sidebar .widget .tagcloud ul li a:hover {
          background: #d41640;
          border: 1px solid #d41640;
          color: #ffffff; }
    .shop-sidebar .widget .widget-categories ul li a {
      font-size: 14px;
      color: #35343c;
      line-height: 32px;
      display: block; }
      .shop-sidebar .widget .widget-categories ul li a:hover {
        color: #d41640; }

.irs--flat .irs-line {
  height: 6px; }

.irs--flat .irs-bar {
  background: #d41640;
  height: 6px; }

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  background: transparent;
  color: #d41640;
  font-size: 13px;
  font-weight: 600; }

.irs--flat .irs-handle > i:first-child,
.irs--flat .irs-handle > i:first-child {
  background: #ffffff;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #d41640;
  cursor: pointer;
  transition: all 0.3s ease-in-out; }

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  display: none; }

.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
  background: #d41640; }

.slider-slick .slider-for .slick-prev,
.slider-slick .slider-for .slick-next {
  display: none !important; }

@media (max-width: 1200px) {
  .cart-table .actions .coupon .btn {
    font-size: 14px; }
  .cart-table .actions .update-cart .btn {
    font-size: 14px; } }

@media (max-width: 767px) {
  .slider-slick .slider-nav .slick-prev {
    display: none !important; }
  .slider-slick .slider-nav .slick-next {
    display: none !important; }
  .shop-single .nav-tabs-02 .nav-item .nav-link {
    font-size: 16px;
    padding: 8px 10px; }
  .cart-table .actions {
    display: block; }
  .commentlist .comment-content .reviews p {
    margin-bottom: 5px; }
  .commentlist .comment-content .reviews .rating {
    margin-left: 0;
    margin-bottom: 10px; } }

@media (max-width: 575px) {
  .cart-table .actions {
    display: block; }
    .cart-table .actions .coupon #coupon_code {
      margin-bottom: 10px; }
    .cart-table .actions .coupon .btn {
      margin-bottom: 10px; }
  .shop-single .add-to-cart-input .product-summary,
  .shop-single .add-to-cart-input .qty {
    margin-bottom: 10px; }
  .shop-single .add-to-cart-input .btn {
    padding: 15px 20px;
    margin-bottom: 10px; }
  .sidebar .cart-totals {
    padding: 25px; } }

@media (max-width: 479px) {
  .cart-table .actions .coupon form {
    display: block; }
    .cart-table .actions .coupon form .input-text {
      width: 100%; }
    .cart-table .actions .coupon form .btn {
      margin-bottom: 0px; } }

/*****************************
    Footer
*****************************/
.footer {
  background-image: url(../images/home-01/footer-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat; }
  .footer .min-footer {
    padding: 60px 0; }
    .footer .min-footer .footer-logo {
      display: inline-block; }
      .footer .min-footer .footer-logo img {
        height: 30px; }
      .footer .min-footer .footer-logo .logo-yellow,
      .footer .min-footer .footer-logo .logo-blue {
        display: none; }
    .footer .min-footer .footer-title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px; }
    .footer .min-footer .footer-menu ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .footer .min-footer .footer-menu ul li {
        margin-bottom: 20px; }
        .footer .min-footer .footer-menu ul li a {
          display: block;
          color: #ffffff; }
          .footer .min-footer .footer-menu ul li a:hover {
            color: #d41640; }
        .footer .min-footer .footer-menu ul li:last-child {
          margin-bottom: 0; }
    .footer .min-footer .newsletter-form {
      position: relative;
      padding-right: 120px; }
      .footer .min-footer .newsletter-form .form-control:focus {
        border-color: #d41640; }
      .footer .min-footer .newsletter-form .btn {
        padding: 18px 15px 15px;
        font-size: 18px;
        position: absolute;
        right: 0;
        top: 0; }
        .footer .min-footer .newsletter-form .btn:hover {
          transform: inherit; }
    .footer .min-footer .footer-social {
      display: flex;
      align-items: center; }
      .footer .min-footer .footer-social span {
        font-weight: 400;
        font-size: 24px;
        color: #ffffff; }
      .footer .min-footer .footer-social ul {
        padding-left: 15px;
        margin-bottom: 0; }
        .footer .min-footer .footer-social ul li {
          display: inline-block;
          list-style-type: none;
          float: left;
          margin-right: 20px;
          font-size: 20px; }
          .footer .min-footer .footer-social ul li a {
            color: #ffffff; }
            .footer .min-footer .footer-social ul li a:hover {
              color: #d41640; }
    .footer .min-footer .download-app > a + a {
      margin-left: 10px; }
    .footer .min-footer .contact-info {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .footer .min-footer .contact-info li {
        display: flex;
        margin-bottom: 20px; }
        .footer .min-footer .contact-info li i {
          color: #d41640;
          width: 20px;
          flex: 0 0 20px; }
        .footer .min-footer .contact-info li span {
          padding-left: 8px;
          color: #ffffff; }
        .footer .min-footer .contact-info li:last-child {
          margin-bottom: 0px; }
  .footer .footer-bottom {
    padding: 30px 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
    .footer .footer-bottom .copyright {
      color: #ffffff; }
      .footer .footer-bottom .copyright a {
        color: #ffffff; }
        .footer .footer-bottom .copyright a:hover {
          color: #d41640; }
      .footer .footer-bottom .copyright .copyright-menu.footer-menu ul {
        display: flex;
        align-items: center;
        padding: 0px;
        margin: 0px; }
        .footer .footer-bottom .copyright .copyright-menu.footer-menu ul li {
          margin-right: 25px;
          margin-bottom: 0; }
          .footer .footer-bottom .copyright .copyright-menu.footer-menu ul li:last-child {
            margin-right: 0; }

/*Footer style 02*/
.footer.footer-style-02 {
  background-color: #12111a;
  background-image: none; }
  .footer.footer-style-02 .footer-menu ul li {
    margin-bottom: 8px; }
  .footer.footer-style-02 .footer-bottom {
    padding: 20px 0px; }
    .footer.footer-style-02 .footer-bottom .footer-logo img {
      height: 20px; }
    .footer.footer-style-02 .footer-bottom .footer-logo .logo-yellow,
    .footer.footer-style-02 .footer-bottom .footer-logo .logo-blue {
      display: none; }
    .footer.footer-style-02 .footer-bottom .download-app .title {
      padding-right: 10px; }
    .footer.footer-style-02 .footer-bottom .download-app a {
      margin-left: 10px; }

.footer-instagram ul {
  display: flex;
  flex-wrap: wrap; }
  .footer-instagram ul li {
    width: 30%;
    margin: 0px 5px 10px; }
    .footer-instagram ul li a {
      position: relative;
      display: block; }
      .footer-instagram ul li a i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.5s ease-in-out;
        opacity: 0;
        color: #ffffff; }
      .footer-instagram ul li a:before {
        position: absolute;
        content: "";
        left: 0;
        width: 100%;
        height: 0;
        top: 0;
        background-color: rgba(212, 22, 64, 0.5);
        transition: all 0.3s ease-in-out; }
      .footer-instagram ul li a:hover:before {
        height: 100%; }
      .footer-instagram ul li a:hover i {
        opacity: 1; }

/*Footer Style 03*/
.footer-style-03 {
  background-image: url(../images/home-03/footer-bg.png);
  background-size: cover;
  background-repeat: no-repeat; }
  .footer-style-03 .min-footer .footer-logo img {
    height: 30px; }
  .footer-style-03 .min-footer .footer-logo .logo-yellow,
  .footer-style-03 .min-footer .footer-logo .logo-blue {
    display: none; }
  .footer-style-03 .min-footer .footer-menu ul li a {
    margin-bottom: 20px; }
    .footer-style-03 .min-footer .footer-menu ul li a:hover {
      color: #d41640; }
  .footer-style-03 .min-footer .footer-menu ul li:last-child a {
    margin-bottom: 0px; }
  .footer-style-03 .min-footer .footer-social {
    padding-top: 20px; }
    .footer-style-03 .min-footer .footer-social ul li a {
      color: #ffffff; }
      .footer-style-03 .min-footer .footer-social ul li a:hover {
        color: #d41640; }
  .footer-style-03 .download-app {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .footer-style-03 .download-app .title {
      font-size: 24px;
      margin-right: 10px;
      color: #ffffff; }
    .footer-style-03 .download-app .app-btns a {
      margin-left: 10px;
      display: inline-block; }
  .footer-style-03 .footer-title {
    color: #d41640; }
  .footer-style-03 .footer-bottom {
    padding: 50px 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
    .footer-style-03 .footer-bottom .copyright a:hover {
      color: #d41640; }
    .footer-style-03 .footer-bottom .copyright-menu.footer-menu ul li a:hover {
      color: #d41640; }

@media (max-width: 991px) {
  .footer .min-footer .footer-logo img {
    height: 35px; }
  .footer .footer-bottom {
    padding: 30px 0; } }

@media (max-width: 767px) {
  .footer .min-footer .footer-menu ul li {
    margin-bottom: 15px; }
  .footer .min-footer .contact-info li {
    margin-bottom: 15px; }
  .footer .min-footer .footer-social span {
    font-size: 18px; }
  .footer .min-footer .footer-social ul li {
    font-size: 14px; } }

@media (max-width: 570px) {
  .footer .min-footer {
    padding: 40px 0; } }

@media (max-width: 479px) {
  .footer-style-03 .download-app {
    display: block; }
    .footer-style-03 .download-app .title {
      display: block;
      margin-right: 0;
      margin-bottom: 10px; }
    .footer-style-03 .download-app .app-btns a {
      margin-left: 0;
      margin-right: 0; } }
