/*****************************
  Header Default
*****************************/

.header {
  padding: 15px 40px;

  .navbar {

    .navbar-brand {
      img{
        height: 30px;
      }

      .logo-blue,
      .logo-yellow{
        display: none;
      }
      
      .sticky-logo {
        display: none;
      }
    }

    .navbar-collapse.collapse {
      margin-left: auto;
      flex-grow: inherit;
    }

    .navbar-nav {
      position: relative;

      .nav-item {
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }

        .nav-link {
          color: $white;
          font-family: $font-hedding;
          font-size: 16px;
          display: flex;
          align-items: center;
          transition: all 0.3s ease-in-out;
          padding: 30px 5px;
          font-weight: 500;
          text-transform: uppercase;

          i {
            margin-left: 8px;
            display: flex;
            align-items: baseline;
          }

          &:hover {
            color: $primary;
          }
        }
      }

      li.active {

        > .nav-link {
          color: $primary;
        }
      }
    }

    .add-listing {
      display: flex;
      align-items: center;

      .need-help {
        display: flex;
        align-items: center;
        padding-right: 50px;
        border-right: 1px solid $border-light;
        height: 50px;

        .icon {
          width: 50px;
          background: $primary;
          height: 50px;
          border-radius: 100%;
          padding: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;

          img {
            width: 24px;
          }
        }

        .help-info {

          p {
            margin-bottom: 5px;
            color: $white;
          }

          span {
            color: $white;
            font-size: 18px;
          }
        }
      }

      .search-box {
        width: fit-content;
        height: fit-content;
        position: relative;
        display: flex;
        align-items: center;
      }

      .input-search {
        height: 50px;
        width: 50px;
        border-style: none;
        padding: 10px;
        font-size: 16px;
        letter-spacing: 2px;
        outline: none;
        border-radius: 25px;
        transition: all .5s ease-in-out;
        background-color: inherit;
        padding-right: 40px;
        color: $white;

        &::placeholder {
          color: rgba($white, 0.5);
          font-size: 18px;
          letter-spacing: 2px;
          font-weight: 100;
        }
      }

      .btn-search {
        border-style: none;
        font-size: 16px;
        font-weight: bold;
        outline: none;
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        right: 5px;
        color: $white;
        background-color: transparent;
        pointer-events: painted;

        &:focus ~ .input-search {
          width: 300px;
          border-radius: 0px;
          background-color: transparent;
          border-bottom: 1px solid rgba($white, 0.5);
          transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
        }
      }

      .input-search:focus {
        width: 300px;
        border-radius: 0px;
        background-color: transparent;
        border-bottom: 1px solid rgba($white, 0.5);
        transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
      }

      .woo-action {
        padding: 10px 40px;
        border-right: 1px solid $border-light;

        ul {
          margin: 0;

          li {
            display: inline-block;
            margin-right: 20px;

            &:last-child {
              margin-right: 0px;
            }

            a {
              color: $secondary;
              font-size: 12px;
              position: relative;

              &:hover {
                color: $primary;
              }
            }

            .cart-count {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: -15px;
              right: -8px;
              width: 16px;
              height: 16px;
              font-size: 10px;
              color: $white;
              background: $primary;
              border-radius: $border-radius-lg;
            }

            .cart-icon {
              font-size: 20px;
              color: $white;
            }
          }

          li.cart {

            .dropdown-toggle {
              background: transparent;
              border: none;
              color: $secondary;
              transition: all 0.3s ease-in-out;
              width: 33px;
              margin-top: 5px;

              object {
                width: 23px;
                filter: invert(10%) sepia(8%) saturate(5323%) hue-rotate(164deg) brightness(98%) contrast(92%);
                cursor: pointer;
                transition: all 0.3s ease-in-out;
              }

              &:hover {
                color: $primary;

                object {
                  filter: invert(84%) sepia(25%) saturate(1301%) hue-rotate(134deg) brightness(90%) contrast(84%);
                  transition: all 0.3s ease-in-out;
                }
              }

              &:after {
                border-top: none;
              }
            }

            &:hover {

              .dropdown-menu-right {
                transform: translate3d(0%, 0, 0);
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }
      }

      .side-menu {
        padding: 10px 0px 10px 40px;

        img {
          width: 24px;
        }

        .menu-dark {
          display: none;
        }
      }
    }
  }
}

header.header.header-sticky.is-sticky {
  position: fixed;
  background: $white;
  transition: all 0.5s ease-in-out;
  box-shadow: $box-shadow;
  top: -160px;
  z-index: 11;
  width: 100%;
  padding: 0 40px;

  &.sticky-show {
    top: 0;
  }

  .navbar {

    .navbar-nav {

      .nav-item {

        .nav-link {
          color: $dark;
          padding: 20px 5px;
        }
      }

      .nav-item.active {

        .nav-link {
          color: $primary;
        }
      }
    }

    .navbar-brand {
      border-right: 1px solid rgba($secondary, .3);

      .logo {
        display: none;
      }
      img{
        height: 25px;
      }
      .sticky-logo {
        display: flex;
      }
      .sticky-logo-blue,
      .sticky-logo-yellow{
        display: none;
      }
    }

    .add-listing {

      .need-help {
        border-right: 1px solid rgba($secondary, .3);

        .help-info {

          p {
            color: $secondary;
          }

          span {
            color: $secondary;
          }
        }
      }

      .woo-action {
        border-right: 1px solid rgba($secondary, .3);

        ul {

          li {

            a {
              .cart-icon {
                color: $secondary;
              }
            }
          }
        }
      }

      .side-menu {
        img {
          display: none;
        }

        .menu-dark {
          display: flex;
        }
      }
    }
  }
}

.header.header-sticky.is-sticky {

  .navbar {

    .navbar-toggler {
      border: inherit;
      color: $black;
    }
  }
}

/*Header Style 02*/
.header.header-style-02 {
  position: absolute;
  z-index: 11;
  width: 100%;
  padding: 0;

  .topbar {
    padding: 15px 0;

    .topbar-left {

      p {
        color: $white;
        margin-bottom: 0;
      }

      .social-media {

        li {
          margin: 0 10px;

          a {
            color: $white;

            &:hover {
              color: $primary;
            }
          }
        }
      }
    }

    .topbar-right {

      .topbar-menu {

        ul {

          li {
            margin-right: 15px;

            a {
              color: $white;

              &:hover {
                color: $primary;
              }
            }
          }
        }
      }

      .login {

        a {
          color: $white;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  .navbar {
    padding: 0;
    
    .main-header {
      justify-content: center;
      padding-top: 20px;
      border-top: 1px solid rgba($white, 0.3);

      .navbar-collapse.collapse {
        margin-left: initial; 
      }
    }

    .navbar-nav {
      align-items: center;

      .nav-item{
        margin-right: 30px;
      }

      .navbar-brand {
        border: none;
        position: relative;
        display: block;
        margin: 0 20px;
      }
    }
  }
}

.header.header-style-02.header-sticky.is-sticky {
  padding: 0;

  .topbar{
    padding: 15px 0px;

    .topbar-left {

      p {
        color: $dark; 
      }

      .social-media {

        li {

          a {
            color: $dark;

            &:hover {
              color: $primary;
            }
          }
        }
      }
    }

    .topbar-right {

      .topbar-menu {

        ul {

          li {

            a {
              color: $dark;

              &:hover {
                color: $primary;
              }
            }
          }
        }
      }

      .login {

        a {
          color: $dark;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  .navbar {

    .main-header {
      border-top: 1px solid rgba($dark, 0.1); 
      padding-top: 15px;

      .navbar-nav{

        .nav-item{

          .nav-link{
            padding: 20px 5px;
          }
        }
        .navbar-brand-item{
          // margin-right: 0;
        }
        .navbar-brand{
          // display: none;
        }
      }
    }
  }
}

 
/*Header Style 03*/
.header.header-style-03 {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 9;

  .navbar {

    .navbar-collapse.collapse{
      margin-left: 20px;
      margin-right: auto;
    }

    .navbar-nav{

      li.active >{

        .nav-link{
          color: $primary;
        }
      }

      .nav-item {

        .nav-link{
          font-size: 18px;

          &:hover{
            color: $primary;
          }
        }
      }
    }

    .dropdown {

      > .dropdown-menu {

        li.active {

          a {
            color: $primary;
          }
        }
      }
    }

    .add-listing{

      .side-menu{
        padding-left:30px;
      }

      .header-search{

        .search{

          a{
            font-size: 20px;
            color: $white;

            &:hover{
              color: $primary;
            }
          }
        }
      }
    } 
  }
}


.header.header-style-03.header-sticky.is-sticky {

  .navbar {
    padding-top: 0px;
    padding-bottom: 0px;

    .navbar-brand {
      border-right: 0;
    }

    .navbar-nav{

      .nav-item{

        .nav-link{
          color: $dark;          

          &:hover{
            color: $primary;
          }
        }
      }

      li.active >{

        .nav-link{
          color: $primary;
        }
      }
    }

    .add-listing{

      .header-search{

        .search{

          a{
            color: $dark;

            &:hover{
              color: $primary;
            }
          }
        }
      }
    } 
  }
}


.offcanvas.offcanvas-end.offcanvas-sidebar-menu {
  right: 0;
  left: inherit;
  transform: translateX(100%);
  backdrop-filter: blur(13px) !important;
  -webkit-backdrop-filter: blur(13px) !important;
  -moz-backdrop-filter: blur(13px) !important;
  -ms-backdrop-filter: blur(13px) !important;
  -o-backdrop-filter: blur(13px) !important;
  background: rgba($dark, .8) !important;
  width: 500px;

  .offcanvas-header .btn-close {
    background: none;
    opacity: 1;

    i {
      font-size: 28px;
      color: $white;
    }

    &:hover{
      i {
        color: $primary;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  .sidebar-menu{
    .sidebar-logo{
      a{
        img{
          height: 30px;
        }
        .logo-yellow,
        .logo-blue{
          display: none;
        }
      }
    }
  }
}

.offcanvas.offcanvas-sidebar-menu.show {
  transform: none;
}

.header {

  .navbar {

    .dropdown {

      > .dropdown-menu {

        li.active {

          a {
            color: $primary;
          }
        }
      }
    }
  }
}


@media (max-width:1500px) {

  .header {

    .navbar {

      .navbar-brand {
        padding-right: 30px;
        margin-right: 20px;
      }

      .navbar-nav {
        .nav-item {
          margin-right: 15px;
        }
      }
    }
  }

  .header.header.header-style-02 {

    .navbar {

      .navbar-brand {
        padding-right: 0px;
      }
    }
  }
}

@media (max-width:1366px) {

  .header {

    .navbar {

      .navbar-nav {

        .nav-item {
          margin-right: 8px;
        }
      }
    }
  }
}

@media (max-width:1300px) {

  .header {
    padding: 15px 20px;
  }

  header.header.header-sticky.is-sticky{
    padding: 0px 20px;
  }

  .header.header-style-03.header-sticky.is-sticky{
    .navbar{
      padding-bottom: 8px;
      padding-top: 8px;
    }
  } 
}

@media (min-width:1200px) {

  .header {

    .navbar {

      .dropdown-menu {
        margin: 0px;
        min-width: 200px;
        font-size: 16px;
        border-radius: 0;
        padding: 20px;
        border-color: transparent;
        left: 0;
        transform: translate3d(-10px, 15px, 0);
        transition: all 0.3s ease-in-out;
        transition-property: opacity, visibility, transform;
        transform-origin: top center;
        box-shadow: 0px 5px 10px rgba($black, 0.1);
        visibility: hidden;
        opacity: 0;
        display: block !important;

        .dropdown-submenu {

          .dropdown-menu {
            left: 100%;
            right: auto;
            transform: translate3d(0, 10px, 0);
          }

          .dropdown-menu.left-side {
            right: 100%;
            left: auto;
            transform: translate3d(-15px, 10px, 0);
          }
        }
      }

      .dropdown-menu.megamenu {
        left: 0;
        width: 100%;

        .nav-title{
          font-weight: 700;
          font-size: 18px;
        }
      }

      .dropdown {
        >.dropdown-menu {
          top: 100%;
          margin-top: 0px;
        }

        >.dropdown-menu.megamenu {
          top: 100%;
        }

        &:hover {
          >.dropdown-menu {
            transform: translate3d(-10px, 0px, 0);
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }

  .navbar-nav {

    .dropdown-menu.dropdown-menu-lg {
      min-width: 630px;
    }

    .dropdown-menu.dropdown-menu-md {
      min-width: 450px;
    }

    .mega-menu {
      position: static;
    }

    li {

      &:hover {

        >ul.dropdown-menu {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .dropdown-submenu {
    position: relative;
    padding: 7px 0 7px 0;
    display: block;
    color: $dark;
    font-weight: 400;

    >.dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -6px;
    }
  }

  .dropdown-menu {

    >li {

      >a {

        &:hover {

          &:after {
            text-decoration: underline;
            transform: rotate(-90deg);
          }
        }
      }
    }

    .dropdown-item {
      padding: 7px 0 7px 0;
      background: none;
      color: $dark;

      &:hover {
        color: $primary;
      }

      &:focus {
        background-color: transparent;
      }
    }
  }


 /*Header Style 03*/
  .header.header-style-03{

    .dropdown-menu {

      .dropdown-item {

        &:hover {
          color: $primary;
        }
      }
    }
  }
}

@media (max-width:1199px) {

  .header {

    .navbar-collapse {
      position: absolute;
      top: 100%;
      z-index: 999;
      background: $dark;
      width: 100%;
      max-height: 350px;
      overflow: auto;
      left: 0;
      margin-top: -1px;
      margin-left: 0px;
      border-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .navbar {

      .navbar-brand {
        .logo {
          height: 30px;
        }
      }

      .navbar-nav {
        align-items: initial;

        .nav-item {
          margin-right: 0;

          .nav-link {
            color: $secondary;
            padding: 10px 20px;
            font-size: 16px;

            i {
              position: absolute;
              right: 20px;
            }
          }
        }

        .dropdown.mega-menu {

          .megamenu {
            width: 95%;
            margin: 0 auto;
          }
        }
      }

      .dropdown-menu {
        margin: 0px 15px;
        font-size: 16px;
        border-radius: 0px;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        background: $gray-1;
        padding: 15px;

        .dropdown-item {
          padding: 10px 0px;
          color: $white;

          &:hover{
            background-color:inherit;
            color: $primary;
          }

          &:active,
          &:focus{
            background-color:inherit;
          }
        }

        .dropdown-item.active{
          background-color:inherit;
          color: $primary;
        }
      }

      .nav-title {
        margin-top: 20px;
        color: $white;
        font-size: 18px;
        font-weight: 700;
      }

      .navbar-toggler {
        position: absolute;
        right: 60px;
        box-shadow: none;
        transition: 0.5s ease-in-out;
        background: none;
        color: $white;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .add-listing {
        padding-right: 0px;

        .menu-btn {
          display: none;
        }
      }
    }
  }

  header.header.header-sticky.is-sticky{

    .navbar {

      .navbar-brand {

        .sticky-logo{
          height: 25px;
        }
      }
      .navbar-nav {

        .nav-item {

          .nav-link{
            padding: 10px 20px;
            color: $white;

            &:hover{
              color: $primary;
            }
          }
        }
      }
    }
  } 

  /*Header Style 02*/
  .header.header-style-02 {

    .topbar{
      display: none !important;
    }

    .navbar {

      .navbar-nav {
        align-items: initial;

        .nav-item{
          margin-right: 0px;
        }

        .dropdown-menu{

          .dropdown-item{
            font-size: 16px;
            color: $white;

            &:hover,
            &:focus{
              background-color:inherit;
            }
          }

          li.active{

            .dropdown-item{
              color: $primary;
            }
          }
        }

        .navbar-brand {
          display: none;
        }

      }

      
      .main-header {
        padding: 0 28px;
        padding-top: 25px;
        padding-bottom: 25px;
        justify-content: flex-start;

        .navbar-toggler {
          top: 35px;
          right: 28px;
        }
        
        img {
          height: 30px;
        }
      }
    }
  }


  .header.header-style-02.header-sticky.is-sticky{

    .navbar {

      .main-header {
        padding-top: 25px;
        .navbar-nav{

          .nav-item {

            .nav-link {
              padding: 10px 20px;
            }
          }
        }
      }
    }
  }   

  /*Header Style 03*/
  .header.header-style-03{

    .navbar{

      .navbar-toggler{
        right: 110px;
      }

      .navbar-nav{

        .nav-item {

          .nav-link {
            font-size: 16px;
          }
        }
      }

      .navbar-collapse.collapse{
        margin-left: 0;
      }
    }
  }

  .header.header-style-03.header-sticky.is-sticky{

    .navbar {

      .navbar-nav{

        .nav-item {

          .nav-link {
            color: $white;
          }
        }
      }
    }
  }  

  .dropdown-submenu{
    color: $white;
    padding: 10px 0px;
    display: block;
  }

}

@media (max-width:991px) {

  .header {

    .navbar {

      .navbar-brand {
        padding-right: 20px;
      }
    }
  }

  /*Header Style 03*/
  .header.header-style-03{
    position: inherit;
    padding: 15px 10px;
  }
}

@media (max-width:767px) {

  .header {

    .navbar {

      .add-listing {

        .need-help {
          display: none;
        }

        .search-box {
          display: none;
        }
      }
    }
  }

  .header.header-style-02 {

    .topbar {
      padding: 15px 0;
      text-align: center;
      justify-content: center !important;
      display: flex;
      flex-wrap: wrap;

      .topbar-right {
        margin-top: 10px;
      }
    }
  }
}

@media (max-width:575px) {

  .header {
    padding: 0px 10px;
  }

  header.header.header-sticky.is-sticky{
    padding: 0px 10px;
  }

  .header.header-style-02 {

    .add-listing {

      .search-product {
        display: none;
      }
    }
  }

  .header.header-style-03 {

    .navbar {

      .navbar-toggler{
        right: 100px;
      }

      .add-listing {

        .side-menu {
          padding: 10px 0px 10px 20px;
        }

        .btn {
          display: none;
        }
      }
    }
  }
}

@media (max-width:479px) {

  .header {
    padding: 0px 10px;

    .navbar {

      .navbar-brand {
        flex: 0 0 120px;
      }
    }
  }

  .header.header-style-02 {

    .topbar{

      .topbar-left{
        display: none !important;
      }
    }
  }
}