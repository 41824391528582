/*****************************
  Feature Info
*****************************/

.feature-item {

	.feature-icon {
		padding-bottom: 15px;
		display: flex;
	}

	.feature-content {

		.feature-title {
			padding-bottom: 15px;
			margin-bottom: 0;
			font-weight: 600;
		}

		p {
			margin-bottom: 0;
		}
	}
}

/*feature style 01*/
.feature-item.feature-info-style-01{
	margin-bottom: 30px;
	display: flex;

	.feature-icon {
		padding-bottom: 0;
		height: 100px;
		min-width: 100px;
		align-items: center;
		display: flex;
		justify-content: center;
		margin-right: 30px;
		background-color: $white;

		object {
			width: 40px;
			filter: $filter-2;
			transition: all 0.3s ease-in-out;
		}
	}

	.feature-content {

		.feature-title {
			color: $white;
			padding-bottom: 7px;
			display: block;
			margin-bottom: 0;
			font-weight: 600;
		}

		span {
			display: block;
			color: $white;
		}

		p {
			margin-bottom: 0;
			color: $white;
		}
	}
}


/*feature style 02*/
.feature-item.feature-info-style-02 {
	display:block;
	padding: 23px 40px;
	border: 1px solid rgba($white,0.2);

	.feature-icon {
		margin-right: 0;
		background-color: inherit;

		object {
			width: 70px;
			filter: $filter-2;
			transition: all 0.3s ease-in-out;
		}
	}
}

.feature-item.feature-info-style-02.feature-info-center {
	text-align: center;

	.feature-icon {
		display: inherit;
		justify-content: inherit;
	}
}


/*History info*/

.history-info{

	.history-info-inner{
		position: relative;

		.history-info-item{
			position: relative;
			display: flex;
			align-items: center;
	    	padding-bottom: 100px;

		    .history-info-img{
		    	flex: 0 0 40%;
		    }

		    .history-info-number{
		    	flex: 0 0 20%;
		    	text-align: center;

		    	.time{
		    		height: 90px;
		    		width: 90px;
		    		display: inline-block;
		    		background-color: $white;
		    		border-radius: 100%;
		    		display: flex;
		    		flex-wrap: wrap;
		    		align-items: center;
		    		justify-content: center;
		    		position: absolute;
		    		left: 50%;
		    		transform: translateX(-50%);
		    		top: 0;

		    		span{
		    			display: block;
		    		}

		    		.year{
		    			color: $primary;
		    		}
		    	}
		    }

		    .history-info-content{
		    	flex: 0 0 40%;
		    }

		    &:before{
		    	position: absolute;
			    top: 0;
			    content: "";
			    left: 50%;
			    height: 100%;
			    width: 2px;
			    background: rgba($white,.2);
		    }

		    &:last-child{

		    	&:before{
		    		content:inherit;
		    	}
		    }
		}
	}
}



@media (max-width:991px) {

	.feature-item.feature-info-style-02 {
		margin-bottom: 30px;
	}


	.history-info {

		.history-info-inner {

			.history-info-item{
				padding-bottom: 60px;
			}
		}
	}	
}


@media (max-width:767px) {

	.history-info {

		.history-info-inner {

			.history-info-item{
				display: block;
				padding-bottom: 30px;

				&:before{
		    		content:inherit;
		    	}

		    	.history-info-number{
		    		position: relative;
		    		margin-top: -40px;
		    		margin-bottom: 120px;
		    	}
			}
		}
	}
}




