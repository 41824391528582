/*****************************
  Shop
*****************************/

/* Product */
.product {

  .add-to-cart {
    padding-top: 20px;

    a {
      background: $primary;
      color: $white;
      text-transform: capitalize;
      padding: 10px 20px;
      border-radius: $border-radius;
      display: inline-block;

      &:hover {
        background: $secondary;
      }
    }
  }

  .product-description {

    .product-title {
      margin: 20px 0px 10px;

      a {
        font-size: 16px;
        font-weight: 600;
        color: $secondary;

        &:hover {
          color: $primary;
        }
      }
    }

    .product-rating {
      margin-bottom: 10px;
    }

    .product-price {
      font-size: 14px;
      font-weight: bold;

      ins {
        color: $primary;
        text-decoration: none;
      }
    }
  }
}

/* Product Detail */
.shop-single {

  .nav-tabs-02 {
    border: inherit;

    .nav-tabs {
      border-top: 1px solid rgba($white,.2) !important;
      border-bottom: 1px solid rgba($white,.2) !important;
      padding: 20px 0 10px 0;
    }

    .nav-item {
      margin: 0 5px 10px;

      .nav-link {
        font-size: 18px;
        font-weight: 500;
        border-radius: 0;
        border: inherit;
        color: $white;
      }

      .nav-link.active {
        border: inherit;
        color: $primary;
        background-color: inherit;
      }
    }

    .tab-content{

      .tab-pane{

        p{
          color: $white;
        }

        .list{

          ul {

            li{
              color: $white;

              i{
                color: $primary;
              }
            }
          }
        }

        form{

          .form-label{
            color: $white;
          }

          .form-control{
            background-color: rgba($white, 0.1);
            border: 1px solid rgba($white, 0.1);
            color: $white;
          }
        }
      }
    }
  }

  .product-detail {

    .product-price-rating {

      .product-prize {
        margin-bottom: 20px;

        p {
          font-size: 28px;
          color: $secondary;
          font-weight: 500;

          span.price {
            color: rgba($secondary, 0.6);

            del{
              margin-right: 8px;
            }

            ins{
              color:$primary;
              text-decoration: none;
            }
          }
        }

        h4 {
          font-size: 20px;
          margin-bottom: 0;

          span {
            font-size: 24px;
            color: $primary;
            margin-left: 8px;
          }
        }
      }

      .product-rating {
        margin: 20px 0;

        i {
          color: $primary;
        }
      }
    }

    .add-to-cart-input {

      .form-control {
        height: 54px;
      }

      .btn {
        text-transform: capitalize;
      }
    }

    .product-summary {

      a {
        margin-right: 10px;
        color: $primary;
        border: 1px solid ($secondary, 0.2);
        border-radius: 0px;
        height: 50px;
        padding: 17px 19px;
        text-align: center;
        justify-content: center;
        display: inline-flex;

        i {
          color: $primary;
          margin-right: 0px;
        }

        &:hover {
          color: $secondary;
        }
      }
    }

    hr {
      margin: 30px 0;
    }

    .product-detail-meta {
      margin-bottom: 20px;

      span {
        display: block;
        margin: 10px 0;

        a {
          color: $primary;

          &:hover {
            color: $secondary;
          }
        }
      }
    }

    .input-group {
      width: 90px;
      float: left;
      margin-right: 20px;
    }
  }
}

.slider-slick {

  .slider-nav {

    .slick-next:before {
      content: "\f178";
      font-family: "Font Awesome 5 Free";
      font-weight: 700;
      z-index: 999;
      color: $white;
      font-size: 20px;
    }

    .slick-prev:before {
      content: "\f177";
      font-family: "Font Awesome 5 Free";
      font-weight: 700;
      z-index: 999;
      color: $white;
      font-size: 20px;
    }

    &:hover {

      .slick-prev {
        left: 0;
        z-index: 99;
        opacity: 1;
        border-radius: 0;
      }

      .slick-next {
        right: 0;
        opacity: 1;
        border-radius: 0;
      }
    }

    .slick-prev {
      opacity: 0;
      width: 50px;
      height: 50px;
      background: $primary;
      transition: all 0.3s ease;
    }

    .slick-next {
      opacity: 0;
      width: 50px;
      height: 50px;
      background: $primary;
      transition: all 0.3s ease;
    }

    .slick-track {

      .slick-slide {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
}

/*commentlist*/
.commentlist {
  display: flex;

  .comment-author {
    width: 70px;
    flex: 0 0 70px;

    img {
      border-radius: 100%;
    }
  }

  .comment-content {
    padding: 15px;
    margin-left: 30px;
    margin-bottom: 20px;
    width: 100%;
    border: 1px solid rgba($white,.2);

    .reviews {
      display: flex;
      flex-wrap: wrap;

      .rating {
        margin-left: auto;

        i {
          color: $primary;
        }
      }
    }
  }
}

.form-check-label {
  padding-left: 0px;
}

.search-field {
  padding-left: 20px;
  box-shadow: none;
  border: none;
  height: 50px;
  border-radius: $border-radius-sm;
  border: 1px solid $border-color;
  width: 100%;
  background: $white;
}

/* Checkout */
.checkout.checkout-form {
  background-color: rgba($gray-1, 0.3);
  padding: 30px;

  form {

    .form-control.basic-select {
      color: $body-color;
    }

    .form-label{
      color: $white;
    }

    .form-control {
      height: 60px;
      background-color: rgba($white, 0.1);
      border: 1px solid rgba($white, 0.1);
      color: $white;

      &::placeholder {
        color: $white;
      }
    }

    textarea.form-control {
      height: auto;
    }

    .select2-container--default {

      .select2-selection--single {
        display: flex;
        align-items: center;
        background-color: rgba($white, 0.1);
        border: 1px solid rgba($white, 0.1);

        .select2-selection__rendered {
          font-size: 14px;
          font-weight: 500;
        }

        .select2-selection__arrow {
          top: 18px;
        }
      }
    }
  }
}

.checkout-info {
  padding: 25px 25px 25px 25px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba($gray-1, 0.3);

  > a{
    color: $primary;
  }

  .returning-icon {
    flex: 0 0 50px;
    margin-right: 20px;
  }
}

.checkout-info-returning {

  .checkout-coupon {
    padding: 20px;
    border: 1px solid rgba($white,.1);
    border-radius: 0px;
    form{
      .form-control{
        background-color: rgba($white, 0.1);
        border: 1px solid rgba($white, 0.1);
        color: $white;

        &::placeholder {
          color: $white;
        }
      }
    }
  }
}

.checkout-info-coupon {

  .checkout-info {
    display: flex;
    align-items: center;

    .coupon-icon {
      flex: 0 0 50px;
      margin-right: 20px;
    }
  }

  .checkout-coupon {
    padding: 20px;
    border: 2px dashed rgba($white,.1);
    border-radius: 0;

    form{

      .form-control{
        background-color: rgba($white, 0.1);
        border: 1px solid rgba($white, 0.1);
        color: $white;

        &::placeholder {
          color: $white;
        }
      }
    }
  }
}

.checkout-review {
  padding: 15px 30px 30px;
  background-color: rgba($gray-1, 0.3);

  .table {

    tr {
      border-color: rgba($white,0.1);

      th {
        padding: 15px 0;
        font-size: 18px;
        font-weight: 600;
        color: $white;
        border-bottom: 1px solid rgba($white, 0.1);
      }

      th.shipping {
        font-size: 14px;
      }

      td {
        text-align: left;
        padding: 15px 0;
        font-size: 14px;
        font-weight: 500;
        color: $white;
        border-bottom: 1px solid rgba($white, 0.1);

        .form-check {

          .form-check-input {
            border-radius: $border-radius-lg;
          }
        }
      }

      td.amount {
        font-size: 18px;
        color: $primary;
      }
    }

    tbody {
      border-bottom: 2px solid rgba($white,0.1);
    }

    .tfoot{
      border-color: rgba($white,0.2);
    }
  }

  .form-check-input{

    &:focus{
      box-shadow:none;
    }
  }
}

/* Cart */
.cart-table {
  background-color: rgba($gray-1, 0.3);
  .table {
    margin-bottom: 0;
    font-size: 14px;

    tr {

      th {
        border: none;
        padding: 15px 10px;
        color: $white;
        background-color: $primary;
        text-align: left;
        vertical-align: middle;
        border-radius: inherit !important;
      }

      td {
        border: none;
        border-top: 1px solid rgba($white,.1);
        padding: 15px 10px;
        font-weight: 500;
        text-align: left;
        vertical-align: middle;

        &.product-remove {
          padding: 15px 25px;
          text-align: center;

          a {
            color: $red;
          }
        }

        &.product-quantity {

          .form-control {
            width: 70px;
            border: 1px solid rgba($white,.1);
            height: 50px;
            background-color: inherit;
            color: $white;
          }
        }

        a {

          &:hover {
            color: $primary;
          }
        }
      }
    }

    thead {

      tr {
        border: none;

        th {
          &:last-child {
            border-radius: $border-radius;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          &:first-child {
            border-radius: $border-radius;
            border-bottom-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }

    tbody {
      border-color: rgba($white,.2);

      tr {

        td.product-name {

          a {
            color: $secondary;

            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    border-top: none;
    padding: 15px 10px;
    align-items: center;

    .coupon {

      form {
        display: flex;
      }

      .input-text {
        border-radius: 0px;
        height: 60px;
        padding: 10px 20px;
        -webkit-box-shadow: none;
        box-shadow: none;
        font-size: 14px;
        font-weight: 500;
        margin-right: 10px;
        color: $secondary;
        background-color: rgba($white, 0.1);
        border: 1px solid rgba($white, 0.1);
        color: $white;
      }

      .btn {
        padding: 12px 20px;
      }
    }

    .update-cart {
      margin-left: auto;
    }
  }
}

.cart-table .table td.product-thumbnail img {
  width: 70px;
  height: auto;
}

.cart-totals {
  padding: 20px;
  background-color: rgba($gray-1, 0.3);

  .cart-totals-title {
    margin-bottom: 20px;
    text-align: center;
    color: $white;
  }

  .table {
    font-size: 14px;

    >tbody{
      border-color: rgba($white, 0.1);
    }

    tr {

      th {
        text-align: left;
        border: none;
        border-top: 1px solid rgba($white, 0.1);
        vertical-align: top;
        background: transparent;
        color: $body-color;
      }

      td {
        text-align: right;
        border: none;
        border-top: 1px solid rgba($white, 0.1);
        background: transparent;
        color: $white;

        .form-check {

          .form-check-input {
            border-radius: $border-radius-lg;
          }
        }
      }

      &.shipping {

        .form-check-label {
          padding-left: 0px;
        }

        p {
          margin-bottom: 0;
        }
      }

      &.order-total {

        th {
          font-size: 18px;
          font-weight: 600;
          color: $body-color;
        }

        td {
          font-size: 26px;
          font-weight: 600;
          color: $white;
        }
      }
    }

    .cart-subtotal {

      th {
        font-size: 18px;
        font-weight: 600;
        border: none;
      }

      td {
        font-size: 18px;
        font-weight: 600;
        border: none;
      }
    }
  }

  .checkout-button {
    width: 100%;
    text-align: center;
    padding: 15px 25px;
    display: block;

    &:hover{
      background-color:$white;
      color: $black;
    }
  }
}

/* Shop Category */
.shop-category {
  padding: 25px 15px;

  h5 {
    margin-bottom: 20px;
  }

  ul {
    margin-bottom: 0;

    li {

      a {
        padding: 5px 0;
        display: block;
      }
    }
  }
}

.gridlist-toggle-wrap {

  .gridlist-toggle {

    .grid-2-column {
      background-image: url(../images/shop/grid-2-icon.png);
    }

    .gridlist-button {
      display: inline-block;
      background-color: $white;
      width: 19px;
      height: 19px;
      color: $secondary;
      font-size: 0;
      line-height: normal !important;
      padding: 0;
      margin-left: 8px;
      text-align: center;
      -webkit-transition: all .3s ease-out 0s;
      -moz-transition: all .3s ease-out 0s;
      transition: all .3s ease-out 0s;
    }

    .grid-3-column {
      background-image: url(../images/shop/grid-3-icon.png);
    }

    .grid-4-column {
      background-image: url(../images/shop/grid-4-icon.png);
    }

    .gridlist-toggle-list {
      background-image: url(../images/shop/list-icon.png);
    }

    a:hover, a.active {
      background-color: $primary;
    }
  }
}

.shop-single {

  form {
    .form-control {
      border: 1px solid $border-color;
    }
  }
}

/* Shop Sidebar */

.offcanvas-header{

  .title{
    color: $dark;
  }
}
.shop-sidebar {
  padding: 30px 20px;

  .widget {
    margin-bottom: 50px;

    .widget-title {
      margin-bottom: 30px;

      .title{
        color: $dark;
      }
    }

    .price-filter {
      display: flex;

      a {
        margin-left: auto;
      }
    }

    .form-check {
      display: block;
      margin-bottom: 0px;
      padding-left: 25px;

      span {
        float: right;
      }
    }

    .social {

      ul {

        li {

          display: inline-block;
          list-style-type: none;
          margin-right: 20px;
          font-size: 16px;

          a{
            color: $body-color;

            &:hover{
              color:$primary;
            }
          }
        }
      }
    }

    .tagcloud {

      ul {

        li {
          margin-bottom: 10px;
          display: inline-block;

          a {
            color: $body-color;
            line-height: 32px;
            border: 1px solid $border-color;
            padding: 0 12px;
            border-radius: 0;
            transition: all 0.3s ease-in-out;
            display: block;

            &:hover {
              background: $primary;
              border: 1px solid $primary;
              color: $white;
            }
          }
        }
      }
    }

    .widget-categories {

      ul {

        li {

          a {
            font-size: 14px;
            color: $gray-1;
            line-height: 32px;
            display: block;

            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }
}

.irs--flat .irs-line {
  height: 6px;
}

.irs--flat .irs-bar {
  background: $primary;
  height: 6px;
}

.irs--flat .irs-from, 
.irs--flat .irs-to, 
.irs--flat .irs-single {
  background: transparent;
  color: $primary;
  font-size: 13px;
  font-weight: 600;
}

.irs--flat .irs-handle > i:first-child, 
.irs--flat .irs-handle > i:first-child {
  background: $white;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid $primary;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.irs--flat .irs-from:before, 
.irs--flat .irs-to:before, 
.irs--flat .irs-single:before {
  display: none;
}

.irs--flat .irs-handle.state_hover > i:first-child, 
.irs--flat .irs-handle:hover > i:first-child {
  background: $primary;
}

.slider-slick {

  .slider-for {

    .slick-prev, 
    .slick-next {
      display: none !important;
    }
  }
}

@media (max-width:1200px) {

  .cart-table {

    .actions {

      .coupon {

        .btn {
          font-size: 14px;
        }
      }

      .update-cart {
        .btn {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width:767px) {

  .slider-slick {

    .slider-nav {

      .slick-prev {
        display: none !important;
      }

      .slick-next {
        display: none !important;
      }
    }
  }

  .shop-single {

    .nav-tabs-02 {

      .nav-item {

        .nav-link {
          font-size: 16px;
          padding: 8px 10px;
        }
      }
    }
  }

  .cart-table {

    .actions {
      display: block;
    }
  }

  .commentlist {

    .comment-content {

      .reviews {

        p {
          margin-bottom: 5px;
        }

        .rating {
          margin-left: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media (max-width:575px) {

  .cart-table {

    .actions {
      display: block;

      .coupon {

        #coupon_code {
          margin-bottom: 10px;
        }

        .btn {
          margin-bottom: 10px;
        }
      }
    }
  }

  .shop-single {

    .add-to-cart-input {

      .product-summary, 
      .qty {
        margin-bottom: 10px;
      }

      .btn {
        padding: 15px 20px;
        margin-bottom: 10px;
      }
    }
  }

  .sidebar {

    .cart-totals {
      padding: 25px;
    }
  }
}

@media (max-width:479px) {

  .cart-table {

    .actions {

      .coupon {

        form {
          display: block;

          .input-text {
            width: 100%;
          }

          .btn {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
