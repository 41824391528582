/*****************************
    Typography
*****************************/

body {
	font-family: $font-base;
	font-style: normal;
	font-size: 16px;
	line-height: 1.5;
	color: $body-color;
	overflow-x: hidden;
	font-weight: 300;
	background: $dark;
}

ul {
	margin-bottom: 0;
}

a {
	outline: medium none !important;
	color: $primary;
	transition: all 0.3s ease-in-out;
	text-decoration: none !important;

	&:focus {
		color: $primary;
		text-decoration: none !important;
	}

	&:hover {
		color: $primary;
		text-decoration: none !important;
	}
}

input {
	outline: medium none !important;
	color: $primary;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-hedding;
	font-weight: normal;
	color: $secondary;
	margin-top: 0px;
	font-weight: 400;
	margin-bottom: 0;

	a {
		color: inherit;
	}
}

label {
	font-weight: normal;
}

h1 {
	font-size: 92px;
	font-style: normal;
	font-weight: 600;
	line-height: 1.1;
}

h2 {
	font-size: 48px;
	font-weight: 500;
}

h3 {
	font-size: 34px;
	font-weight: 500;
}

h4 {
	font-size: 28px;
	font-weight: 500;
}

h5 {
	font-size: 24px;
	font-weight: 500;
}

h6 {
	font-size: 22px;
	font-weight: 500;
}

p {
	line-height: 1.5;
	color: $white;
}

*::-moz-selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

::-moz-selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

::selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

.fw-medium {
	font-weight: 500;
}

.fw-600 {
	font-weight: 600;
}

.border-radius {
	border-radius: $border-radius;
}

.border-radius-sm {
	border-radius: $border-radius-sm;
}

.border-radius-md {
	border-radius: $border-radius-md;
}

.border-radius-lg {
	border-radius: $border-radius-lg;
}

.box-shadow {
	box-shadow: $box-shadow;
}

.box-shadow-sm {
	box-shadow: $box-shadow-sm;
}

.box-shadow-lg {
	box-shadow: $box-shadow-lg;
}

/* form-control */
.form-control, .form-select {
	border: 1px solid $border-color;
	border-radius: 0px;
	height: 60px;
	padding: 10px 20px;
	box-shadow: none;
	font-size: 16px;
	font-weight: 500;
	color: $dark;
	background-clip: inherit !important;

	&:focus {
		box-shadow: none;
		border-color: $primary;
	}
}

textarea.form-control{
	height: auto;
}

.avatar-lg {
	min-width: 80px;
	width: 80px;
}

/* input-group-prepend */
.input-group-prepend, 
.input-group-append {

	.input-group-text {
		width: 50px;
		background: transparent;
		border-color: $border-color;
		box-shadow: none;
		justify-content: center;
	}
}

/* file-upload */
.input-group.file-upload {
	height: 50px;

	.form-control {
		height: 50px;
		color: $body-color;
		border-radius: 3px;
		border-color: $border-color;
		position: relative;
		z-index: 2;
		width: 100%;
		margin: 0;
		opacity: 0;
		border-radius: 3px;
	}

	.input-group-text {
		height: 50px;
		font-size: 14px;
		font-weight: 600;
		color: $body-color;
		border-color: $border-color;
		padding: 15px 90px 15px 20px;
		border-radius: 3px;
		transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: 1;
		line-height: 1.5;
		background-color: $white;
		justify-content: space-between;
		margin-left: 0;

		&:after {
			content: "Browse";
			height: 48px;
			padding: 14px 25px;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 3;
			display: block;
			line-height: 1.5;
			color: $secondary;
			background-color: $gray-1;
			border-left: inherit;
			border-radius: 0px 3px 3px 0px;
		}
	}
}

@media (max-width:1400px) {

	h1 {
		font-size: 90px;
	}
}

@media (max-width:1199px) {

	h1 {
		font-size: 80px;
	}
}

@media (max-width:991px) {

	h1 {
		font-size: 65px;
		line-height: 1;
	}
}

@media (max-width:767px) {

	h1 {
		font-size: 60px;
	}

	h2 {
		font-size: 30px;
	}

	h3 {
		font-size: 26px;
	}

	h4 {
		font-size: 24px;
	}
}

@media (max-width:575px) {

	h1 {
		font-size: 30px;
	}

	h2 {
		font-size: 28px;
	}
}
