/*****************************
    Blog
*****************************/

.blog-post {
	position:relative;
	cursor: pointer;

	.blog-image {
		position:relative;

		&:before {
	    position: absolute;
	    content: "";
	    background: linear-gradient(to bottom, rgba($black, 0) 0, rgba($black, 0.6) 100%);
	    left: 0;
	    width: 100%;
	    height: 75%;
	    bottom: 0;
	    z-index: 1;
	    transition: all 0.3s ease;
	  }
	}

	.blog-post-details {
		position: absolute;
	    left: 0;
	    right: 0;
	    width: 100%;
	    z-index: 2;
	    bottom: 20px;
	    padding: 0px 30px;

	    .tag {
	    	background-color: $white;
	    	font-size: 14px;
	    	font-weight: 400;
	    	color: $primary;
	    	padding: 5px 10px;
			display: inline-flex;
	    }
		
		h5 {
			margin-top: 15px;
			color: $white;
			font-weight: normal;
			margin-bottom: 0px;
			font-size:24px;
			line-height: 28px;

			a {
				&:hover {
					color:$primary;
				}
			}
		}

		.blog-post-meta {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			

			.blog-post-author {
				margin-right: 20px;
				margin-bottom: 10px;

				a {
					font-size: 16px;
					color: $white;

					img {
					    height: 30px;
					    border-radius: 50%;
					    margin: 0px;
					    width: auto;
					    display: inline-block;
					    margin-right: 10px;
					    transform: inherit;
					}
				}
			}

			.blog-post-time {
				margin-bottom: 10px;
				margin-right: 20px;

				a {
					color: $white;
				}

				i {
					margin-right: 10px;
				}
			}

			.blog-post-comments{
				margin-bottom: 10px;
			}
		}
		.blog-link{
			color: $primary;
			display: flex;
			align-items: center;
			&:hover{
				color:$white;
			}
		}
	}
}

/*Blog Style 01*/

.blog-post.post-style-01{

	.blog-image{
		overflow: hidden;

		img{
			transition: all 0.5s ease;
			transform: scale(1);
		}

		.blog-post-date {
		    position: absolute;
		    top: 20px;
		    left: 20px;
		    background-color: $primary;
		    z-index: 3;
		    color: $white;
		    text-align: center;
		    width: 80px;

		    span{
		    	display: block;
		    }

		    .date{
		    	margin-top: 8px;
		    	font-size: 26px;
		    	font-weight: bold;
		    }

		    .month{
		    	text-transform: uppercase;
		    	font-size: 14px;
		    	margin-bottom: 10px;
		    }
		}

		.blog-post-meta {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-top: 10px;
			position: absolute;
			left: 20px;
			bottom: 20px;
			z-index: 2;

			.blog-post-author {
				margin-right: 20px;
				margin-bottom: 10px;

				a {
					font-size: 16px;
					color: $white;

					img {
					    height: 30px;
					    border-radius: 50%;
					    margin: 0px;
					    width: auto;
					    display: inline-block;
					    margin-right: 10px;
					    transform: inherit;
					}
				}
			}

			.blog-post-time {
				margin-bottom: 10px;
				margin-right: 20px;

				a {
					color: $white;
				}

				i {
					margin-right: 10px;
				}
			}

			.blog-post-comments{
				margin-bottom: 10px;
			}
		}
	}
	

	.blog-post-details{
		position: inherit;
	    left: inherit;
	    right: inherit;
	    width: 100%;
	    z-index: 2;
	    bottom: inherit;
	    padding: 0px;
	    padding-top: 20px;
	    

	    .blog-title{
	    	white-space: nowrap;
		    overflow: hidden;
		    text-overflow: ellipsis;
		    display: inline-block;
		    max-width: 100%;
		    margin-top: 10px;

		    a{

		    	&:hover{
			    	color:$primary;
			    }
		    }

		    &:hover{
		    	color:$primary;
		    }
	    }

	    .blog-link{
	    	display: flex;
	    	align-items: center;

	    	&:hover{
		    	color:$white;
		    }
	    }
	}
	&:hover{

    	.blog-image{

    		img{
    			transform: scale(1.1);
    		}
    	}
    }
}

/*Blog Style 02*/

.blog-post.post-style-02 {

	.blog-image{
		overflow: hidden;

		img{
			transform: scale(1);
			transition: all 0.5s ease;
		}

		.blog-post-date {
		    position: absolute;
		    top: 20px;
		    left: 20px;
		    background-color: $primary;
		    z-index: 3;
		    color: $white;
		    text-align: center;
		    width: 80px;

		    span{
		    	display: block;
		    }

		    .date{
		    	margin-top: 8px;
		    	font-size: 26px;
		    	font-weight: bold;
		    }

		    .month{
		    	text-transform: uppercase;
		    	font-size: 14px;
		    	margin-bottom: 10px;
		    }
		}
	}

	.blog-post-details{
		position: inherit;
	    left: inherit;
	    right: inherit;
	    width: 100%;
	    z-index: 2;
	    bottom: inherit;
	    padding: 0px;
	    padding-top: 20px;
	    
	    .tag {
	    	background-color: $white;
	    	font-size: 14px;
	    	color: $primary;
	    	padding: 5px 10px;
	    	display: inline-block;
	    }
		

		h5 {
			margin-top: 0px;
			color: $white;
			font-weight: normal;
			margin-bottom: 0px;
			font-size:24px;
			line-height: 28px;

			a {
				
				&:hover {
					color:$primary;
				}
			}
		}

		.blog-post-meta {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-top: 10px;

			.blog-post-author {
				margin-right: 20px;
				margin-bottom: 10px;

				a {
					font-size: 16px;
					color: $white;

					img {
					    height: 30px;
					    border-radius: 50%;
					    margin: 0px;
					    width: auto;
					    display: inline-block;
					    margin-right: 10px;
					    transform: inherit;
					}
				}
			}
		}
	}

	&:hover{

		.blog-image{
			
			img{
				transform: scale(1.1);
			}
		}
	}


}


.blog-post.post-style-03{
	.blog-image{
		overflow: hidden;

		img{
			transform: scale(1);
			transition: all 0.5s ease;
		}
	}

	&:hover{
		.blog-image{
			img{
				transform: scale(1.1);
			}
		}
	}
}

.latest-news-pattern {
	position: relative;
	z-index: 2;

	&:before {
		background-image: url(../images/home-01/pattern-06.png);
		content: "";
		position: absolute;
		height: 70px;
		width: 70px;
		left: 110px;
		top: 200px;
		background-repeat: no-repeat;
		z-index: -1;
		backface-visibility: hidden;
		animation: jumping4 9s ease-in-out 2s infinite alternate;
		animation-delay: 1s;
		transition: all 8s ease 4s;
		user-select: none;
	}

	&:after {
		background-image: url(../images/home-01/pattern-09.png);
		content: "";
		position: absolute;
		height: 305px;
		width: 140px;
		right: 0;
		top: 50px;
		background-repeat: no-repeat;
		z-index: -1;
		-webkit-animation: upmover 1.5s infinite  alternate;
      	animation: upmover 1.5s infinite  alternate;
	}
}


/*Featured News*/

.featured-news{
	position: relative;
	z-index: 2;

	&:before {
		background-image: url(../images/home-02/pattern-04.png);
		content: "";
		position: absolute;
		height: 327px;
		width: 101px;
		left: 110px;
		top: 50%;
		z-index: -1;
		transform: translateY(-50%);
		background-repeat: no-repeat;
		-webkit-animation: upmover 1.5s infinite  alternate;
  		animation: upmover 1.5s infinite  alternate;
	}

	&:after {
		background-image: url(../images/home-02/pattern-05.png);
		content: "";
		position: absolute;
		height: 70px;
		width: 70px;
		right: 115px;
		top: 206px;
		z-index: -1;
		background-repeat: no-repeat;
		backface-visibility: hidden;
		animation: jumping3 9s ease-in-out 2s infinite alternate;
		animation-delay: 1s;
		transition: all 7s ease 2s;
		user-select: none;
	}

	.pattern-6{
		bottom: 0;
		right: 0;
		z-index: -1;
	}

	.blog-post.post-style-03{

		.blog-post-details{

			.blog-title{
				width: 470px;
			}
		}
	}
	.blog-post.post-style-02{

		.blog-post-details{

			.blog-title{
				width: 270px;
			}
		}
	}
}

/*Blog*/

.video-bg-section{

	.video-image{
		position: relative;

		&:before{
			content:"";
			position: absolute;
			top:0;
			left:0;
			z-index: 2;
			width: 100%;
			height: 100%;
			background-color: rgba($white,0.3);
		}

		.video-btn {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			margin: 0 auto;
			margin-bottom: 40px;
			display: flex;
			flex: 0 0 90px;
			align-items: center;
			justify-content: center;
			height: 90px;
			width: 90px;
			border-radius: $border-radius-lg;
			background-color: $white;
			color: $primary;
			transition: all 0.3s ease-in-out;
			z-index: 9;

			i {
			   font-size: 30px;
			}

			&:hover{
			   background-color:$primary;
			   color:$white;
			}
		}
	}
}


.blog-post-social {
	display: flex;

	span {
		color: $primary;
		padding-right: 15px;
	}

	ul {
		display: flex;
		margin-bottom: 0;

		li {
			padding: 0 8px;

			a {
				color: $white;

				&:hover {
					color: $primary;
				}
			}
		}
	}
}

.blog-post-navigation {

	.post-navigation {

		.nav-link {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 25px 0;
			margin-top: 50px;
			border-top: 1px solid rgba($white,0.2);
			border-bottom: 1px solid rgba($white,0.2);

			a {

				i {
					position: absolute;
					font-size: 18px;
					color: $white;
					top: 50%;
					transform: translateY(-50%);
				}

				span {
					display: block;
					color: $white;
					transition: all 0.5s ease-in-out;
					font-weight: 500;
				}

				.nav-title {
					font-size: 18px;
				}
			}

			div {
				width: 50%;
			}

			.nav-previous {

				a {
					position: relative;
					display: block;
					padding-left: 80px;
					padding-top: 11px;
					padding-bottom: 11px;

					i {
						left: 25px;
					}

					&:before {
						position: absolute;
						content: "";
						top: 50%;
						transform: translateY(-50%);
						left: 0;
						width: 60px;
						height: 60px;
						background-color: $primary;
						transition: all 0.5s ease-in-out;
					}

					&:hover {

						i {
							color: $primary;
						}

						span {
							color: $primary;
						}

						&:before {
							background-color: $white;
						}
					}
				}
			}

			.nav-next {
				text-align: right;
				margin-left: auto;

				a {
					position: relative;
					display: block;
					padding-right: 80px;
					padding-top: 11px;
					padding-bottom: 11px;

					i {
						right: 25px;
					}

					&:before {
						position: absolute;
						content: "";
						top: 50%;
						transform: translateY(-50%);
						right: 0;
						width: 60px;
						height: 60px;
						background-color: $primary;
						transition: all 0.5s ease-in-out;
					}

					&:hover {
						i {
							color: $primary;
						}

						span {
							color: $primary;
						}

						&:before {
							background-color: $white;
						}
					}
				}
			}
		}
	}
}


/*Blog Detail*/
.blog-detail {

	.quote-yellow,
	.quote-blue{
		display: none;
	}

	.comments-01 {
		display: flex;

		.comment-author {
			flex: 0 0 90px;

			img {
				width: 100%;
			}
		}

		.comment-content {
			border: 1px solid rgba($white,0.2);
			padding: 30px;
			margin-left: 20px;
			margin-bottom: 30px;
			width: 100%;

			.reviews {
				display: flex;
				margin-bottom: 10px;
				align-items: center;

				.meta {
					font-size: 20px;

					strong {
						color: $white;
					}

					span {
						font-weight: 500;
						font-size: 14px;
						color: $white;
					}
				}

				p {
					margin-bottom: 0;
				}

				.port-social {
					margin-left: auto;
					border-radius: 0;
					color: $primary;
					padding: 5px 15px;
					display: block;

					&:hover{
						color:$white;
					}
				}
			}
		}
	}

	.comments-02 {
		display: flex;
		padding-left: 110px;

		.comment-author {
			flex: 0 0 90px;

			img {
				width: 100%;
				border-radius: 0;
			}
		}

		.comment-content {
			border: 1px solid rgba($white,0.2);
			padding: 30px;
			margin-left: 20px;
			margin-bottom: 30px;
			width: 100%;

			.reviews {
				display: flex;
				margin-bottom: 10px;
				align-items: center;

				.meta {
					font-size: 20px;

					strong {
						color: $white;
					}

					span {
						font-weight: 500;
						font-size: 14px;
						color: $white;
					}
				}

				p {
					margin-bottom: 0;
				}

				.port-social {
					margin-left: auto;
					border-radius: 0;
					color: $primary;
					padding: 5px 15px;
					display: block;

					&:hover{
						color:$white;
					}
				}
			}
		}
	}

	.leave-reply-form {

		form {

			.form-control {
				background-color: rgba($white, 0.1);
				border: 1px solid rgba($white, 0.1);
				color: $white;
			}
		}
	}
}


@media (max-width: 991px) {

	.latest-news {

		.my-shuffle-container.columns-3{

			.grid-item{
				width: 50%;
			}
		}
	}
}

@media (max-width: 767px) {

	.featured-news{

		&:before{
			background-size:cover;
			height: 130px;
		    width: 40px;
		    left: 30px;
		    top: 130px;
		}

		&:after{
			background-size: cover;
		    height: 50px;
		    width: 50px;
		    right: 30px;
		}

		.pattern-6 {
		    max-width: 10%;
		}
	}

	.latest-news {

		.my-shuffle-container.columns-3{

			.grid-item{
				width: 100%;
			}
		}
	}
}

@media (max-width:575px) {

	.featured-news{

		&:before,
		&:after{
			content:none;
		}

		.pattern-6 {
			display: none;
		}

		.blog-post.post-style-03{

			.blog-post-details{

				.blog-title{
					width: auto;
				}
			}
		}

		.blog-post.post-style-02{

			.blog-post-details{

				.blog-title{
					width: auto;
				}
			}
		}
	}

	.blog-detail {

		.video-bg-section {

			.video-image {
				min-height: auto;
			}
		}

		.comments-01 {

			.comment-content {
				padding: 15px;
			}
		}

		.comments-02 {
			padding-left: 10px;

			.comment-content {
				padding: 15px;
			}
		}
	}
}

@media (max-width: 479px) {
 	
 	.blog-post {

 		.blog-post-details {
 			padding: 0px 20px;
 			
 			h5{
 				font-size: 20px;
 			}
 		}
 	}	
}


